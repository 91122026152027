<template>
	<div class="container">
		<!-- 广告图 -->
		<div class="banner">
			<div class="main">
				<div class="breadcrumb-text">
					<h2>企业空间</h2>
					<p>Company Pages</p>
					<!-- 	<h2 class="mt-10">应急物流</h2>
					<p>Emergency logistics</p> -->
				</div>
			</div>
		</div>
		<div class="scfl">
			<div class="main">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>企业空间</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main_wrap">
			<div class="main">
				<div v-loading="loading" style="margin-top: 30px;">
					<div class="ent_logo" v-if="companyData">
						<img :src="companyData.logo?$img(companyData.logo):require('@/assets/images/logo.png')">
						<div style="margin-left: 20px;display: inline-block;vertical-align: top;">
							<div class="ent_name">{{companyData.name}}</div>
							<!-- <span>开业</span> -->
							<span>{{companyData.province_name}}{{companyData.city_name}}{{companyData.district_name}}</span>
						</div>
					</div>
					<div style="font-size: 16px;color: #333;margin: 20px 0 10px;font-weight: bold">工商注册</div>
					<el-descriptions class="mydes" :column="2" border v-if="companyData">
						<el-descriptions-item :span="2" label-class-name="my-label " content-class-name="my-content"
							label="公司名称">
							{{companyData.name}}
						</el-descriptions-item>
						<el-descriptions-item :span="2" label-class-name="my-label " content-class-name="my-content"
							label="曾用名">
							{{companyData.name_once}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content" label="法人代表">
							{{companyData.legal_person}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content" label="核准日期">
							{{companyData.check_time}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content"
							label="统一社会信用代码">
							{{companyData.unicode}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content"
							label="工商注册号">
							{{companyData.register_number}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content"
							label="纳税人识别号">
							{{companyData.taxpayer_code}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content"
							label="组织机构代码">
							{{companyData.organization_code}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content" label="公司类型">
							{{companyData.category_name}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content" label="行业">
							{{companyData.industry_name}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content" label="成立日期">
							{{companyData.cl_time}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content" label="注册资本">
							{{companyData.zczb}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content" label="经营期限">
							{{companyData.jy_start_time}}至{{companyData.jy_ent_time}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content" label="登记机关">
							{{companyData.register_organ}}
						</el-descriptions-item>
						<el-descriptions-item :span="2" label-class-name="my-label " content-class-name="my-content"
							label="电子邮箱">
							{{companyData.lx_email}}
						</el-descriptions-item>
						<el-descriptions-item :span="2" label-class-name="my-label " content-class-name="my-content"
							label="公司地址">
							{{companyData.province_name}}{{companyData.city_name}}{{companyData.district_name}}{{companyData.address}}
						</el-descriptions-item>
						<el-descriptions-item :span="2" label-class-name="my-label " content-class-name="my-content"
							label="经营范围">
							{{companyData.jyfw}}
						</el-descriptions-item>
					</el-descriptions>

				</div>


			</div>
		</div>

	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		companyInfo
	} from '@/api/supply/supply';
	export default {
		name: "supplyGysHome",
		components: {},
		data: () => {
			return {
				companyData: null,
				loading: true,
			};
		},
		created() {

			this.getOrderList(this.$route.query.id);

		},
		methods: {
			getOrderList(id) {
				companyInfo({
					id
				}).then(res => {
					if (res.code == 0 && res.data) {
						this.companyData = res.data
						this.loading = false
					}
				})

			},
		}
	};
</script>
<style scoped>
	/deep/.nowidth .el-form-item__content {
		width: 360px;
	}

	.el-select {
		width: 120px;
	}

	/deep/.my-label {
		font-size: 16px;
		color: #333;
		width: 18%;
		background-color: #fff !important;
	}

	/deep/.my-content {
		font-size: 16px;
		color: #333;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}

	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #fff !important;
	}

	/deep/.el-pager .number:hover {
		color: #11773a !important;
	}
</style>
<style scoped lang="scss">
	.el-button--primary {
		background-color: #57c5a5;
		border-color: #57c5a5;
	}

	.container {
		.banner {
			background: url('../../../assets/images/supply/common.png') no-repeat;
			background-size: 100% 100%;
			position: relative;
			z-index: 1;
			width: 100%;
			padding: 6% 0;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.breadcrumb-text {
					// height: 300px;
					position: relative;
					z-index: 1;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h2 {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 36px;
					}

					p {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 18px;
					}
				}

			}

		}

		.scfl {
			padding-top: 30px;



			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;
				padding-bottom: 10px;
				border-bottom: 1px #c1c1c1 solid;
			}
		}

		.main_wrap {
			padding-bottom: 50px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.ent_logo {
					display: flex;

					img {
						width: 100px;
						height: 100px;
						background: radial-gradient(transparent 50%, rgba(0, 0, 0, .05) 100%);
						border-radius: 6px;
						border: 1px solid #e0e0e0;
					}

					.ent_name {
						margin: 15px 0 10px;
						font-size: 22px;
						font-weight: bold;
						line-height: 1.3;
					}
				}


			}
		}
	}

	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
</style>