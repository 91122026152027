<template>
	<div class="container">
		<!-- 广告图 -->
		<div class="banner">
			<div class="main">
				<div class="zbdw">
					<p>主办：XXX主办单位</p>
					<p>承办：XXXX承办单位</p>
				</div>
				<div class="logo_wrap">
					<p><el-image :src="require('@/assets/images/logo.png')"></el-image></p>
					<p class="zw">应急保障储备社会化数字协同平台</p>
					<p class="yw">Socialized Digital Collaborative Platform for emergency security reserve</p>
				</div>
				<div class="in-sousuo">
					<div class="sousuo-box">
						<input type="text" placeholder="请输入物资名称关键字" v-model="keyword" @keyup.enter="search"
							maxlength="50" />
						<el-button type="primary" size="medium " icon="el-icon-search" @click="search">物资寻源</el-button>
						<!-- <el-button type="primary" size="medium " icon="el-icon-place" @click="search">空间定位</el-button> -->
					</div>
					<div class="hot-search-words" v-if="hotSearchWords.length">
						<span>热门搜索：</span>
						<ul>
							<li v-for="(item, index) in hotSearchWords" :key="index"
								@click="$router.push({ path: '/supplyView/productSearch', query: { keyword: item, search_type: 'wz' } })">
								{{ item }}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="sfdw">
			<div class="main">
				<router-link to="/supplier/index" target="_blank" class="zbdw">
					<p>全国社会信用标准化技术委员会授权</p>
					<p style="margin-top: 10px;">国家标准《合格供应商信用评价规范》应用试点示范平台</p>
				</router-link>
			</div>
		</div>
		<div class="scfl">
			<div class="main">
				<div class="scfl_top">
					<router-link target="_blank" to="/index" class="item top_item1">
						数智商城
					</router-link>
					<router-link target="_blank" to="/supplyView/zyjc" class="item top_item2">
						数智集采
					</router-link>
					<router-link target="_blank" to="/supplyView/szck" class="item top_item3">
						数字仓库
					</router-link>
					<router-link target="_blank" to="/supplyView/logistics" class="item top_item4">
						应急物流
					</router-link>
				</div>
				<div class="scfl_banner">
					<el-carousel height="500px" arrow="hover" v-loading="loadingAd">
						<el-carousel-item v-for="item in adList" :key="item.adv_id">
							<el-image :src="$img(item.adv_image)" fit="cover"
								@click="$router.pushToTab(item.adv_url.url)" />
						</el-carousel-item>
					</el-carousel>
					<div class="shop-list-content"
						:class="forceExpand || isShopHover || isIndex ? 'shop-list-active' : 'shadow'">
						<div class="shop-list" v-if="categoryConfig.category ==1" @mouseover="shopHover"
							@mouseleave="shopLeave">
							<div class="list-item" v-for="(item, index) in goodsCategoryTree" :key="index"
								@mouseover="selectedCategory = item.category_id" @mouseleave="selectedCategory = -1">
								<router-link
									:to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }"
									target="_blank">
									<div>
										<img class="category-img" v-if="categoryConfig.img == 1"
											:src="$util.img(item.image)" />
										<p class="category-name">{{item.category_name }}</p>
									</div>
									<i class="el-icon-arrow-right" aria-hidden="true"></i>
								</router-link>
							</div>
						</div>


						<!-- 分类类型2 -->
						<div class="shop-list"
							:class="forceExpand || isShopHover || isIndex ? 'shop-list-active' : 'shadow'"
							v-else-if="categoryConfig.category ==2" @mouseover="shopHover" @mouseleave="shopLeave">
							<div class="list-item" v-for="(item, index) in goodsCategoryTree" :key="index"
								@mouseover="selectedCategory = item.category_id" @mouseleave="selectedCategory = -1">
								<router-link
									:to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }"
									target="_blank">
									<div>
										<img class="category-img" v-if="categoryConfig.img == 1"
											:src="$util.img(item.image)" />
										<p class="category-name">{{ item.category_name }}</p>

									</div>
									<i class="el-icon-arrow-right" aria-hidden="true"></i>
								</router-link>
								<div class="cate-part" v-if="item.child_list"
									:class="{ show: selectedCategory == item.category_id }">
									<div class="cate-part-col1">
										<div class="cate-detail">
											<div class="cate-detail-item">
												<div class="cate-detail-con">
													<router-link v-for="(second_item, second_index) in item.child_list"
														:key="second_index" target="_blank"
														:to="{ path: '/list', query: { category_id: second_item.category_id, level: second_item.level } }">
														<img class="cate-detail-img" v-if="categoryConfig.img == 1"
															:src="$util.img(second_item.image)" />
														<p class="category-name">{{second_item.category_name }}</p>
													</router-link>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>


						<!-- 分类类型3 -->
						<div class="shop-list"
							:class="forceExpand || isShopHover || isIndex ? 'shop-list-active' : 'shadow'"
							v-else-if="categoryConfig.category ==3" @mouseover="shopHover" @mouseleave="shopLeave">
							<div class="list-item" v-for="(item, index) in goodsCategoryTree" :key="index"
								@mouseover="selectedCategory = item.category_id" @mouseleave="selectedCategory = -1">
								<router-link
									:to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }"
									target="_blank">
									<div class="list-item-left">
										<img class="category-img" v-if="categoryConfig.img == 1"
											:src="$util.img(item.image)" />
										<p class="category-name">{{item.category_name }}</p>
									</div>
									<!-- <i class="el-icon-arrow-right" aria-hidden="true" v-if="item.child_list"></i>  -->
								</router-link>
								<div class="item-itm " :class="{'item-itm-img':categoryConfig.img == 1}">
									<router-link v-for="(second_item, second_index) in item.child_list"
										:key="second_index"
										:to="{ path: '/list', query: { category_id: second_item.category_id, level: second_item.level } }"
										target="_blank" v-show="second_index < 3" style="display:inline-block;">
										{{ second_item.short_name?second_item.short_name : second_item.category_name }}
									</router-link>
								</div>
								<div class="cate-part" v-if="item.child_list"
									:class="{ show: selectedCategory == item.category_id }">
									<div class="cate-part-col1">
										<div class="cate-detail">
											<dl class="cate-detail-item"
												v-for="(second_item, second_index) in item.child_list"
												:key="second_index">
												<dt class="cate-detail-tit">
													<router-link target="_blank"
														:to="{ path: '/list', query: { category_id: second_item.category_id, level: second_item.level } }">
														{{ second_item.category_name }}
													</router-link>
												</dt>
												<dd class="cate-detail-con" v-if="second_item.child_list">
													<router-link
														v-for="(third_item, third_index) in second_item.child_list"
														:key="third_index" target="_blank"
														:to="{ path: '/list', query: { category_id: third_item.category_id, level: third_item.level } }">
														<img class="cate-detail-img" v-if="categoryConfig.img == 1"
															:src="$util.img(third_item.image)" />
														<p class="category-name">{{third_item.category_name }}</p>
													</router-link>
												</dd>
											</dl>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- 主要功能 -->
		<div class="zygn">
			<div class="main">
				<div class="section-heading">
					<h2>主要功能与服务</h2>
					<p>Main functions and services</p>
					<img src="@/assets/images/supply/decor2.png">
				</div>
				<div class="zygn_wrap">
					<div class="item" v-for="(item,index) in zygnList" :key="index">
						<div class="item_title">
							{{item.title}}
						</div>
						<div class="item-subtitle">
							{{item.yw}}
						</div>
						<div class="panldian">

						</div>
						<div class="panlmsg">
							<p>{{item.content}}</p>
							<div class="panlckxq">
								<router-link :to="item.path" class="right-btn">
									进入>>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 最新入驻 -->
		<div class="zygn zxrz">
			<div class="main">
				<div class="section-heading">
					<h2>最新入驻</h2>
					<p style="color: #fff;">Newly settled</p>
					<img src="@/assets/images/supply/decor2.png">
				</div>
				<div class="zxrz_wrap">
					<div class="item" v-for="(item,index) in zxrzList" :key="index">
						<div class="item_wrap">
							<router-link :to="'/supplier/companyDetail?id='+item.id" target="_blank">
								<div class="item_top">
									<div class="zxrz_item_img">
										<img :src="item.logo?$img(item.logo):require('@/assets/images/logo.png')">

									</div>
									<div class="zxrz_item_name">{{item.name}}</div>
								</div>
							</router-link>
							<div class="zxrz_item_nr" style="padding-top: 20px;">
								<span>法人代表：</span>{{item.legal_person}}
							</div>
							<div class="zxrz_item_nr">
								<span>统一代码：</span>{{item.unicode}}
							</div>
							<div class="zxrz_item_nr">
								<span>成立日期：</span>{{item.cl_time}}
							</div>
							<div class="zxrz_item_nr">
								<span>所属行业：</span>{{item.industry_name}}
							</div>
						</div>
					</div>
				</div>
				<!-- 合格供应商 -->
				<div class="enterp-server">
					<div class="enterp_left">
						<div class="left_button">
							<router-link style="margin-left: 24px;margin-right: 100px;" to="/supplyLogin/register"
								target="_blank">
								申请成为合格供应商
							</router-link>
							<router-link to="/supplier/gys" target="_blank">
								供应商库
							</router-link>
						</div>
						<div class="left_img">
							<img src="@/assets/images/supply/qytb.png">
							<router-link to="/supplyLogin/register" target="_blank">
								现在申请
							</router-link>
						</div>
					</div>
					<div class="enterp_center">
						<el-image style="width: 100%; height: auto" :src="$img('upload/company/zs_hggys.png')"
							:preview-src-list="[$img('upload/company/zs_hggys.png')]">
						</el-image>
					</div>
					<div class="enterp_right">
						<p class="title">实时统计：</p>
						<ul>
							<li>
								<p>
									本周新增合格供应商数：<span>{{statisticsData.new_company}}家</span>
								</p>
							</li>
							<li>
								<p>
									合格供应商总数数：<span>{{statisticsData.company}}家</span>
								</p>
							</li>
							<li>
								<p>
									应急产品品类总数：<span>{{statisticsData.category}}种</span>
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="zygn qyfn">
			<div class="main">
				<div class="section-heading">
					<h2>企业赋能百宝箱</h2>
					<p>Enterprise empowerment treasure chest</p>
					<img src="@/assets/images/supply/decor2.png">
				</div>
				<ul class="qyfn_wrap">
					<li class="item" v-for="(item,index) in qyfnList" :key="index">
						<div class="item_wrap" :class="{active:index==qyfnIndex}" @click="qyfnIndex = index">
							<img :src="item.img" alt="">
							<p>{{item.title}}</p>
						</div>
					</li>
				</ul>
				<div class="qyfn_children">
					<router-link :to="item.path" class="children_item"
						:class="{items:qyfnList[qyfnIndex].childList.length<4}"
						v-for="(item,index) in qyfnList[qyfnIndex].childList" :key="index">
						<img :src="item.img" alt="">
						<p>{{item.title}}</p>
					</router-link>
				</div>

			</div>
		</div>
		<div class="jrwm">
			<div class="main">
				<div class="ad_wrap">
					<div class="ad-info">
						<h3>即刻加入</h3>
						<p>应急保障储备社会化数字协同平台</p>
						<h4>享受平台<span>全方位</span>的服务</h4>
					</div>
					<div class="btn-box">
						<router-link to="/supplyLogin/register" class="l-btn green">
							立即注册
						</router-link>
						<router-link to="/supplyView/aboutePage?type=gywm" class="l-btn">
							联系我们
						</router-link>
					</div>
				</div>
				<el-form :model="formData" :inline="true" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item prop="name">
						<el-input class="input" v-model="formData.name" placeholder="联系人"></el-input>
					</el-form-item>
					<el-form-item prop="mobile">
						<el-input v-model="formData.mobile" placeholder="手机"></el-input>
					</el-form-item>
					<el-form-item prop="company">
						<el-input v-model="formData.company" placeholder="公司名称"></el-input>
					</el-form-item>
					<el-form-item prop="content">
						<el-input v-model="formData.content" placeholder="服务需求"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button class="btn" type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		adList
	} from "@/api/website"
	import {
		tree,
		categoryConfig
	} from '@/api/goods/goodscategory';
	import {
		new_settled,
		statistics
	} from '@/api/supply/supply';
	export default {
		name: "supplyIndex",
		components: {},
		data: () => {
			return {
				statisticsData: {
					category: 0,
					company: 0,
					new_company: 0
				},
				formData: {
					name: "",
					mobile: "",
					content: "",
					company: ""
				},
				rules: {
					name: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						message: '请输入联系方式',
						trigger: 'blur',
					}],
					company: [{
						required: true,
						message: '请输入公司名称',
						trigger: 'blur'
					}],
					content: [{
						required: true,
						message: '请输入服务需求',
						trigger: 'blur'
					}],
				},
				keyword: "",
				hotSearchWords: ['口罩', '酒精', '一次性口罩', '面包', '方便面'],
				adList: [],
				loadingAd: true,
				forceExpand: false,
				isShopHover: false,
				isIndex: true,
				categoryConfig: {},
				goodsCategoryTree: [],
				selectedCategory: -1,
				zygnList: [{
						title: "物资寻源",
						yw: "Material Sourcing",
						content: "根据采购人位置和产品名称，快速寻找本地区提供紧急采购的供应商的名录、产品许可、产品规格、技术参数、物流条件、供给能力等。",
						path: "/supplyView/productSearch"
					},
					{
						title: "客户优选",
						yw: "Customer preference",
						content: "对区域供给同类应急物资的资源根据企业规模、竞争力、创新能力、售后服务、公共信用记录等12个指标选择最优秀的供应商。",
						path: "/supplier/gys"
					},
					{
						title: "产业地图",
						yw: "Industry map",
						content: "涉及36个行业1700余类应急保障物资供给资源区域分类分布示意图；平台供应商能力分布示意图；年度供给能力变化趋势图等。",
						path: "/supplymap"
					},
					{
						title: "信用服务",
						yw: "Credit service",
						content: "着眼于受评主体在一段时期的企业特质、运营能力、偿付能力、公共信用记录等信息，着重分析企业自身信用以及外部环境对受评主体经营及信用产生的影响。",
						path: "/"
					}
				],
				zxrzList: [],
				qyfnList: [{
						title: "成长计划",
						img: require("@/assets/images/supply/jh.png"),
						childList: [{
								title: "品牌战略计划",
								img: require("@/assets/images/supply/zxjh1.jpg"),
								path: ""
							}, {
								title: "供应链培育计划",
								img: require("@/assets/images/supply/zxjh2.png"),
								path: ""
							},
							{
								title: "银企通培育计划",
								img: require("@/assets/images/supply/zxjh3.jpg"),
								path: ""
							},
							{
								title: "厂直营销培育计划",
								img: require("@/assets/images/supply/zxjh4.jpg"),
								path: ""
							}
						]
					},
					{
						title: "精准营销",
						img: require("@/assets/images/supply/yx.png"),
						childList: [{
								title: "智能营销",
								img: require("@/assets/images/supply/yx1.jpg"),
								path: ""
							}, {
								title: "直面市场",
								img: require("@/assets/images/supply/zmsc1.png"),
								path: ""
							},
							{
								title: "公共采购",
								img: require("@/assets/images/supply/ggcg5.png"),
								path: ""
							}
						]
					},
					{
						title: "平台生态",
						img: require("@/assets/images/supply/sc1.png"),
						childList: [{
								title: "产品检测",
								img: require("@/assets/images/supply/jc1.png"),
								path: ""
							}, {
								title: "产品追溯",
								img: require("@/assets/images/supply/cpzs5.jpg"),
								path: ""
							},
							{
								title: "智慧物流",
								img: require("@/assets/images/supply/zmsc5.png"),
								path: ""
							},
							{
								title: "保险服务",
								img: require("@/assets/images/supply/bx1.png"),
								path: ""
							}
						]
					},
					{
						title: "产业赋能",
						img: require("@/assets/images/supply/fn.png"),
						childList: [{
								title: "科技赋能",
								img: require("@/assets/images/supply/kjfn5.png"),
								path: ""
							}, {
								title: "产能复能",
								img: require("@/assets/images/supply/kjfn4.png"),
								path: ""
							},
							{
								title: "产业孵化",
								img: require("@/assets/images/supply/jrfn1.png"),
								path: ""
							}
						]
					}
				],
				qyfnIndex: 0
			};
		},
		computed: {

		},
		created() {
			this.getAdList()
			this.getCategoryConfig();
			this.getNewCompany()
		},
		methods: {
			//立即提价
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//鼠标移入显示商品分类
			shopHover() {
				this.isShopHover = true;
			},
			//鼠标移出商品分类隐藏
			shopLeave() {
				this.isShopHover = false;
			},
			//搜索
			search() {
				this.$router.push({
					path: '/supplyView/productSearch',
					query: {
						keyword: this.keyword,
						search_type: 'wz'
					}
				})
			},
			getNewCompany() {
				statistics().then(res => {
						if (res.code == 0 && res.data) {
							// this.zxrzList = res.data;
							this.statisticsData = res.data
						}
					})
					.catch(err => {
						this.$message.error(err.message);
					});
				new_settled().then(res => {
						if (res.code == 0 && res.data) {
							this.zxrzList = res.data;
						}
					})
					.catch(err => {
						this.$message.error(err.message);
					});
			},
			//获取配置
			getCategoryConfig() {
				categoryConfig({

					})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.categoryConfig = res.data;
							this.getTree(res.data);
						}
					})
					.catch(err => {
						this.$message.error(err.message);
					});
			},
			getTree(categoryConfig) {
				tree({
						level: 3,
						template: 2
					})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.goodsCategoryTree = res.data || [];
							for (let i = 0; i < this.goodsCategoryTree.length; i++) {
								if (this.goodsCategoryTree[i].child_list > 3) {
									this.isHide = true
								}
							}
						}
					})
					.catch(err => {
						this.$message.error(err.message);
					});
			},
			getAdList() {
				adList({
						keyword: "NS_PC_INDEX"
					})
					.then(res => {
						this.adList = res.data.adv_list
						for (let i = 0; i < this.adList.length; i++) {
							if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
						}
						this.backgroundColor = this.adList[0].background
						this.loadingAd = false
					})
					.catch(err => {
						this.loadingAd = false
					})
			},
		}
	};
</script>
<style scoped>
	/deep/.el-carousel__arrow {
		width: 46px;
		height: 46px;
		font-size: 20px;
	}

	/deep/ .el-carousel__arrow--left {
		left: 210px;

	}

	/deep/.el-input__inner {
		background: #fff;
		border-radius: 4px;
		height: 50px;
		padding-left: 16px;

	}

	/deep/.el-form-item__error {
		font-size: 16px;
	}

	/deep/.el-input__inner::placeholder {
		color: #666;
	}
</style>
<style scoped lang="scss">
	.container {
		.banner {
			background: url('../../../assets/images/supply/bg2.png') no-repeat;
			background-size: 100% 100%;
			width: 100%;
			height: 600px;
			z-index: -1;
			margin-top: 80px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.zbdw {
					text-shadow: #ffffff 0 0 1px;
					font-weight: bold;
					color: $base-supply-color;
					margin-top: 30px;
					font-size: 16px;
					padding-top: 20px;
				}

				.logo_wrap {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 20px 0 50px;

					p {
						.el-image {
							max-width: 120px;
							height: auto;
						}

						&.zw {
							text-shadow: #ffffff 1px 1px 1px;
							font-size: 38px;
							letter-spacing: 5px;
							font-weight: bold;
							color: $base-supply-color;
							margin-top: 30px;
						}

						&.yw {
							color: $base-supply-color;
							font-size: 19px;
							font-weight: bold;
							text-shadow: #ffffff 1px 1px 1px;
						}
					}
				}

				.in-sousuo {

					margin: 0 auto;
					padding: 40px 0;
					margin-top: 70px;

					.sousuo-box {
						width: 66%;
						height: 36px;
						box-sizing: border-box;
						margin: 0 auto;
						display: flex;
						align-items: center;

						input {
							flex: 1;
							// height: 22px;
							background: none;
							outline: none;
							border-radius: 4px;
							border: none;
							background-color: #e8f0fe;
							padding: 10px 20px;
						}

						button {
							border-radius: 4px;
							// float: right;
							background-color: $base-supplyfuzhu-color;
							border: none;
							margin: 0;
							height: 42px;

							&:hover {
								background-color: #6c757d;
							}
						}
					}

					.hot-search-words {
						width: 66%;
						margin: 30px auto 0;
						font-size: 14px;

						span {
							float: left;
							margin-right: 20px;
							color: #515a6e;
							font-size: 16px;
							font-weight: 600;
						}

						ul {
							float: left;
							color: #fff;
							font-size: 16px;

							li {
								cursor: pointer;
								list-style: none;
								float: left;
								margin-right: 10px;

								&:hover {
									color: #fa430a;
								}
							}
						}
					}
				}

			}

		}

		.sfdw {
			padding: 40px 0;
			background-color: #FFE699;
			text-align: center;

			p {
				font-size: 30px;
				color: #D7563A;
				font-weight: bold;
			}
		}

		.scfl {
			margin: 80px auto;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.scfl_top {
					display: flex;
					align-items: center;

					.item {
						height: 60px;
						line-height: 60px;
						color: #282828;
						font-size: 20px;
						font-weight: 400;
						cursor: pointer;
						display: inline-block;
						text-align: center;
						float: left;
						background: #f8f0f0;
						border: 1px solid #fff;
					}

					.top_item1 {
						flex: 1;
						height: 60px;
						background: #e93323;
						color: #fff;
						background-image: url('../../../assets/images/supply/item1.png');
						background-size: 40px 40px;
						background-repeat: no-repeat;
						background-position-x: 20%;
						background-position-y: 10px;
					}

					.top_item2 {
						flex: 1;
						height: 60px;
						background-image: url('../../../assets/images/supply/item22.png');
						background-size: 40px 40px;
						background-repeat: no-repeat;
						background-position-x: 20%;
						background-position-y: 10px;

						&:hover {
							background-color: #e93323;
							background-image: url('../../../assets/images/supply/item2.png');
							color: #fff;

						}
					}

					.top_item3 {
						flex: 1;
						height: 60px;
						background-image: url('../../../assets/images/supply/item33.png');
						background-size: 40px 40px;
						background-repeat: no-repeat;
						background-position-x: 20%;
						background-position-y: 10px;

						&:hover {
							background-color: #e93323;
							background-image: url('../../../assets/images/supply/item3.png');
							color: #fff;

						}
					}

					.top_item4 {
						flex: 1;
						height: 60px;
						background-image: url('../../../assets/images/supply/item44.png');
						background-size: 40px 40px;
						background-repeat: no-repeat;
						background-position-x: 20%;
						background-position-y: 10px;

						&:hover {
							background-color: #e93323;
							background-image: url('../../../assets/images/supply/item4.png');
							color: #fff;

						}
					}
				}

				.scfl_banner {
					height: 500px;
					width: 100%;
					position: relative;

					.el-image {
						width: 100%;
						height: 100%;
					}

					.shop-list-content {
						width: 210px;
						height: 500px;
						position: absolute;
						left: 0;
						top: 0px;
						background-color: rgba(0, 0, 0, .4);
						display: none;
						padding: 0;
						box-sizing: border-box;
						font-size: $ns-font-size-base;
						z-index: 10;
						color: #FFFFFF;

						&::-webkit-scrollbar {
							display: none;
						}

						&.shop-list-active {
							display: block;
						}

						&.shadow {
							box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
						}

						// overflow: hidden;
						.shop-list {
							width: 210px;
							height: 500px;
							overflow-y: auto;
							overflow-x: hidden;

							// box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
							&::-webkit-scrollbar {
								display: none;
							}

							// a:hover {
							// 	background-color: #e93323;
							// 	-webkit-transition: 0.2s ease-in-out;
							// 	-moz-transition: -webkit-transform 0.2s ease-in-out;
							// 	-moz-transition: 0.2s ease-in-out;
							// 	transition: 0.2s ease-in-out;
							// }

							.list-item {
								padding-left: 12px;
								box-sizing: border-box;
								// min-height:50px;
								// height: 45px;
								// line-height: 50px;
								padding: 13px;

								a {
									display: flex;
									justify-content: space-between;
									align-items: center;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									// width: 95%;
									// display: block;
									color: #FFFFFF;

									>div {
										display: flex;
										align-items: center;
									}
								}

								&:hover {
									background-color: #e93323;
									-webkit-transition: 0.2s ease-in-out;
									-moz-transition: -webkit-transform 0.2s ease-in-out;
									-moz-transition: 0.2s ease-in-out;
									transition: 0.2s ease-in-out;

									a:hover {
										// color: #FD274A;
									}
								}

								span:hover {
									// color: #FD274A;
								}

								.category-img {
									width: 17px;
									height: 17px;
									margin-right: 10px;
								}

								.category-name {
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
								}

								.item-itm {
									font-size: 14px;
									line-height: 15px;
									height: 28px;
									overflow: hidden;

									a {
										margin-top: 5px;
										margin-right: 10px;
										color: #BFBFBF;

										&:hover {
											// color: #FD274A !important;
										}
									}

									&.item-itm-img {
										margin-left: 27px;
									}
								}

								.cate-part {
									display: none;
									position: absolute;
									left: 210px;
									top: 0;
									z-index: auto;
									// width: 998px;
									width: 760px;
									height: 498px;
									overflow-y: auto;
									border: 1px solid #f7f7f7;
									background-color: #fff;
									-webkit-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
									-moz-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
									box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
									-webkit-transition: top 0.25s ease;
									-o-transition: top 0.25s ease;
									-moz-transition: top 0.25s ease;
									transition: top 0.25s ease;

									&.show {
										display: block;
									}

									&::-webkit-scrollbar {
										display: none;
									}
								}

								.cate-part-col1 {
									float: left;
									width: 100%;

									.cate-detail-item {
										position: relative;
										min-height: 36px;
										padding-left: 20px;

										&:last-child {
											margin-bottom: 30px;
										}

										.cate-detail-tit {
											margin-top: 30px;
											margin-right: 20px;
											white-space: nowrap;
											text-overflow: ellipsis;
											font-weight: 700;

											a {
												display: block;
												color: #333333;
											}

											a:hover {
												// color: #FD274A;
											}

										}

										.cate-detail-con {
											overflow: hidden;
											padding: 6px 0 6px 17px;
											display: flex;
											flex-wrap: wrap;

											// border-top: 1px dashed #eee;
											a {
												justify-content: start;
												font-size: 12px;
												height: 30px;
												/* float: left; */
												margin: 4px 40px 4px 0;
												padding: 0 10px;
												white-space: nowrap;
												line-height: 30px;
												color: #666;
												width: calc((100% - 120px) / 4);
												display: flex;
												box-sizing: border-box;
												margin-top: 20px;

												.cate-detail-img {
													width: 30px;
													height: 30px;
													margin-right: 10px;
												}

												&:nth-child(4n+4) {
													margin-right: 0;
												}
											}

											a:hover {
												// color: #FD274A;
											}
										}

										&:first-child .cate-detail-con {
											border-top: none;
										}
									}
								}

								// .sub-class-right {
								// 	display: block;
								// 	width: 240px;
								// 	height: 439px;
								// 	float: right;
								// 	border-left: solid 1px #e6e6e6;
								// 	overflow: hidden;
								// 	.adv-promotions {
								// 		display: block;
								// 		height: 441px;
								// 		margin: -1px 0;
								// 		a {
								// 			background: #fff;
								// 			display: block;
								// 			width: 240px;
								// 			height: 146px;
								// 			border-top: solid 1px #e6e6e6;
								// 			img {
								// 				background: #d3d3d3;
								// 				width: 240px;
								// 				height: 146px;
								// 			}
								// 		}
								// 	}
								// }
							}
						}
					}
				}
			}
		}

		.zygn {
			background: #f1f3f7;
			padding-bottom: 70px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.section-heading {
					padding: 50px 0;
					text-align: center;

					h2 {
						margin-bottom: 10px;
						letter-spacing: 5px;
						font-size: 32px;
						color: #77b122;
						line-height: 1.3;
					}

					p {
						font-size: 18px;
						text-transform: uppercase;
						font-family: Verdana;
					}
				}

				.zygn_wrap {
					display: flex;
					align-items: center;
					justify-content: center;

					.item {
						flex: 1;
						background-size: 100% 100%;
						height: 360px;
						transition: all .3s ease;
						margin-right: 2%;
						position: relative;
						background-image: url('../../../assets/images/supply/index_cpxy.png');

						&:nth-child(n+4) {
							margin-right: 0;
						}

						.item_title {
							font-size: 24px;
							-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
							padding-left: 30px;
							margin-top: 25px;
							color: #fff;
						}

						.item-subtitle {
							font-size: 14px;
							padding-left: 30px;
							-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
							opacity: .7;
							color: #fff;
						}

						.panldian {
							margin-left: 30px;
							margin-top: 16px;
							width: 60px;
							border: 1px solid #ff712a;
						}

						.panlmsg {
							padding: 0 24px;
							margin-top: 20px;
							max-width: 288px;
							font-size: 14px;
							color: #fff;
							text-align: left;
							line-height: 45px;
							overflow: hidden;
							-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
							opacity: .7;
							list-style: inside;

							p {
								color: #fff;
								text-indent: 2em;
								font-size: 15px;
								height: 125px;
								text-align: justify;
								line-height: 1.7;
							}

							.panlckxq {
								margin-top: 25px;

								.right-btn {
									text-align: center;
									display: inline-block;
									width: 45%;
									height: 50px;
									line-height: 50px;
									border-radius: 10px;
									background-color: #2388f9;
									font-size: 20px;
									font-weight: 700;
									color: #fff;
									cursor: pointer;
								}
							}
						}
					}
				}
			}

			&.zxrz {
				background: url('../../../assets/images/supply/zxsq1.png') top no-repeat, url('../../../assets/images/supply/zxsq2.png') bottom no-repeat;
				background-color: #416aa6;
				// height: 850px;
				padding-bottom: 70px;

				.zxrz_wrap {
					display: flex;
					align-items: center;
					justify-content: center;

					.item {
						flex: 1;
						max-width: 25%;
						padding: 0 15px;

						.item_wrap {
							padding: 18px 20px;
							color: #666;
							background-image: url('../../../assets/images/supply/rzbj.jpg');
							background-size: 100% 100%;
							border-radius: 5px;

							.item_top {
								padding-top: 12px;

								.zxrz_item_img {
									width: 90px;
									height: 48px;
									position: relative;
									border-radius: 7px;
									overflow: hidden;
									float: left;

									img {
										max-width: 100%;
										max-height: 100%;
										position: absolute;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%);
										-webkit-transform: translate(-50%, -50%);
									}
								}

								.zxrz_item_name {
									width: 130px;
									font-size: 16px;
									line-height: 24px;
									color: #0b72e7;
									font-weight: 700;
									float: right;
								}
							}

							.zxrz_item_nr {
								clear: both;
								color: #7f7f7f;
								font-size: 12px;
								line-height: 24px;
								font-weight: 700;
								margin-top: 3px;

								span {
									font-size: 14px;
									color: #404040 !important;
								}
							}
						}

					}
				}

				.enterp-server {
					margin-top: 80px;
					padding: 0 20px;
					display: flex;
					align-items: flex-end;
					justify-content: space-between;

					.enterp_left {
						width: 52%;

						.left_button {
							line-height: 24px;
							position: relative;

							&:before {
								content: "";
								display: block;
								width: 8px;
								height: 24px;
								border-radius: 24px;
								background: #91b8f1;
								position: absolute;
								left: 0;
								top: 0;
							}

							a {
								background: #91b8f1;
								border-radius: 20px;
								padding: 3px 10px;
								color: #fff;
								font-size: 18px;
							}
						}

						.left_img {
							width: 100%;

							position: relative;
							margin-left: 24px;
							margin-top: 30px;

							img {
								height: auto;
								max-width: 100%;
							}

							a {
								display: inline-block;
								width: 120px;
								height: 40px;
								border-radius: 3px;
								background: #91b8f1;
								font-size: 18px;
								text-align: center;
								line-height: 40px;
								color: #fff;
								position: absolute;
								right: 21px;
								bottom: 14px;
							}
						}
					}

					.enterp_center {
						width: 14%;
						flex-shrink: 0;
						margin-left: 44px;
					}

					.enterp_right {
						width: 20%;
						flex-shrink: 0;

						.title {
							color: #fff;
							font-size: 20px;
							margin-bottom: 20px;
							line-height: 1.3;
						}

						ul {
							margin-bottom: 30px;

							li {
								box-sizing: border-box;
								width: 100%;
								height: 33px;
								border-radius: 3px;
								background: #fc6b51;
								font-weight: 700;
								margin-top: 45px;
								line-height: 33px;
								padding: 0 15px;

								&:first-child {
									margin-top: 10px;
								}

								p {
									display: inline-block;
									min-width: 135px;
									font-size: 14px;
									color: #fff;
									font-weight: 400;
								}
							}
						}
					}
				}
			}

			&.qyfn {
				background: #FDFDFD;

				.qyfn_wrap {
					display: flex;
					align-items: center;
					border-bottom: 1px solid #F3F3F3;
					margin-top: 8px;
					justify-content: space-between;

					.item {
						flex: 1;
						border: 2px solid #fff;


						.item_wrap {
							padding: 16px 0;
							display: flex;
							align-items: center;
							flex-direction: column;
							cursor: pointer;

							&:hover {
								background: #fff;
								border-bottom: 2px solid #0065dd;

								p {
									color: #0065dd;
								}
							}

							&.active {
								border-bottom: 2px solid #0065dd;

								p {
									color: #0065dd;
								}
							}
						}

						// box-sizing: border-box;


						img {
							width: 80px;
							height: 80px;
							margin: 0 auto;
							background-size: 100%;
						}

						p {
							font-size: 20px;
							margin-top: 20px;
							color: #333;
							font-weight: 700;
						}
					}
				}

				.qyfn_children {
					margin-top: 45px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.children_item {
						flex: 1;
						margin-right: 5%;
						display: flex;
						align-items: center;
						flex-direction: column;

						&:last-child {
							margin-right: 0;
						}

						img {
							height: 260px;
							width: 100%;
							border-radius: 15px;

						}

						&.items {
							img {
								height: 280px;
							}
						}

						p {
							color: #333;
							font-size: 20px;
							letter-spacing: 0;
							text-align: center;
							margin-top: 8px;
						}
					}
				}
			}
		}

		.jrwm {
			background: url('../../../assets/images/supply/bg02.png');
			background-size: 100% 100%;
			padding-bottom: 50px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.ad_wrap {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 280px;

					.ad-info {
						margin-right: 60px;
						width: calc(100% - 400px);

						h3 {
							text-shadow: #212529 1px 1px 1px;
							font-size: 36px;
							color: #ffffff;
							line-height: 48px;
							font-weight: 400;
						}

						p {
							text-shadow: #212529 1px 1px 1px;
							font-size: 22px;
							color: #ffffff;
							letter-spacing: 0;
							line-height: 28px;
						}

						h4 {
							text-shadow: #212529 1px 1px 1px;
							margin-top: 20px;
							font-size: 26px;
							color: #ffffff;
							line-height: 48px;
							font-weight: 400;

							span {
								text-shadow: #999 1px 1px 1px;
								font-size: 30px;
								color: #ff0000;
							}
						}
					}

					.btn-box {
						width: 400px;
						display: flex;
						align-items: center;

						.l-btn {
							height: 48px;
							line-height: 48px;
							padding: 0 30px;
							color: #ffffff;
							border: 1px solid #fff;
							background: transparent;
							border-radius: 4px;
							font-size: 16px;
							vertical-align: middle;
							text-align: center;
							transition: all 0.3s;

							&.green {
								color: #fff;
								border: 1px solid #00adbb;
								background: #00adbb;
								margin-right: 13px;

								&:hover {
									border-color: #29bece;
									background: #29bece;
								}
							}
						}
					}
				}

				.demo-ruleForm {
					text-align: center;

					.btn {
						width: 176px;
						height: 50px;
						background: #2386f7;
						font-size: 20px;
						color: #fff;
						letter-spacing: .71px;
						text-align: center;
						font-weight: 700;
						border-radius: 2px;
						border-color: #2386f7;
					}
				}
			}
		}
	}
</style>