export default {
	path: "/",
	component: () => import("@/views/supplyView/index"),
	redirect: "/supplyView/index",
	name: "supplyIndexPage",
	children: [{
			path: "/supplyView/index",
			name: "supplyIndex",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/index/index")
		},
		{
			path: "/supplyView/logistics",
			name: "supplyLogistics",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/index/logistics")
		},
		{
			path: "/supplyView/aboutePage",
			name: "supplyAboute",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/index/aboutePage")
		},
		{
			path: "/supplyView/productSearch",
			name: "supplyProductSearch",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/index/productSearch")
		},
		{
			path: "/supplyView/szck",
			name: "supplySzck",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/index/szck")
		},
		{
			path: "/supplyView/zyjc",
			name: "supplyZyjc",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/index/zyjc")
		},
		{
			path: "/supplyView/zyjcDetail",
			name: "supplyZyjcDetail",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/index/zyjcDetail")
		}
	]

}