<template>
	<div class="container">
		<div class="login_wrap">
			<div class="main">
				<h2>中国合格供应商管理平台</h2>
				<div class="grid-content bg-purple login_tab">
					<h3>登录</h3>
					<el-form :model="formData" :rules="accountRules" ref="ruleForm">
						<el-form-item prop="mobile">
							<el-input v-model="formData.mobile" placeholder="请输入账号">
								<template slot="prepend">
									<i class="iconfont iconzhanghao"></i>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input type="password" v-model="formData.password" autocomplete="off"
								placeholder="请输入登录密码">
								<template slot="prepend">
									<i class="iconfont iconmima"></i>
								</template>
							</el-input>
						</el-form-item>
						<!-- 验证码显示与否字段shop_pc_reception_login -->
						<!-- <el-form-item prop="vercode">
							<el-input v-model="formData.vercode" autocomplete="off" placeholder="请输入验证码" maxlength="4">
								<template slot="prepend">
									<i class="iconfont iconyanzhengma"></i>
								</template>
								<template slot="append">
									<img :src="captcha.img" mode class="captcha" @click="getCaptcha" />
								</template>
							</el-input>
						</el-form-item> -->
						<el-form-item>
							<el-row>
								<el-col :span="12">
									<el-checkbox v-model="formData.checked">七天自动登录</el-checkbox>
								</el-col>
								<el-col :span="12" class="ns-forget-pass">
									<router-link to="/supplyLogin/supplyFind" class>忘记密码?</router-link>
								</el-col>
							</el-row>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" class="rule-button" @click="accountLogin('ruleForm')">
								登录</el-button>
						</el-form-item>

						<el-form-item>
							<el-row>
								<el-col :span="24">
									<div class="bg-purple-light">没有账号？<router-link class="register"
											to="/supplyLogin/register">立即注册</router-link>
									</div>
								</el-col>
							</el-row>
						</el-form-item>
					</el-form>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		captcha,
		captchaConfig
	} from "@/api/website"
	import {
		tree,
		categoryConfig
	} from '@/api/goods/goodscategory';
	export default {
		name: "supplyIndex",
		components: {},
		data: () => {
			return {
				formData: {
					password: "",
					mobile: "",
					vercode: ""
				},
				accountRules: {
					mobile: [{
						required: true,
						message: '请输入账号或手机号',
						trigger: 'blur',
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					// vercode: [{
					// 	required: true,
					// 	message: '请输入验证码',
					// 	trigger: 'blur'
					// }],
				},
				captcha: {
					id: "",
					img: ""
				}, // 验证码
				autoLoginRange: 7

			};
		},
		computed: {

		},
		created() {
			this.getCaptcha()
		},
		methods: {
			accountLogin(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						var data = {
							mobile: this.formData.mobile,
							password: this.formData.password
						}

						if (this.captcha.id != "") {
							data.captcha_id = this.captcha.id
							data.captcha_code = this.formData.vercode
						}

						if (this.formData.checked) {
							data.autoLoginRange = this.formData.autoLoginRange
						}

						if (this.isSub) return
						this.isSub = true

						this.$store
							.dispatch("supplymember/login_supply", data)
							.then(res => {
								if (res.code >= 0) {
									this.$message({
										message: "登录成功！",
										type: "success"
									})
									if (this.$route.query.redirect) {
										const a = this.$route.query.redirect
										const b = this.$route.query
										this.$router.push(this.$route.query.redirect)
									} else {
										this.$router.push({
											name: "supplierIndex"
										})
									}
								} else {
									this.isSub = false
									this.getCaptcha()
									this.$message({
										message: res.message,
										type: "warning"
									})
								}
							})
							.catch(err => {
								this.isSub = false
								this.$message.error(err.message)
								this.getCaptcha()
							})
					} else {
						return false
					}
				})
			},
			//获取验证码
			getCaptcha() {
				captcha({
						captcha_id: this.captcha.id
					})
					.then(res => {
						if (res.code >= 0) {
							this.captcha.id = res.data.id
							this.captcha.img = res.data.img
							this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
						}
					})
					.catch(err => {
						this.$message.error(err.message)
					})
			},
			//立即提价
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

		}
	};
</script>
<style scoped>
	/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #379563;
	}

	/deep/.el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: #379563;
		border-color: #379563;
	}

	/deep/.el-checkbox__inner:hover {
		border-color: #379563;
	}

	/deep/.el-input__inner {
		background: #fff;
		border-radius: 4px;
		height: 50px;
		padding-left: 16px;

	}

	/deep/.el-input__inner:focus {
		border-color: #379563;
	}

	/deep/.el-form-item__error {
		font-size: 16px;
	}

	/deep/.el-input__inner::placeholder {
		color: #666;
	}
</style>
<style scoped lang="scss">
	.container {

		.login_wrap {
			overflow: hidden;

			.main {
				width: 380px;
				margin: 10% auto;
				display: flex;
				flex-direction: column;
				align-items: center;
				box-sizing: border-box;

				h2 {
					text-align: center;
					margin-bottom: 35px;
				}



				.grid-content {
					border: 1px solid rgb(208, 208, 208);
					-moz-box-shadow: 0 3px 0 rgba(12, 12, 12, .03);
					-webkit-box-shadow: 0 3px 0 rgb(12 12 12 / 3%);
					box-shadow: 0 3px 0 rgb(12 12 12 / 3%);
					-moz-border-radius: 3px;
					-webkit-border-radius: 3px;
					border-radius: 5px;
					padding: 30px;
					width: 100%;

					h3 {
						text-align: center;
						font-weight: bold;
						font-size: 18px;
						margin-bottom: 30px;
					}

					.el-form {
						.captcha {
							vertical-align: top;
							max-width: inherit;
							max-height: 38px;
							line-height: 38px;
							cursor: pointer;
						}

						.dynacode {
							cursor: pointer;
						}

						[class*=' el-icon-'],
						[class^='el-icon-'] {
							font-size: 16px;
						}
					}

					.el-form-item__error {
						padding-left: 50px;
					}

					button {
						width: 100%;
						background-color: #379563;
						border-color: #379563;
					}

					.ns-forget-pass {
						text-align: right;

						a {
							color: #337ab7;
						}
					}

					i {
						font-size: 18px;
					}

					.bg-purple-light {
						display: flex;
						justify-content: flex-end;
						align-items: center;

						.register {
							color: #337ab7;
						}
					}
				}
			}
		}
	}
</style>