export const mapOption = {
	basicOption: {
		tooltip: {
			trigger: 'item',
			formatter: '{b}<br/>{c}'
		},
		//左侧小导航图标
		// visualMap: {
		//     pieces: [
		//         {min: 0, max:1, label: '已提交', color: '#2c9a42'},
		//         {min: -1, max: 0, label: '未提交', color: '#d08a00'},
		//     ],
		//     textStyle: {
		//         color: '#fff',
		//     },
		//     top:50,
		//     left:30,
		// },
		visualMap: {
			show: true,
			min: 0,
			max: 1,
			bottom: 60,
			left: 60,
			// splitNumber: 5,
			inRange: {
				color: ['#d94e5d', '#eac736', '#50a3ba'].reverse()
			},
			textStyle: {
				color: '#fff'
			},
		},
		geo: {
			map: 'china',
			type: 'map',
			label: {
				show: true, //显示省份标签
				color: "#fff",
				emphasis: { //动态展示的样式
					color: '#1996b8',
				},
			},
			roam: true,
			itemStyle: {
				areaColor: '#0e2f50',
				borderColor: '#1996b8',
				color: '#1996b8',
				emphasis: {
					show: false,
					color: "#fff",
					areaColor: "#0a3767"
				}
			},
			// zoom: 1.2,
			// top: 100
			// layoutCenter: ['30%', '30%'],
			// // 如果宽高比大于 1 则宽度为 100，如果小于 1 则高度为 100，保证了不超过 100x100 的区域
			// layoutSize: 100
			// 设置这两个值后 left/right/top/bottom/width/height 无效。
		}
	},
	seriesOption: {
		type: 'map',
		roam: false,
		nameMap: {
			'china': '中国'
		},
		label: {
			normal: {
				show: true,
				textStyle: {
					color: '#fff',
				}
			},
			emphasis: {
				show: true,
				textStyle: {
					color: '#fff',
				}
			}
		},
		itemStyle: {
			normal: {
				color: '#fff',
				areaColor: '#00007f',
				borderColor: '#fff',
				borderWidth: 0.5,
				label: {
					show: true,
					textStyle: {
						color: "rgb(249, 249, 249)",
					}
				},
			},
			emphasis: {
				areaColor: true,
			}
		},
	}
}