// 会员模块
const memberDetailRoutes = [{
	path: "index",
	name: "supplymember",
	meta: {
		module: "supply",
		auth: true,
	},
	component: () => import("@/views/supplyView/member/index")
}, {
	path: "info",
	name: "supplymemberinfo",
	meta: {
		module: "supply",
		auth: true,
	},
	component: () => import("@/views/supplyView/member/info")
}, {
	path: "authentication",
	name: "supplymemberAuthentication",
	meta: {
		module: "supply",
		auth: true,
	},
	component: () => import("@/views/supplyView/member/authentication")
}, {
	path: "logisticsList",
	name: "supplymemberLogistics",
	meta: {
		module: "supply",
		auth: true,
	},
	component: () => import("@/views/supplyView/member/logisticsList")
}, {
	path: "mygoods",
	name: "supplymemberMygoods",
	meta: {
		module: "supply",
		auth: true,
	},
	component: () => import("@/views/supplyView/member/mygoods")
}, {
	path: "myJcxx",
	name: "supplymemberMyJcxx",
	meta: {
		module: "supply",
		auth: true,
	},
	component: () => import("@/views/supplyView/member/myJcxx")
}, ]
export default {
	path: "/supplymember",
	component: () => import("@/views/supplyView/member"),
	redirect: "index",
	alwaysShow: true,
	name: "supplyMemberIndex",
	children: [{
		path: "/supplymember",
		name: "supplyhome",
		redirect: "index",
		children: memberDetailRoutes,
		component: () => import("@/views/supplyView/member/home")
	}]
}