<template>
	<div id="index">
		<dv-full-screen-container class="bg">
			<!-- <div class="dataweather">
				<div>
					{{dataWeather.city}}
					{{dataWeather.weathertemp}}
					<img :src="dataWeather.icontemp" alt="">
					{{dataWeather.peraturetemp}}
				</div>
			</div> -->
			<!-- <div class="datatime">
				<span>
					{{dataTime.dateYear}} {{dataTime.dateWeek}} {{dataTime.dateDay}}
				</span>
			</div> -->
			<dv-loading v-if="loading">Loading...</dv-loading>
			<div v-else class="host-body">
				<top-header />
				<div class="body-box">
					<div class="content-box info-four">
						<div>
							<dv-border-box-8 class="bg-color-black" style="height: 1rem;padding: .2rem;">
								<div class="topNumber">
									<!-- <top-number /> -->
									<top-number :numberConfig="numberConfig" />
								</div>
							</dv-border-box-8>
						</div>
						<div>
							<dv-border-box-10 style="height: 1rem">
								<center-form :industryList="industryList" :industry_id="industry_id"
									@changeIndus="changeIndus" />
							</dv-border-box-10>
						</div>
						<div>
							<dv-border-box-8 :reverse="true" class="bg-color-black"
								style="height: 1rem;padding: .2rem;">
								<div class="topNumber topNumberRotate">
									<!-- <top-number />
									<top-number /> -->
									<div class="datatime">
										<span>
											{{dataTime.dateYear}} {{dataTime.dateWeek}} {{dataTime.dateDay}}
										</span>
									</div>
								</div>
							</dv-border-box-8>
						</div>
					</div>

					<div class="content-box">
						<div>
							<dv-border-box-12>
								<left-level :industryList="industryList" :total="numberConfig.number[0]" />
							</dv-border-box-12>
							<dv-border-box-10>
								<left-loops />
							</dv-border-box-10>
						</div>
						<div>
							<dv-border-box-11 :title="chinaMapTitle" style="height:9.1rem;">
								<!--                                    echarts地图-->
								<china ref="mapChina" :mapDataList="mapDataList" @map-change="mapClick"
									@back-change="backClick" />
								<!--                                    高德地图-->
								<!--                                <china-gaode/>-->
							</dv-border-box-11>
						</div>
						<div>
							<dv-border-box-13>
								<right-province ref="fzRef" :fzDataList="fzDataList" />
							</dv-border-box-13>
							<dv-border-box-12>
								<right-county :fzDataList="fzDataList" />
							</dv-border-box-12>
						</div>
					</div>
				</div>
			</div>

		</dv-full-screen-container>
	</div>
</template>

<script>
	// import {formatTime} from '../utils/index.js'

	import http from "../../../utils/http"
	import topHeader from "./topHeader"
	import topNumber from "./topNumber"
	import centerForm from "./centerForm"
	import leftLevel from "./leftLevel"
	import leftLoops from "./leftLoops"
	import china from "./china"
	import chinaGaode from "./chinaGaode"
	import rightProvince from "./rightProvince"
	import rightCounty from "./rightCounty"

	import {
		weatherOption
	} from '../../../utils/weatherOption.js'
	import {
		companyIndustry,
		mapIndustry,
		mapData,
		industry_data
	} from '@/api/supply/supply'
	export default {
		name: "index",
		components: {
			topHeader,
			topNumber,
			centerForm,
			leftLevel,
			leftLoops,
			china,
			// chinaGaode,
			rightProvince,
			rightCounty,
		},
		data() {
			return {
				numberConfig: {
					title: '企业数量',
					name: '企业数量',
					number: [100],
					content: '{nt}个',
					style: {
						fontSize: 20
					}
				},
				loading: true,
				dataTime: {
					dateDay: null,
					dateYear: null,
					dateWeek: null,
					weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
				},
				dataWeather: {
					city: '北京市',
					dayweather: '晴',
					nightweather: '多云',
					weathertemp: '晴', // 根据时间判断选白天还是晚上的天气
					nighttemp: '3℃',
					daytemp: '15℃',
					peraturetemp: '3℃~15℃', // 根据时间判断选白天还是晚上的温度
					icontemp: 'https://cdn.heweather.com/cond_icon/100.png',
				},
				areaData: {
					areaCode: "000000",
					areaLevel: "county",
					name: "中国",
					areaName: "中国",
				},
				industryList: [],
				industry_id: "",
				mapDataList: [],
				fzDataList: []
			};
		},
		computed: {
			chinaMapTitle() {
				let areaName = this.areaData.areaName;
				if (areaName === 'china') {
					areaName = '全国'
				}
				return areaName;
			}
		},
		created() {
			this.getDateTime();
			// this.cancelLoading();
			// this.requestGetWeather();
			this.getCompanyIndustry().then(res => {
				Promise.all([this.getChinaData(1), this.getFzData(1)]).then(res => {
					this.loading = false;
				})


			})
		},
		mounted() {},
		methods: {
			changeIndus(e) {
				console.log(e)
				this.industry_id = e
				Promise.all([this.getChinaData(), this.getFzData()]).then(res => {

				})
			},
			getFzData(type) {
				return new Promise((resolve, reject) => {
					industry_data({
							industry_id: this.industry_id
						}).then(res => {
							let data = res.data
							if (data != null) {
								// this.$set(this,"fzDataList",data)
								this.fzDataList = data

								resolve()
							} else {
								this.fzDataList = []

								resolve()
							}
						})
						.catch(err => {})
				})
			},
			getChinaData(type) {
				return new Promise((resolve, reject) => {
					mapData({
							industry_id: this.industry_id
						}).then(res => {
							let data = res.data
							if (data.length > 0) {
								function sortId(a, b) {
									return b.total - a.total;
								}
								data.sort(sortId);
								this.mapDataList = data
								if (type != 1) {
									this.$refs.mapChina.initEcharts();
								}
								resolve()
							} else {
								this.mapDataList = data
								if (type != 1) {
									this.$refs.mapChina.initEcharts();
								}
								resolve()
							}
						})
						.catch(err => {})
				})
			},
			getCompanyIndustry() {
				return new Promise((resolve, reject) => {
					mapIndustry().then(res => {
							let data = res.data
							if (data != null) {
								this.industryList = data.industry_lists
								this.numberConfig.number = [data.total]
								this.industry_id = this.industryList[0].class_id
								resolve()
							}
						})
						.catch(err => {})
				})

			},
			formatTime(time, fmt) {
				if (!time) return '';
				else {
					const date = new Date(time);
					const o = {
						'M+': date.getMonth() + 1,
						'd+': date.getDate(),
						'H+': date.getHours(),
						'm+': date.getMinutes(),
						's+': date.getSeconds(),
						'q+': Math.floor((date.getMonth() + 3) / 3),
						S: date.getMilliseconds(),
					};
					if (/(y+)/.test(fmt))
						fmt = fmt.replace(
							RegExp.$1,
							(date.getFullYear() + '').substr(4 - RegExp.$1.length)
						);
					for (const k in o) {
						if (new RegExp('(' + k + ')').test(fmt)) {
							fmt = fmt.replace(
								RegExp.$1,
								RegExp.$1.length === 1 ?
								o[k] :
								('00' + o[k]).substr(('' + o[k]).length)
							);
						}
					}
					return fmt;
				}
			},
			// 获取时间
			getDateTime() {
				setInterval(() => {
					this.dataTime.dateYear = this.formatTime(new Date(), 'yyyy-MM-dd');
					this.dataTime.dateWeek = this.dataTime.weekday[new Date().getDay()];
					this.dataTime.dateDay = this.formatTime(new Date(), 'HH: mm: ss');
				}, 1000)
			},
			// 获取天气
			requestGetWeather() {
				http({
					url: "https://api.asilu.com/weather_v2/",
					data: {}
				}).then(res => {
					console.log(res)
				})
				return
				$.get({
					//这里的url不能写到data对象的外面
					url: 'https://api.asilu.com/weather_v2/',
					dataType: 'jsonp', // 请求方式为jsonp
					success: (data) => {
						if (data.status === '1') {
							let currTime = new Date().getHours();
							let dailyForecast = data.forecasts[0].casts[0];
							let city = data.forecasts[0].city;
							let daytemp = dailyForecast.daytemp + '℃';
							let nighttemp = dailyForecast.nighttemp + '℃';
							let weathertemp = '';
							let peraturetemp = '';
							if ((currTime >= 6) && (currTime < 18)) {
								weathertemp = dailyForecast.dayweather;
								peraturetemp = dailyForecast.nighttemp + "℃~" + dailyForecast.daytemp + "℃";
							} else {
								weathertemp = dailyForecast.nightweather;
								peraturetemp = dailyForecast.daytemp + "℃~" + dailyForecast.nighttemp + "℃";
							}
							let icontemp = 'https://cdn.heweather.com/cond_icon/' + weatherOption.weatherCode[
								weathertemp] + '.png';
							this.dataWeather = {
								city,
								daytemp,
								nighttemp,
								weathertemp,
								peraturetemp,
								icontemp
							}
						}
					}
				})
			},

			cancelLoading() {
				setTimeout(() => {
					this.loading = false;
				}, 500);
			},
			mapClick(data) {
				this.areaData = data;
			},
			backClick(data) {
				this.areaData = data;
			},
		}
	};
</script>

<style lang="scss">
	body {
		line-height: 1.2em;
	}
	div{
		box-sizing: border-box;	}
	@import '@/assets/scss/index.scss';
</style>
<style lang="scss" scoped>
	#index {

		.dv-border-box-10,
		.dv-border-box-12,
		.dv-border-box-13,
		{
		height: inherit;
	}

	.dataweather {
		z-index: 999;
		position: absolute;
		top: .2rem;
		left: 1.1rem;
		font-size: .22rem;
		color: #fff;

		img {
			width: .3rem;
			height: .3rem;
			filter: brightness(3);
			vertical-align: bottom;
		}
	}

	.datatime {
		z-index: 999;
		position: absolute;
		top: .2rem;
		right: 1.1rem;
		font-size: .22rem;
		color: #fff;
	}

	.topNumber {
		height: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center
	}


	}
</style>