<template>
	<div class="container">
		<!-- 广告图 -->
		<div class="banner">
			<div class="main">
				<div class="breadcrumb-text">
					<h2>{{menuList[menuIndex].head_o}}</h2>
					<br v-if="!menuList[menuIndex].head_t">
					<br v-if="!menuList[menuIndex].head_t">
					<p>{{menuList[menuIndex].head_oyw}}</p>
					<h2 class="mt-10" v-if="menuList[menuIndex].head_t">{{menuList[menuIndex].head_t}}</h2>
					<p v-if="menuList[menuIndex].head_t">{{menuList[menuIndex].head_tyw}}</p>
				</div>
			</div>
		</div>
		<div class="scfl">
			<div class="main">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>{{menuList[menuIndex].title}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main_wrap">
			<div class="main">
				<el-aside width="160px">
					<el-menu :default-active="menuIndex" class="menu" @select="handleOpen">
						<el-menu-item :index="index+''" v-for="(item,index) in menuList" :key="index">
							<template slot="title">
								<span>{{item.title}}</span>
								<i class="el-icon-arrow-right" style="margin-left: 30px;"></i>
							</template>
						</el-menu-item>
						<!-- <el-menu-item index="ptjz" title>
							<template slot="title">
								<span>平台价值</span>
								<i class="el-icon-arrow-right" style="margin-left: 30px;"></i>
							</template>
						</el-menu-item>
						<el-menu-item index="ptjz" title>
							<template slot="title">
								<span>平台价值</span>
								<i class="el-icon-arrow-right" style="margin-left: 30px;"></i>
							</template>
						</el-menu-item> -->
					</el-menu>
				</el-aside>
				<el-main class="member">
					<transition name="slide">
						<div v-html="content"></div>
					</transition>
				</el-main>

			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		aboutpage
	} from '@/api/supply/supply';
	export default {
		name: "supplyAboute",
		components: {},
		data: () => {
			return {
				formData: {
					fromaddr_province_id: "",
					fromaddr_city_id: "",
					fromaddr_district_id: "",
					toaddr_province_id: "",
					toaddr_city_id: "",
					toaddr_district_id: "",
				},
				menuList: [{
						title: "关于我们",
						type: 'gywm',
						head_o: "诚信为本，客户至上",
						head_oyw: "Credit is the basic,client is the first,to make client more satisfied is our constant aim",
						name: "about"
					},
					{
						title: "平台价值",
						type: 'ptjz',
						head_o: "应急保障储备社会化数字协同平台之",
						head_oyw: "Socialized Digital Collaborative Platform for emergency security reserve",
						head_t: "平台价值",
						head_tyw: "Platform value",
						name: "platform_calue"
					},
					{
						title: "知识产权",
						type: 'zscq',
						head_o: "应急保障储备社会化数字协同平台之",
						head_oyw: "Socialized Digital Collaborative Platform for emergency security reserve",
						head_t: "知识产权",
						head_tyw: "Intellectual property",
						name: "property"
					},
					{
						title: "合作机构",
						type: 'hzjg',
						head_o: "诚信为本，客户至上",
						head_oyw: "Credit is the basic,client is the first,to make client more satisfied is our constant aim",
						name: "partners"
					},
					{
						title: "会员服务",
						type: 'hyfw',
						head_o: "应急保障储备社会化数字协同平台之",
						head_oyw: "Socialized Digital Collaborative Platform for emergency security reserve",
						head_t: "会员服务",
						head_tyw: "Membership services",
						name: "member_services"
					},
					{
						title: "政策法规",
						type: 'zcfg',
						head_o: "应急保障储备社会化数字协同平台之",
						head_oyw: "Socialized Digital Collaborative Platform for emergency security reserve",
						head_t: "政策法规",
						head_tyw: "National Policy, Local Regulations, Industry Standards",
						name: "policy"
					},
					{
						title: "隐私声明",
						type: 'yssm',
						head_o: "应急保障储备社会化数字协同平台之",
						head_oyw: "Socialized Digital Collaborative Platform for emergency security reserve",
						head_t: "隐私声明",
						head_tyw: "Privacy statement",
						name: "privacy"
					},
					{
						title: "服务条款",
						type: 'fwtk',
						head_o: "应急保障储备社会化数字协同平台之",
						head_oyw: "Socialized Digital Collaborative Platform for emergency security reserve",
						head_t: "服务条款",
						head_tyw: "Terms of service",
						name: "service"
					}
				],
				menuIndex: '0',
				loading: true,
				pageType: "",
				content:""
			};
		},
		created() {
			console.log(this.$route.query.type)
			this.pageType = this.$route.query.type
			this.menuList.forEach((item, index) => {
				if (this.pageType == item.type) {
					this.menuIndex = index + ""
				}
			})
			this.getOrderList()
		},
		methods: {
			handleOpen(key, keyPath) {
				this.menuIndex = key
				this.pageType = this.menuList[this.menuIndex].type
				console.log(key, this.pageType)
				this.getOrderList()
			},
			getOrderList() {
				aboutpage({
						name: this.menuList[this.menuIndex].name
					})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.content = res.data.content
						}
						this.loading = false;
					})
					.catch(res => {
						this.loading = false;
					});
			},
		}
	};
</script>
<style scoped>
	/deep/.el-menu-item.is-active {
		background-color: #77b122 !important;
		color: #fff !important;
	}

	/deep/.el-menu-item:hover {
		background-color: #77b122 !important;
		color: #fff !important;
	}

	/deep/.el-submenu__title:hover {
		background-color: #77b122 !important;
		color: #fff !important;
	}

	/deep/.el-breadcrumb__inner:hover {
		color: #77b122 !important;
	}
</style>
<style scoped lang="scss">
	.el-button--primary {
		background-color: #57c5a5;
		border-color: #57c5a5;
	}

	.container {
		.banner {
			background: url('../../../assets/images/supply/gywm.png') no-repeat;
			background-size: 100% 100%;
			position: relative;
			z-index: 1;
			width: 100%;
			height: 300px;
			margin-top: 80px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.breadcrumb-text {
					height: 300px;
					position: relative;
					z-index: 1;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h2 {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 36px;
					}

					p {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 18px;
					}
				}

			}

		}

		.scfl {
			padding-top: 30px;



			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;
				padding-bottom: 10px;
				border-bottom: 1px #c1c1c1 solid;
			}
		}

		.main_wrap {
			padding-bottom: 50px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 30px auto;

				display: flex;

				.menu {
					min-height: 730px;
				}
				
				.content {
					display: flex !important;
					margin: 20px 0;
				}
				
				.member {
					margin-left: 15px;
					flex: 1;
					width: auto;
					border-top: none;
				}
			}
		}
	}

	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
</style>