import Cookies from 'js-cookie'

const TokenKey = 'SSDrxEA%_tWW6ezd3'
const SiteKey = ''

export function getsupplyToken() {
    return Cookies.get(TokenKey)
}

export function setsupplyToken(token, expires = 0) {
	console.log(TokenKey,'tokenkey')
    if (expires) return Cookies.set(TokenKey, token, { expires })
    return Cookies.set(TokenKey, token)
}

export function removesupplyToken() {
    return Cookies.remove(TokenKey)
}

export function getSiteId() {
    return Cookies.get(SiteKey)
}

export function setSiteId(siteId) {
	return Cookies.set('SiteKey', siteId)
}

export function removeSiteId() {
    return Cookies.remove(SiteKey)
}