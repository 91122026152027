<template>
	<div>
		<div class="header-top">
			<div class="top-content">
				<div class="top-left">
					<router-link to="/" class="logo-wrap">
						<img src="@/assets/images/logo.jpg" />
					</router-link>
				</div>
				<div class="top-right">
					<router-link to="/">首页</router-link>
					<router-link to="/cms/notice">公众监督</router-link>
					<router-link to="/supplier/gys">合格供应商</router-link>
					<router-link to="/supplyView/aboutePage?type=gywm"
						:target="$route.path=='/supplyView/aboutePage'?'_blank':''">关于我们</router-link>
					<router-link v-if="!logined" to="/supplyLogin/login">登录</router-link>
					<div v-if="logined" class="member-info">
						<router-link
							to="/supplymember/index">{{ supplymember.nickname || supplymember.username }}</router-link>
						<span @click="logout">退出</span>
					</div>
					<router-link v-if="!logined" to="/supplyLogin/register">注册</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex"
	import {
		getToken
	} from "@/utils/auth"

	export default {
		props: {},
		data() {
			return {
				visible: false
			}
		},
		created() {
			this.$store.dispatch("site/qrCodes")
			this.$store.dispatch("supplymember/member_detail")
			this.$store.dispatch("site/defaultFiles")
			this.$store.dispatch("site/addons")
		},

		watch: {},
		methods: {

			logout() {
				this.$store.dispatch("supplymember/logout")
			}
		},
		components: {},
		computed: {
			...mapGetters(["wapQrcode", "supplymember", "addonIsExit", "city"]),
			qrcode: function() {
				return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
			},
			logined: function() {
				console.log(123)
				return this.supplymember !== undefined && this.supplymember !== "" && this.supplymember !== {}
			}
		}
	}
</script>

<style scoped lang="scss">
	.header-top {
		width: 100%;
		height: 80px;
		font-size: 12px;
		background-color: #fff;



		.el-dropdown {
			font-size: $ns-font-size-sm;
		}

		.top-content {
			// width: $width;
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 100%;
			width: 80%;
			max-width: 1140px;
			margin: 0 auto;

			.top-left {
				// height: 100%;
				line-height: 31px;

				.logo-wrap {
					// width: 240px;
					height: 60px;
					line-height: 60px;
					display: block;

					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
			}

			.top-right {
				font-size: 15px;
				color: #666;
				font-weight: 600;
				display: flex;

				a {
					float: left;
					line-height: 31px;
					padding: 0 10px;
					color: #808080;
					font-size: 16px;

					&:hover {
						color: #0065DD;
						font-size: 16px;
						font-weight: bold;
					}
				}

				div {
					float: left;
					height: 100%;
					margin-left: 10px;
					cursor: pointer;
					line-height: 31px;
					padding: 0 5px;

					&.member-info {
						margin-left: 0;

						span:first-child {
							margin-right: 10px;
						}
					}

					span:hover {
						color: $base-color;
					}

					&.el-dropdown:hover {
						background-color: #fff;
					}
				}
			}
		}
	}

	.mobile-qrcode {
		padding: 10px 0;
	}

	.router-link-active {
		// color: $base-color;
	}
</style>