<template>
	<div class="container">
		<!-- 广告图 -->
		<div class="company_top" v-loading="loading" v-if="companyData">
			<div class="main">
				<div class="top_wrap">
					<div class="image_logo col-lg-1 col-2">
						<img :src="$img(companyData.logo)" alt="">
					</div>
					<div class="ent_label col-lg-7 col-10">
						<h5 style="font-size: 18px;">
							<span>{{companyData.name}}</span>
						</h5>
						<div style="margin-top: 12px;font-size: 13px">
							<span class="ent_label_item" v-for="(item,index) in companyData.label"
								:key="index">{{(index>0?"|":'')+item}}</span>
						</div>
					</div>
					<div class="ent_search col-lg-4 col-12">
						<el-form :inline="true" :model="formInline" class="demo-form-inline">
							<el-form-item>
								<el-input v-model="formInline.goodsname" placeholder="商品名称"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="onSubmit">查询</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>

		<div class="banner">
			<div class="main">
				<div class="tabs_wrap">
					<div class="tabs_left">
						<div class="tabs_item" :class="{active:tabsIndex==0}" @click="tabsIndex=0">
							企业首页
						</div>
						<div class="tabs_item" :class="{active:tabsIndex==1}" @click="tabsIndex=1">
							企业介绍
						</div>
						<div class="tabs_item" :class="{active:tabsIndex==2||tabsIndex==5}" @click="tabsIndex=2">
							商品列表
						</div>
						<div class="tabs_item" :class="{active:tabsIndex==3}" @click="tabsIndex=3">
							联系方式
						</div>
						<div class="tabs_item" :class="{active:tabsIndex==4}" @click="tabsIndex=4">
							企业留言板
						</div>
					</div>
					<div class="tabs_right" @click="$router.push('/supplyLogin/register')">
						成为合格供应商
					</div>
				</div>

			</div>
		</div>
		<div class="company_top content_wrap">
			<div class="main" v-if="companyData">
				<div class="tab_content" v-if="tabsIndex==0">
					<div class="index_wrap">
						<div class="index_top">
							<div class="video-player-wrap show">
								<video-player v-if="companyData.video" ref="videoPlayer" :playsinline="true"
									:options="playerOptions" @play="onPlayerPlay($event)" @pause="onPlayerPause($event)"
									@ended="onPlayerEnded($event)" @waiting="onPlayerWaiting($event)"
									@playing="onPlayerPlaying($event)" @loadeddata="onPlayerLoadeddata($event)"
									@timeupdate="onPlayerTimeupdate($event)" @canplay="onPlayerCanplay($event)"
									@canplaythrough="onPlayerCanplaythrough($event)"
									@statechanged="playerStateChanged($event)" @ready="playerReadied"></video-player>
							</div>
							<div class="col-lg-6 col-12 overflow-auto ent_describe_text">
								<div class="ent_title"
									style="height: 30px;line-height: 30px;margin-top:10px;margin-bottom: 10px;font-size: 18px;">
									企业简介</div>
								<div class="overflow-auto ent_jieshao">
									{{companyData.content}}
								</div>
							</div>
						</div>
						<div style="padding: 30px 40px;">
							<div class="cell">
								<div class="row">
									<div class="cell-item">
										<div for="" class="cell-label">企业名称</div>
										<div class="cell-value">{{companyData.name}}</div>
									</div>
									<div class="cell-item">
										<div for="" class="cell-label">企业法人</div>
										<div class="cell-value">{{companyData.legal_person}}</div>
									</div>
									<div class="cell-item">
										<div for="" class="cell-label">成立日期</div>
										<div class="cell-value">{{companyData.cl_time}}</div>
									</div>
									<div class="cell-item">
										<div for="" class="cell-label">注册资本</div>
										<div class="cell-value">{{companyData.zczb}}</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					<div class="qyhj">
						<div class="ent_title">企业环境</div>
						<el-carousel :interval="4000" type="card" height="300px">
							<el-carousel-item v-for="(item,index) in companyData.hj_image" :key="index">
								<div class="image_wrap" v-if="item">
									<img :src="$img(item)" alt="">
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="qyhj">
						<div class="ent_title">企业荣誉</div>
						<el-carousel :interval="4000" type="card" height="300px">
							<el-carousel-item v-for="(item,index) in companyData.ry_image" :key="index">
								<div class="image_wrap" v-if="item">
									<img :src="$img(item)" alt="">
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
				<div class="tab_content qyjs " v-if="tabsIndex==1">
					<div class="index_wrap ">
						<div class="index_top">

							<div class="col-lg-6 col-12 overflow-auto ent_describe_text" style="padding: 15px 30px;">
								<div class="ent_title"
									style="height: 30px;line-height: 30px;margin-top:10px;margin-bottom: 10px;font-size: 18px;">
									企业简介</div>
								<div class="overflow-auto ent_jieshao" style="height: auto;">
									{{companyData.content}}
								</div>
							</div>
						</div>


					</div>
					<div style="padding: 30px 20px 50px;">
						<div class="cell">
							<div class="row">
								<div class="cell-item">
									<div for="" class="cell-label">企业名称</div>
									<div class="cell-value">{{companyData.name}}</div>
								</div>
								<div class="cell-item">
									<div for="" class="cell-label">企业法人</div>
									<div class="cell-value">{{companyData.legal_person}}</div>
								</div>
							</div>
						</div>
						<div class="cell">
							<div class="row">
								<div class="cell-item">
									<div for="" class="cell-label">统一社会信用代码</div>
									<div class="cell-value">{{companyData.unicode}}</div>
								</div>
								<div class="cell-item">
									<div for="" class="cell-label">企业类型</div>
									<div class="cell-value">{{companyData.category_name}}</div>
								</div>
							</div>
						</div>
						<div class="cell">
							<div class="row">
								<div class="cell-item">
									<div for="" class="cell-label">成立日期</div>
									<div class="cell-value">{{companyData.cl_time}}</div>
								</div>
								<div class="cell-item">
									<div for="" class="cell-label">注册资本</div>
									<div class="cell-value">{{companyData.zczb}}</div>
								</div>
							</div>
						</div>
						<div class="cell">
							<div class="row">
								<div class="cell-item">
									<div for="" class="cell-label">地址</div>
									<div class="cell-value">
										{{companyData.province_name}}{{companyData.city_name}}{{companyData.district_name}}{{companyData.address}}
									</div>
								</div>
							</div>
						</div>
						<div class="cell">
							<div class="row">
								<div class="cell-item">
									<div for="" class="cell-label">经营范围</div>
									<div class="cell-value">{{companyData.jyfw}}</div>
								</div>
							</div>
						</div>
						<div class="cell">
							<div class="row">
								<div class="cell-item">
									<div for="" class="cell-label">企业网站</div>
									<div class="cell-value">{{companyData.website}}</div>
								</div>
								<div class="cell-item">
									<div for="" class="cell-label">企业微博</div>
									<div class="cell-value">{{companyData.weibo}}</div>
								</div>
								<div class="cell-item">
									<div for="" class="cell-label">企业公众号</div>
									<div class="cell-value">{{companyData.account}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<el-card class="goods_list" v-loading="goodsloading" v-if="tabsIndex==2">
					<div class="cargo-list" v-if="goodsList.length">
						<div class="goods-info">
							<div class="item" v-for="(item, index) in goodsList" :key="item.goods_id"
								@click="openDetail(item)">
								<img class="img-wrap" :src="$img(item.goods_image.split(',')[0])"
									@error="imageError(index)" />
								<div class="goods-name">{{ item.goods_name }}</div>

							</div>
						</div>

						<div class="pager">
							<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
								:current-page.sync="currentPage" :page-size.sync="pageSize"
								@size-change="handlePageSizeChange"
								@current-change="handleCurrentPageChange"></el-pagination>
						</div>
					</div>
					<div class="empty" v-else>
						<div class="ns-text-align">没有查询的相关的产品</div>
					</div>
				</el-card>
				<div class="tab_content qyjs lxfs" v-if="tabsIndex==3">
					<div class="ent_contact">
						<div class="ent_title">联系方式</div>
						<div>
							<div class="cell">
								<div class="row">
									<div class="cell-item">
										<div for="" class="cell-label">企业名称</div>
										<div class="cell-value">{{companyData.name}}</div>
									</div>
									<div class="cell-item">
										<div for="" class="cell-label">联系人</div>
										<div class="cell-value">{{companyData.lx_name}}</div>
									</div>
								</div>
							</div>
							<div class="cell">
								<div class="row">
									<div class="cell-item">
										<div for="" class="cell-label">联系电话</div>
										<div class="cell-value">{{companyData.lx_mobile}}</div>
									</div>
									<div class="cell-item">
										<div for="" class="cell-label">联系邮箱</div>
										<div class="cell-value">{{companyData.lx_email}}</div>
									</div>
								</div>
							</div>
							<div class="cell">
								<div class="row">
									<div class="cell-item">
										<div for="" class="cell-label">注册地址</div>
										<div class="cell-value">
											{{companyData.province_name}}{{companyData.city_name}}{{companyData.district_name}}{{companyData.address}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
				<div class="tab_content qyjs lxfs" v-if="tabsIndex==4">
					<div class="ent_contact">
						<div class="ent_title">留言信息</div>
						<div class="empty" style="margin-top: 50px;">
							<div class="ns-text-align" style="font-size: 16px;">没有查询到相关的信息</div>
						</div>
						<div class="message_public">
							<div class="ent_right-title">留言：</div>
							<el-form :model="formMessage" :rules="rules" class="demo-form-inline">
								<el-form-item prop="content">
									<el-input v-model.trim="formMessage.content" type="textarea" autocomplete="off"
										placeholder="请输入留言" class="ns-len-input"></el-input>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="onSubmitMessage">提交</el-button>
								</el-form-item>
							</el-form>
							<!-- <form role="form" class="ly" action="" onsubmit="return messagesave();">
								<textarea id="message" name="" rows="2" class="form-control" required="required"
									maxlength="50"></textarea>
								<button type="submit" class="btn btn-primary">提交</button>
							</form> -->
						</div>
					</div>

				</div>

				<template v-if="tabsIndex==5&&goodsDetail">
					<el-card class="goods_list">
						<div class="goods-detail">
							<div class="preview-wrap">
								<div class="magnifier-wrap">
									<pic-zoom ref="PicZoom" :url="$img(picZoomUrl)" :scale="2"></pic-zoom>
								</div>

								<div class="spec-items">
									<span class="left-btn iconfont iconarrow-left-copy" :class="{ move: moveThumbLeft }"
										@click="changeThumbImg('prev')"></span>
									<span class="right-btn iconfont iconarrow-right" :class="{ move: moveThumbRight }"
										@click="changeThumbImg('next')"></span>
									<ul :style="{ left: 30 + thumbPosition + 'px' }">
										<!-- 商品缩率图 -->
										<li v-for="(item, index) in goodsDetail.goods_image.split(',')" :key="index"
											@mousemove="picZoomUrl = item" :class="{ selected: picZoomUrl == item }">
											<img :src="$img(item)" @error="imageErrorSpec(index)" />
										</li>
									</ul>
								</div>
							</div>

							<!-- 商品信息 -->
							<div class="basic-info-wrap">
								<h1>{{ goodsDetail.goods_name }}</h1>
								<div class="goods_item">
									<span class="goods_item_label">产品类别：</span>
									<span
										class="goods_item_value">{{goodsDetail.one_category_name}}{{'/'+goodsDetail.two_category_name}}</span>
								</div>
								<div class="goods_item">
									<span class="goods_item_label">生产许可证号：</span>
									<span class="goods_item_value">{{goodsDetail.number}}</span>
								</div>
								<div class="goods_item">
									<span class="goods_item_label">产品规格：</span>
									<span class="goods_item_value">{{goodsDetail.product_spec}}</span>
								</div>
								<div class="goods_item">
									<span class="goods_item_label">包装规格：</span>
									<span class="goods_item_value">{{goodsDetail.package_spec}}</span>
								</div>
								<div class="goods_item">
									<span class="goods_item_label">有效期：</span>
									<span class="goods_item_value">{{goodsDetail.expire_data}}</span>
								</div>
								<div class="goods_item">
									<span class="goods_item_label">生产地：</span>
									<span class="goods_item_value">{{goodsDetail.address}}</span>
								</div>
							</div>

						</div>

					</el-card>
					<el-card class="tab_content qyjs lxfs">
						<div class="ent_contact">
							<div class="ent_title">产品参数</div>
							<div>
								<div class="cell">
									<div class="row">
										<div class="cell-item">
											<div for="" class="cell-label">即时供给能力（吨、件/周）</div>
											<div class="cell-value">{{goodsDetail.js_supply}}</div>
										</div>
										<div class="cell-item">
											<div for="" class="cell-label">年供给量（吨、件）</div>
											<div class="cell-value">{{goodsDetail.year_supply}}</div>
										</div>
										<div class="cell-item">
											<div for="" class="cell-label">执行标准</div>
											<div class="cell-value">{{goodsDetail.zxbz}}</div>
										</div>
									</div>
								</div>
								<div class="cell">
									<div class="row">
										<div class="cell-item">
											<div for="" class="cell-label">品牌</div>
											<div class="cell-value">{{goodsDetail.brand}}</div>
										</div>
										<div class="cell-item">
											<div for="" class="cell-label">药监局备案证号</div>
											<div class="cell-value">{{goodsDetail.yj_number}}</div>
										</div>
										<div class="cell-item">
											<div for="" class="cell-label">备案人组织机构代码</div>
											<div class="cell-value">{{goodsDetail.filings_code}}</div>
										</div>
									</div>
								</div>

							</div>
						</div>

					</el-card>
					<el-card class="tab_content qyjs lxfs" style="margin: 30px 0;">
						<div class="ent_contact">
							<div class="ent_title">产品详情</div>
							<div style="padding: 20px;">
								<div v-html="goodsDetail.content"></div>
							</div>
						</div>

					</el-card>
				</template>
			</div>

		</div>

	</div>
</template>

<script>
	import PicZoom from 'vue-piczoom';
	import {
		mapGetters
	} from "vuex";
	import {
		companyInfo,
		my_goods
	} from '@/api/supply/supply';
	export default {
		name: "companyDetail",
		components: {
			PicZoom
		},
		data: () => {
			return {
				formMessage: {
					content: ""
				},
				rules: {
					content: [{
						required: true,
						message: "请输入留言",
						trigger: "blur"
					}]
				},
				currentPage: 1,
				pageSize: 10,
				total: 0,
				loading: true,
				companyData: null,
				formInline: {
					goodsname: ""
				},
				tabsIndex: 0,
				switchMedia: "video",
				playerOptions: {
					playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
					autoplay: false, // 如果为true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 是否视频一结束就重新开始。
					preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: "zh-CN",
					aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "video/mp4", // 类型
						src: "" // url地址
					}],
					poster: "", // 封面地址
					notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true, // 当前时间和持续时间的分隔符
						durationDisplay: true, // 显示持续时间
						remainingTimeDisplay: true, // 是否显示剩余时间功能
						fullscreenToggle: true // 是否显示全屏按钮
					}
				},
				goodsList: [],
				companyId: "",
				goodsloading: true,
				goodsDetail: null,
				picZoomUrl: "",
				thumbPosition: 0,
				// 是否可以移动
				moveThumbLeft: false,
				moveThumbRight: false,
			};
		},
		computed: {

		},
		created() {
			this.companyId = this.$route.query.id
			this.getcompanyInfo(this.companyId);

		},
		methods: {
			changeThumbImg(tag) {
				if (this.goodsDetail.goods_image.split(',').length < 4) return
				let page = this.goodsDetail.goods_image.split(',').length % 4 // 可见数量4个
				let position = 88
				if (page == 0) page = this.goodsDetail.goods_image.split(',').length - 4 // 可见数量4个
				else if (page != 0 && page != 1 && page < 2) return

				if (tag == "prev") {
					if (this.thumbPosition != 0 && Math.round(this.thumbPosition, 2) != position) {
						this.thumbPosition += position
						// this.moveThumbLeft = true;
					} else {
						// this.moveThumbLeft = false;
					}
				} else if (tag == "next") {
					if (Math.round(this.thumbPosition, 2) != -Math.round(position * page, 2)) {
						this.thumbPosition -= position
						// this.moveThumbRight = true;
					} else {
						// this.moveThumbRight = false;
					}
				}
			},
			openDetail(data) {
				this.goodsDetail = data
				this.tabsIndex = 5
				this.picZoomUrl = data.goods_image.split(',')[0]
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			refresh() {
				this.goodsloading = true;
				this.getGoodsList();
			},
			getGoodsList() {
				my_goods({
						page: this.currentPage,
						page_size: this.pageSize,
						goods_name: this.formInline.goodsname,
						uid: this.companyData.uid
					})
					.then(res => {
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						this.goodsList = list;
						this.goodsloading = false;
					})
					.catch(res => {
						this.goodsloading = false;
					});
			},
			//搜索商品
			onSubmit() {
				this.currentPage = 1;
				this.tabsIndex = 2
				this.refresh()
			},
			getcompanyInfo(id) {
				companyInfo({
					id
				}).then(res => {
					if (res.code == 0 && res.data) {
						this.companyData = res.data
						this.playerOptions.sources[0].src = this.$img(this.companyData.video)
						this.loading = false
						this.getGoodsList()
					}
				})
			},
			// 播放回调
			onPlayerPlay(player) {},
			// 暂停回调
			onPlayerPause(player) {},
			// 视频播完回调
			onPlayerEnded(player) {},
			// DOM元素上的readyState更改导致播放停止
			onPlayerWaiting(player) {},
			// 已开始播放回调
			onPlayerPlaying(player) {},
			// 当播放器在当前播放位置下载数据时触发
			onPlayerLoadeddata(player) {},
			// 当前播放位置发生变化时触发。
			onPlayerTimeupdate(player) {},
			//媒体的readyState为HAVE_FUTURE_DATA或更高
			onPlayerCanplay(player) {},
			//媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
			onPlayerCanplaythrough(player) {},
			//播放状态改变回调
			playerStateChanged(playerCurrentState) {},
			//将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
			playerReadied(player) {},
		}
	};
</script>
<style scoped>
	/deep/.el-tabs__item.is-active {
		color: #0b72e7;

	}

	/deep/.el-tabs__active-bar {
		background: #0b72e7;


	}

	/deep/.el-textarea__inner:focus {
		border-color: #0b72e7;
	}

	/deep/.el-tabs__item:hover {
		color: #0b72e7;
	}


	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #0b72e7;
	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #0b72e7;
	}

	/deep/.video-js.vjs-fluid {
		width: 570px !important;
		height: 350px !important;
	}

	/deep/.vjs-control-bar {
		visibility: hidden;
		transition: none;
	}

	/deep/.vjs-big-play-button {
		visibility: hidden;
		transition: none;
	}

	/deep/.vjs-big-play-button {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	/deep/.show .video-player>div {
		display: block;
	}

	/deep/.show .video-player {
		visibility: visible;
	}

	/deep/.show .vjs-control-bar {
		visibility: visible;
	}

	/deep/.show .vjs-big-play-button {
		visibility: visible;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #d71e26;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #fff !important;
	}

	/deep/.magnifier-wrap {
		width: 356px;
		height: 356px;
		border: 1px solid #e9e9e9;
		box-sizing: border-box;


	}

	/deep/.magnifier-box img {
		width: 100%;
	}

	/deep/.magnifier-wrap .magnifier-box .mouse-cover {
		background-color: #fede4f;
		opacity: 0.4;
	}
</style>
<style scoped lang="scss">
	.container {
		.banner {
			width: 100%;
			background-color: #d71e26;

			.main {
				width: 90%;
				max-width: 1140px;
				margin: 0 auto;

				.tabs_wrap {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.tabs_left {
						flex: 1;
						display: flex;
						align-items: center;

						.tabs_item {
							height: 48px;
							width: 10%;
							line-height: 48px;
							color: #fff !important;
							text-align: center;
							cursor: pointer;

							&.active {
								background-color: #b81a20;
							}

							&:hover {
								background-color: #b81a20;
							}
						}
					}

					.tabs_right {
						height: 48px;
						width: 200px;
						line-height: 48px;
						color: #fff !important;
						text-align: center;
						cursor: pointer;

						&:hover {
							background-color: #b81a20;
						}
					}
				}

			}

		}

		.company_top {
			background: #f5f6f7;
			border-top: 1px solid #ddd;
			padding: 20px 0;


			&.content_wrap {
				background: #fff;
				border-top: none;
				padding: 0;
			}

			.main {
				width: 90%;
				max-width: 1140px;
				margin: 0 auto;

				.top_wrap {

					display: flex;
					align-items: center;

					.image_logo {
						width: 70px;
						height: 70px;
						line-height: 70px;
						display: inline-block;
						margin-right: 40px;
						flex-shrink: 0;

						img {
							width: 100%;
							height: 100%;
						}
					}

					.ent_label {
						flex: 1;

						h5 {
							font-weight: 500;
							line-height: 1.2;
							// background: -webkit-linear-gradient(left,
							// 		#ffffff,
							// 		#ff0000 6.25%,
							// 		#ff7d00 12.5%,
							// 		#ffff00 18.75%,
							// 		#00ff00 25%,
							// 		#00ffff 31.25%,
							// 		#0000ff 37.5%,
							// 		#ff00ff 43.75%,
							// 		#ffff00 50%,
							// 		#ff0000 56.25%,
							// 		#ff7d00 62.5%,
							// 		#ffff00 68.75%,
							// 		#00ff00 75%,
							// 		#00ffff 81.25%,
							// 		#0000ff 87.5%,
							// 		#ff00ff 93.75%,
							// 		#ffff00 100%);
							// -webkit-background-clip: text;
							// -webkit-text-fill-color: transparent;
							// background-size: 200% 100%;
							// animation: masked-animation 2s infinite linear;
						}

						// @keyframes masked-animation {
						// 	0% {
						// 		background-position: 0 0;
						// 	}

						// 	100% {
						// 		background-position: -100%, 0;
						// 	}
						// }
					}

					.ent_search {
						.demo-form-inline {
							.el-form-item {
								margin: 0;

								.el-button--primary {
									background-color: #d71e26;
									border-color: #d71e26;
								}
							}

						}
					}
				}

				.tab_content {
					.index_wrap {
						background-color: #f6f6f6;
						margin-top: 50px;
						margin-bottom: 30px;

						.index_top {
							display: flex;

							.video-player-wrap {
								width: 570px !important;
								height: 350px !important;
								background: #000;
								position: relative;
								// overflow: hidden;
								z-index: 10;
							}

							.ent_describe_text {
								flex: 1;
								padding: 15px 30px 0px 20px;

								.ent_title {
									font-weight: 600;
									color: #323232;
								}

								.ent_jieshao {
									text-indent: 2em;
									padding: 5px;
									line-height: 25px;
									height: 278px;
									overflow: auto !important;
								}
							}
						}

						.cell {
							border-bottom: 1px solid #e6e6e6;
							min-height: 34px;
							line-height: 34px;
							padding: 10px 0;
							font-size: 14px;
							color: #343434;

							.row {
								display: -ms-flexbox;
								display: flex;
								-ms-flex-wrap: wrap;
								flex-wrap: wrap;
								margin-right: -15px;
								margin-left: -15px;

								.cell-item {
									flex: 1;
									padding: 0 15px;

									.cell-label {
										color: #666;
									}

									.cell-value {
										flex: 1;
										font-size: 15px;
										font-weight: 600;
										padding-right: 18px;
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
										-webkit-box-orient: vertical;
										-webkit-line-clamp: 2;
									}
								}
							}
						}


					}

					.qyhj {
						.ent_title {
							height: 42px;
							line-height: 42px;
							align-items: center;
							font-size: 20px;
							font-weight: 600;
							color: #323232;
							margin-top: 20px;
							margin-bottom: 15px;
						}

						.image_wrap {
							width: 100%;
							height: 100%;

							img {
								width: 100%;
								height: 100%;
							}
						}
					}

					&.qyjs {
						.index_wrap {
							margin-bottom: 0;
						}

						.ent_contact {
							background-color: #ffffff;
							padding: 30px !important;

							.ent_title {
								font-size: 28px;
							}
						}

						.message_public {
							margin: 50px 0px;
							padding: 30px;
							background-color: #F4F4F4;

							.el-button--primary {
								color: #fff;
								background-color: #0b72e7fd;
								border-color: #0b72e7fd;
							}
						}

						.cell {
							border-bottom: 1px solid #e6e6e6;
							min-height: 34px;
							line-height: 34px;
							padding: 10px 0;
							font-size: 14px;
							color: #343434;

							.row {
								display: -ms-flexbox;
								display: flex;
								-ms-flex-wrap: wrap;
								flex-wrap: wrap;
								margin-right: -15px;
								margin-left: -15px;

								.cell-item {
									flex: 1;
									padding: 0 15px;

									.cell-label {
										color: #666;
									}

									.cell-value {
										flex: 1;
										font-size: 15px;
										font-weight: 600;
										padding-right: 18px;
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
										-webkit-box-orient: vertical;
										-webkit-line-clamp: 2;
									}
								}
							}
						}
					}

				}

				.goods_list {
					margin: 30px 0;

					.goods-info {
						margin-top: 5px;
						display: flex;
						flex-wrap: wrap;

						.item {
							width: 220px;
							margin: 10px 20px 0 0;
							border: 1px solid #eeeeee;
							padding-top: 10px;
							position: relative;
							padding-bottom: 5px;

							&:nth-child(4n) {
								margin-right: initial !important;
							}

							&:hover {
								border: 1px solid #d71e26;
							}

							.img-wrap {
								width: 198px;
								height: 198px;
								cursor: pointer;
								padding: 10px;
							}

							.goods-name {
								padding: 0 10px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								word-break: break-all;
								height: 50px;
								cursor: pointer;

								&:hover {
									color: #d71e26;
								}
							}

							.price-wrap {
								padding: 0 10px;
								display: flex;
								align-items: center;

								.price {
									display: flex;
									color: #d71e26;
									font-size: $ns-font-size-lg;

									p {
										font-size: $ns-font-size-base;
										display: flex;
										align-items: flex-end;
									}
								}

								.market-price {
									color: #838383;
									text-decoration: line-through;
									margin-left: 10px;
								}
							}

							.sale-num {
								padding: 0 10px;
								display: flex;
								color: #838383;
								font-size: $ns-font-size-sm;

								p {
									color: #4759a8;
								}
							}

							.shop_name {
								padding: 0 10px;
								display: flex;
								color: #838383;
							}

							.saling {
								padding: 0 10px;
								display: flex;
								font-size: $ns-font-size-sm;
								line-height: 1;

								.free-shipping {
									background: #d71e26;
									color: #ffffff;
									padding: 3px 4px;
									border-radius: 2px;
									margin-right: 5px;
								}

								.is_own {
									color: #ffffff;
									background: #ff850f;
									border: 1px solid;
									margin-right: 5px;
									display: flex;
									align-items: center;
									padding: 3px 4px;
									border-radius: 2px;
								}

								.promotion-type {
									color: #d71e26;
									border: 1px solid #d71e26;
									display: flex;
									align-items: center;
									padding: 1px 3px;
								}
							}

							.item-bottom {
								display: flex;
								margin-top: 5px;

								.collection {
									flex: 1;
									border: 1px solid #e9e9e9;
									border-right: none;
									border-left: none;
									height: 40px;
									display: flex;
									align-items: center;
									justify-content: center;
									cursor: pointer;
								}

								.cart {
									flex: 2;
									border: 1px solid #e9e9e9;
									border-right: none;
									height: 40px;
									display: flex;
									align-items: center;
									justify-content: center;
									cursor: pointer;
								}
							}
						}
					}

					.pager {
						text-align: center;
						margin-top: 30px;
					}

					.empty {
						padding: 50px 0;
					}

					.goods-detail {
						margin-top: 20px;
						overflow: hidden;
						display: flex;

						.preview-wrap {



							.spec-items {
								position: relative;
								width: 356px;
								height: 56px;
								margin-top: 25px;
								margin-bottom: 13px;
								overflow: hidden;

								>span {
									cursor: pointer;
									// cursor: not-allowed;
									bottom: 0;
									line-height: 56px;
									position: absolute;
									// background: #e5e5e5;
									background: #fff;
									color: #838383;
									z-index: 1;
									// &.move {
									// 	cursor: pointer;
									// 	background: #fff;
									// }
								}

								.left-btn {
									left: 0;
								}

								.right-btn {
									right: 0;
								}

								ul {
									width: 500%;
									position: absolute;
									top: 0;
									left: 30px;

									li {
										width: 56px;
										height: 56px;
										float: left;
										overflow: hidden;
										margin-right: 5px;
										border: 1px solid #e9e9e9;
										cursor: pointer;
										opacity: 0.5;
										box-sizing: border-box;

										&:hover,
										&.selected {
											opacity: 1;
											border-color: $base-color;
										}
									}
								}
							}

							.share-collect {
								padding: 10px 0 20px;
								cursor: pointer;
								text-align: right;
								display: flex;
								align-items: center;
								justify-content: space-between;
								color: #838383;

								i,
								span {
									vertical-align: middle;
								}

								i {
									margin-right: 5px;
								}

								.focus-text {
									margin-right: 20px;
								}
							}
						}

						.basic-info-wrap {
							flex: 1;

							margin-left: 20px;
							padding: 15px;

							h1 {
								font-size: 18px;
								font-weight: 600;
								color: #000;
								line-height: 30px;
								margin-bottom: 16px;
							}

							.goods_item {
								color: #333;
								font-size: 14px;
								line-height: 22px;
								padding: 11px 0;

							}
						}

					}
				}
			}

		}
	}
</style>