<template>
	<div class="container">
		<!-- 广告图 -->
		<div class="banner">
			<div class="main">
				<div class="breadcrumb-text">
					<h2>公共信用记录</h2>
					<p>Public credit record</p>
					<!-- 	<h2 class="mt-10">应急物流</h2>
					<p>Emergency logistics</p> -->
				</div>
			</div>
		</div>
		<div class="scfl">
			<div class="main">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>{{source==0?'失信主体':source==1?'抽检不合格':'食品安全抽检不合格信息'}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main_wrap">
			<div class="main">
				<div v-loading="loading" style="margin-top: 30px;">
					<el-table :data="orderList" style="width: 100%" v-if="source==0">

						<el-table-column prop="company_name" label="企业名称" width="200">
						</el-table-column>
						<el-table-column prop="unicode" label="统一社会信用代码（或组织机构代码）" width="200">
						</el-table-column>
						<el-table-column prop="content" label="具体情形">
						</el-table-column>
						<el-table-column prop="create_time" label="处罚日期" width="150">
						</el-table-column>
					</el-table>
					<el-table :data="orderList" style="width: 100%" v-if="source==1" @cell-click="goclick">

						<el-table-column prop="goods_name" label="样品名称" width="200">
						</el-table-column>
						<el-table-column prop="company_name" label="被抽检单位名称" width="300">
						</el-table-column>
						<el-table-column prop="bc_company_name" label="标称生产企业名称">
						</el-table-column>
					</el-table>
					<el-table :data="orderList" style="width: 100%" v-if="source==2" @cell-click="goclick">

						<el-table-column prop="goods_name" label="食品名称" width="200">
						</el-table-column>
						<el-table-column prop="company_name" label="被抽检单位名称">
						</el-table-column>
						<el-table-column prop="province_name" label="被抽检单位所在省份" width="200">
						</el-table-column>
						<el-table-column prop="sc_time" label="生产日期/批号" width="150">
						</el-table-column>
					</el-table>
				</div>

				<div class="pager" style="margin-top: 20px;">
					<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
						:current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange"
						@current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
				</div>
			</div>
		</div>
		<el-dialog title="详情" :visible.sync="dialogTableVisible">
			<table class="table" v-if="source==1&&goodsDetail">
				<tbody class="info-detail_body">
					<tr>
						<td>抽样编号：</td>
						<td>{{goodsDetail.cy_number}}</td>
					</tr>
					<tr>
						<td>产品种类：</td>
						<td>{{goodsDetail.one_category_name}}/{{goodsDetail.two_category_name}}</td>
					</tr>
					<tr>
						<td>样品名称：</td>
						<td>{{goodsDetail.goods_name}}</td>
					</tr>
					<tr>
						<td>标称生产企业名称：</td>
						<td>{{goodsDetail.bc_company_name}}</td>
					</tr>
					<tr>
						<td>标称生产企业地址：</td>
						<td>{{goodsDetail.bc_company_address}}</td>
					</tr>
					<tr>
						<td>被抽样单位名称：</td>
						<td>{{goodsDetail.company_name}}</td>
					</tr>
					<tr>
						<td>被抽样单位地址：</td>
						<td>{{goodsDetail.province_name}}{{goodsDetail.city_name}}{{goodsDetail.district_name}}{{goodsDetail.address}}
						</td>
					</tr>
					<!-- <tr>
						<td>功能类别：</td>
						<td>抗疲劳、免疫调节</td>
					</tr> -->
					<tr>
						<td>标称批准文号：</td>
						<td>{{goodsDetail.bc_number}}</td>
					</tr>
					<!-- <tr>
						<td>批号：</td>
						<td>/</td>
					</tr> -->
					<tr>
						<td>规格型号：</td>
						<td>{{goodsDetail.product_spec}}</td>
					</tr>
					<!-- <tr>
						<td>商标：</td>
						<td>椰岛</td>
					</tr> -->
					<tr>
						<td>生产日期/批号：</td>
						<td>{{goodsDetail.sc_time}}</td>
					</tr>
					<tr>
						<td>保质期：</td>
						<td>{{goodsDetail.expire_data}}</td>
					</tr>
					<tr>
						<td>不合格项目：</td>
						<td>{{goodsDetail.check_project}}</td>
					</tr>
					<tr>
						<td>检测值：</td>
						<td>{{goodsDetail.check_value}}</td>
					</tr>
					<tr>
						<td>标准值：</td>
						<td>{{goodsDetail.standard_value}}</td>
					</tr>
					<tr>
						<td>产品包装标签明示值：</td>
						<td>{{goodsDetail.express_value}}</td>
					</tr>
					<tr>
						<td>检验机构：</td>
						<td>{{goodsDetail.check_agency}}</td>
					</tr>
					<tr>
						<td>备注：</td>
						<td>{{goodsDetail.remarks}}</td>
					</tr>
				</tbody>
			</table>
			<table class="table" v-if="source==2&&goodsDetail">
				<tbody class="info-detail_body">
					<tr>
						<td>食品名称：</td>
						<td>{{goodsDetail.goods_name}}</td>
					</tr>
					<tr>
						<td>被抽检单位名称：</td>
						<td>{{goodsDetail.company_name}}</td>
					</tr>
					<tr>
						<td>被抽检单位所在省份：</td>
						<td>{{goodsDetail.province_name}}</td>
					</tr>
					<!-- <tr>
						<td>商标：</td>
						<td>/</td>
					</tr> -->
					<tr>
						<td>标称生产企业地址：</td>
						<td>{{goodsDetail.bc_company_address}}</td>
					</tr>
					<tr>
						<td>生产日期/批号：</td>
						<td>{{goodsDetail.sc_time}}</td>
					</tr>
					<tr>
						<td>规格型号：</td>
						<td>{{goodsDetail.product_spec}}</td>
					</tr>
					<tr>
						<td>食物类型：</td>
						<td>{{goodsDetail.one_category_name}}/{{goodsDetail.two_category_name}}</td>
					</tr>
					<tr>
						<td>抽检单位：</td>
						<td>{{goodsDetail.check_agency}}</td>
					</tr>
					<tr>
						<td>检测项目名称：</td>
						<td>{{goodsDetail.check_project}}</td>
					</tr>
					<tr>
						<td>标准值：</td>
						<td>{{goodsDetail.standard_value}}</td>
					</tr>
					<tr>
						<td>检测值：</td>
						<td>{{goodsDetail.check_value}}</td>
					</tr>
					<tr>
						<td>抽检结果：</td>
						<td>{{goodsDetail.check_result}}</td>
					</tr>
				</tbody>
			</table>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		dishonesty_lists,
		spotcheck_lists
	} from '@/api/supply/supply';
	export default {
		name: "supplyGgxy",
		components: {},
		data: () => {
			return {
				orderList: [],
				currentPage: 1,
				pageSize: 10,
				total: 0,
				loading: true,
				source: 0,
				dialogTableVisible: false,
				goodsDetail: null
			};
		},
		created() {
			this.source = this.$route.query.source
			this.getOrderList();

		},
		methods: {
			goclick(row, column, event, cell) {
				console.log(row, column, event, cell)
				this.goodsDetail = row
				this.dialogTableVisible = true
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			refresh() {
				this.loading = true;
				this.getOrderList();
			},
			getOrderList() {
				if (this.source == 0) {
					dishonesty_lists({
							page: this.currentPage,
							page_size: this.pageSize,

						})
						.then(res => {
							let list = [];
							if (res.code == 0 && res.data) {
								list = res.data.list;
								this.total = res.data.count;
							}
							this.orderList = list;
							this.loading = false;
						})
						.catch(res => {
							this.loading = false;
						});
				} else {
					spotcheck_lists({
							page: this.currentPage,
							page_size: this.pageSize,
							source: this.source
						})
						.then(res => {
							let list = [];
							if (res.code == 0 && res.data) {
								list = res.data.list;
								this.total = res.data.count;
							}
							this.orderList = list;
							this.loading = false;
						})
						.catch(res => {
							this.loading = false;
						});
				}

			},
		}
	};
</script>
<style scoped>
	/deep/.nowidth .el-form-item__content {
		width: 360px;
	}

	.el-select {
		width: 120px;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}

	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #fff !important;
	}

	/deep/.el-pager .number:hover {
		color: #11773a !important;
	}
</style>
<style scoped lang="scss">
	.el-button--primary {
		background-color: #57c5a5;
		border-color: #57c5a5;
	}

	.container {
		.banner {
			background: url('../../../assets/images/supply/common.png') no-repeat;
			background-size: 100% 100%;
			position: relative;
			z-index: 1;
			width: 100%;
			padding: 6% 0;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.breadcrumb-text {
					// height: 300px;
					position: relative;
					z-index: 1;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h2 {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 36px;
					}

					p {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 18px;
					}
				}

			}

		}

		.scfl {
			padding-top: 30px;



			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;
				padding-bottom: 10px;
				border-bottom: 1px #c1c1c1 solid;
			}
		}

		.main_wrap {
			padding-bottom: 50px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.search_wrap {
					height: 48px;
					line-height: 48px;
					text-align: center;
					font-size: 15px;
					margin: 30px 0;

					.el-form-item {
						margin-bottom: 0;

					}
				}

				.box-card {
					.route_list_title {
						height: 18px;
						line-height: 18px;
						padding: 0 0 0 6px;
						font-size: 16px;
						border-left: 3px solid #57c5a5;
						color: #57c5a5;
						overflow: hidden;
					}

					.route_list {
						border: 1px #eee solid;

						.list_item {
							padding: 10px 20px;

							border-bottom: 1px #eee solid;

							.title {
								color: #333;
								font-size: 16px;
								font-weight: bold;
								margin-bottom: 10px;
								display: block;
							}

							.info {
								margin-bottom: 5px;
								display: inline-block;
								width: 33%;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;

								a {
									color: #57c5a5;
									font-size: 14px;
									font-weight: bold;
								}
							}
						}
					}
				}
			}
		}
	}

	.empty-wrap {
		text-align: center;
		padding: 10px;
	}

	.table>tbody>tr>td {
		text-align: center;
		background: #fff;
		height: 30px;
		line-height: 30px;
		font-size: 16px;
		// border-bottom: 1px solid #e2e2e2;
	}

	.table td,
	.table th {
		padding: 10px;
		vertical-align: top;
		border-top: 1px solid #dee2e6;
	}

	.table {
		// border-spacing: 0;
		width: 100%;
	}

	.info-detail_body tr td:first-child {
		width: 142px;
		text-align: right;
	}
</style>