<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card order-list">
			<div slot="header" class="clearfix">
				<span>我的线路</span>
				<el-button style="margin-left: 15px;" type="primary" size="small " @click="addBtn">新增</el-button>
			</div>

			<div v-loading="loading">
				<el-table :data="orderList" border style="width: 100%">
					<el-table-column label="出发地" width="150">
						<template slot-scope="scope">
							{{orderList[scope.$index].fromaddr_province_name}}{{orderList[scope.$index].fromaddr_city_name}}{{orderList[scope.$index].fromaddr_district_name}}
						</template>
					</el-table-column>
					<el-table-column label="到达地" width="150">
						<template slot-scope="scope">
							{{orderList[scope.$index].toaddr_province_name}}{{orderList[scope.$index].toaddr_city_name}}{{orderList[scope.$index].toaddr_district_name}}
						</template>

					</el-table-column>
					<el-table-column prop="contacts" label="联系人" width="100">
					</el-table-column>
					<el-table-column prop="phone" label="手机号" width="120">
					</el-table-column>
					<el-table-column label="运价-轻货" width="120">
						<template slot-scope="scope">
							{{(orderList[scope.$index].lightprice&&orderList[scope.$index].lightprice>0)?orderList[scope.$index].lightprice+'元/立方':'面议'}}
						</template>
					</el-table-column>
					<el-table-column label="运价-重货" width="120">
						<template slot-scope="scope">
							{{(orderList[scope.$index].heavyprice&&orderList[scope.$index].heavyprice>0)?orderList[scope.$index].heavyprice+'元/吨':'面议'}}
						</template>

					</el-table-column>
					<el-table-column label="时效" width="120">
						<template slot-scope="scope">
							{{(orderList[scope.$index].time&&orderList[scope.$index].time>0)?orderList[scope.$index].time+'天':'不详'}}
						</template>

					</el-table-column>
					<el-table-column label="是否显示" width="120">
						<template slot-scope="scope">
							<el-switch :value="orderList[scope.$index].is_show?true:false" active-color="#11773a"
								@change="changeSwitch($event,scope.row.id,scope.$index)">
							</el-switch>
						</template>

					</el-table-column>
					<el-table-column fixed="right" label="操作" width="100">
						<template slot-scope="scope">

							<el-button type="text" size="small" @click="handleClick(scope.row)">编辑</el-button>
							<el-button @click="deleteClick(scope.row)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pager" style="margin-top: 20px;">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
					:current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange"
					@current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
			</div>
		</el-card>
		<el-dialog title="线路详情" :visible.sync="addLogistics" width="80%">
			<el-form :model="formData" :rules="formrules" ref="ruleFormData" label-width="80px">
				<el-form-item label="出发地" prop="fromaddr_province_id">
					<el-select v-model="formData.fromaddr_province_id" placeholder="请选择省"
						@change="changefromaddr_Provice(formData.fromaddr_province_id)">
						<el-option v-for="option in fromaddr_province" :key="option.id" :label="option.name"
							:value="option.id">{{ option.name }}</el-option>
					</el-select>
					<el-select v-model="formData.fromaddr_city_id" placeholder="请选择市"
						@change="changefromaddr_City(formData.fromaddr_city_id)">
						<el-option v-for="option in fromaddr_city" :key="option.id" :label="option.name"
							:value="option.id">{{ option.name }}</el-option>
					</el-select>
					<el-select v-model="formData.fromaddr_district_id" placeholder="请选择区/县"
						@change="changefromaddr_District(formData.fromaddr_district_id)">
						<el-option v-for="option in fromaddr_district" :key="option.id" :label="option.name"
							:value="option.id">{{ option.name }}</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="到达地" prop="toaddr_province_id">
					<el-select v-model="formData.toaddr_province_id" placeholder="请选择省"
						@change="changetoaddr_Provice(formData.toaddr_province_id)">
						<el-option v-for="option in toaddr_province" :key="option.id" :label="option.name"
							:value="option.id">{{ option.name }}</el-option>
					</el-select>
					<el-select v-model="formData.toaddr_city_id" placeholder="请选择市"
						@change="changetoaddr_City(formData.toaddr_city_id)">
						<el-option v-for="option in toaddr_city" :key="option.id" :label="option.name"
							:value="option.id">{{ option.name }}</el-option>
					</el-select>
					<el-select v-model="formData.toaddr_district_id" placeholder="请选择区/县"
						@change="changetoaddr_District(formData.toaddr_district_id)">
						<el-option v-for="option in toaddr_district" :key="option.id" :label="option.name"
							:value="option.id">{{ option.name }}</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="联系人" prop="contacts">
					<el-input v-model="formData.contacts" placeholder="请输入联系人" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model="formData.phone" maxlength="11" placeholder="请输入手机号"
						class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="运价-轻货" prop="lightprice">
					<el-input type="number" v-model="formData.lightprice" placeholder="请输入运价-轻货(单位:元/立方,不填为面议)"
						class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="运价-重货" prop="heavyprice">
					<el-input type="number" v-model="formData.heavyprice" placeholder="请输入运价-重货(单位:元/吨,不填为面议)"
						class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="时效" prop="time">
					<el-input type="number" v-model="formData.time" placeholder="请输入时效(天)" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="medium" @click="saveInfo('ruleFormData')">保存</el-button>
				</el-form-item>
			</el-form>

		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import {
		myLogistics,
		getArea,
		add_route,
		modifyState,
		delLogistics
	} from '@/api/supply/supply';
	export default {
		name: 'supplymemberLogistics',
		components: {},
		data() {
			let self = this
			var isMobile = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("手机号不能为空"))
				} else {
					const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

					if (reg.test(value)) {
						callback()
					} else {
						callback(new Error("请输入正确的手机号"))
					}
				}
			}
			var areaValidator = (rule, value, callback) => {

				if (self.formData.fromaddr_province_id) {
					if (self.formData.fromaddr_city_id) {
						if (self.formData.fromaddr_district_id) {
							return callback()
						} else {
							return callback(new Error("请选择区/县"))
						}
					} else {
						return callback(new Error("请选择市"))
					}
				} else {
					return callback(new Error("请选择省"))
				}
			}
			var areaValidatorTo = (rule, value, callback) => {

				if (self.formData.toaddr_province_id) {
					if (self.formData.toaddr_city_id) {
						if (self.formData.toaddr_district_id) {
							return callback()
						} else {
							return callback(new Error("请选择区/县"))
						}
					} else {
						return callback(new Error("请选择市"))
					}
				} else {
					return callback(new Error("请选择省"))
				}
			}
			return {
				loading: true,
				addLogistics: false,
				orderList: [],
				currentPage: 1,
				pageSize: 10,
				total: 0,
				yes: true,
				formData: {
					fromaddr_province_id: "",
					fromaddr_province_name: "",
					fromaddr_city_id: "",
					fromaddr_city_name: "",
					fromaddr_district_id: "",
					fromaddr_district_name: "",
					toaddr_province_id: "",
					toaddr_province_name: "",
					toaddr_city_id: "",
					toaddr_city_name: "",
					toaddr_district_id: "",
					toaddr_district_name: "",
					contacts: "",
					phone: "",
					lightprice: "",
					heavyprice: "",
					time: "",
				},
				formrules: {
					fromaddr_province_id: [{
						required: true,
						validator: areaValidator,
						trigger: "blur"
					}],
					toaddr_province_id: [{
						required: true,
						validator: areaValidatorTo,
						trigger: "blur"
					}],
					contacts: [{
						required: true,
						message: "请输入联系人",
						trigger: "blur"
					}],
					phone: [{
						required: true,
						validator: isMobile,
						trigger: "blur"
					}],
				},
				fromaddr_province: [],
				fromaddr_city: [],
				fromaddr_district: [],
				fromaddr_pickerValueArray: [],
				fromaddr_multiIndex: [0, 0, 0],
				fromaddr_isInitMultiArray: false,
				// 是否加载完默认地区
				fromaddr_isLoadDefaultAreas: false,
				fromaddr_defaultRegions: [],
				toaddr_province: [],
				toaddr_city: [],
				toaddr_district: [],
				toaddr_pickerValueArray: [],
				toaddr_multiIndex: [0, 0, 0],
				toaddr_isInitMultiArray: false,
				// 是否加载完默认地区
				toaddr_isLoadDefaultAreas: false,
				toaddr_defaultRegions: [],
			};
		},
		created() {
			this.getOrderList();
			this.getDefaultAreas(0, {
				level: 0
			})
		},
		watch: {
			fromaddr_defaultRegions: {
				handler(arr, oldArr = []) {
					// 避免传的是字面量的时候重复触发
					if (arr.length !== 3 || arr.join("") === oldArr.join("")) return
					this.fromaddr_handleDefaultRegions()
				},
				immediate: true
			},
			toaddr_defaultRegions: {
				handler(arr, oldArr = []) {
					// 避免传的是字面量的时候重复触发
					if (arr.length !== 3 || arr.join("") === oldArr.join("")) return
					this.toaddr_handleDefaultRegions()
				},
				immediate: true
			}
		},
		computed: {
			// ...mapGetters(['defaultGoodsImage']),
			fromaddr_pickedArr() {
				// 进行初始化
				if (this.fromaddr_isInitMultiArray) {
					return [this.fromaddr_pickerValueArray[0], this.fromaddr_pickerValueArray[1], this
						.fromaddr_pickerValueArray[2]
					]
				}
				return [this.fromaddr_pickerValueArray[0], this.fromaddr_city, this.fromaddr_district]
			},
			toaddr_pickedArr() {
				// 进行初始化
				if (this.toaddr_isInitMultiArray) {
					return [this.toaddr_pickerValueArray[0], this.toaddr_pickerValueArray[1], this.toaddr_pickerValueArray[
						2]]
				}
				return [this.toaddr_pickerValueArray[0], this.toaddr_city, this.toaddr_district]
			}
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		methods: {
			//删除
			deleteClick(data) {
				this.$confirm("您确定要删除吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					delLogistics({
						route_id: data.id
					}).then(res => {
						this.$message({
							message: "删除成功",
							type: "success"
						})
						this.refresh()
						typeof callback == "function" && callback()
					})
				})
			},
			//编辑
			handleClick(data) {
				this.formData = data
				this.fromaddr_defaultRegions = [data.fromaddr_province_id, data.fromaddr_city_id, data
					.fromaddr_district_id
				]
				this.toaddr_defaultRegions = [data.toaddr_province_id, data.toaddr_city_id, data.toaddr_district_id]
				this.addLogistics = true
			},
			//修改状态
			changeSwitch(e, route_id, index) {
				console.log(e, route_id)
				modifyState({
						route_id,
						is_show: e ? '1' : '0'
					})
					.then(res => {
						if (res.code == 0) {
							this.$message({
								message: "修改成功！",
								type: "success"
							})
							this.refresh()
						} else {
							this.$message({
								message: res.message,
								type: "warning"
							})
						}
					})
					.catch(err => {
						this.flag = false
						this.$message.error(err.message)
					})
			},
			saveInfo(formName) {
				this.$refs[formName].validate(valid => {
					console.log(valid)
					if (valid) {
						var data = {
							fromaddr_province_id: this.formData.fromaddr_province_id,
							fromaddr_province_name: this.formData.fromaddr_province_name,
							fromaddr_city_id: this.formData.fromaddr_city_id,
							fromaddr_district_id: this.formData.fromaddr_district_id,
							fromaddr_city_name: this.formData.fromaddr_city_name,
							fromaddr_district_name: this.formData.fromaddr_district_name,
							toaddr_province_id: this.formData.toaddr_province_id,
							toaddr_province_name: this.formData.toaddr_province_name,
							toaddr_city_id: this.formData.toaddr_city_id,
							toaddr_city_name: this.formData.toaddr_city_name,
							toaddr_district_id: this.formData.toaddr_district_id,
							toaddr_district_name: this.formData.toaddr_district_name,
							contacts: this.formData.contacts,
							phone: this.formData.phone,
							heavyprice: this.formData.heavyprice,
							lightprice: this.formData.lightprice,
							time: this.formData.time,

						}
						if (this.formData.id) {
							data.id = this.formData.id
						}
						if (this.flag) return
						this.flag = true

						add_route(data)
							.then(res => {
								if (res.code == 0) {
									this.flag = false
									this.$message({
										message: "提交成功！",
										type: "success"
									})
									this.currentPage = 1
									this.refresh()
									this.addLogistics = false
								} else {
									this.flag = false
									this.$message({
										message: res.message,
										type: "warning"
									})
								}
							})
							.catch(err => {
								this.flag = false
								this.$message.error(err.message)
							})
					} else {
						return false
					}
				})
			},
			fromaddr_handleDefaultRegions() {
				var time = setInterval(() => {
					if (!this.fromaddr_isLoadDefaultAreas) return
					this.fromaddr_isInitMultiArray = false

					for (let i = 0; i < this.fromaddr_defaultRegions.length; i++) {
						for (let j = 0; j < this.fromaddr_pickerValueArray[i].length; j++) {
							this.fromaddr_province = this.fromaddr_pickerValueArray[0]

							// 匹配省
							if (this.fromaddr_defaultRegions[i] == this.fromaddr_pickerValueArray[i][j].id) {
								// 设置选中省
								this.$set(this.fromaddr_multiIndex, i, j)
								// 查询市
								this.getAreas(this.fromaddr_pickerValueArray[i][j].id, data => {
									this.fromaddr_city = data

									for (let k = 0; k < this.fromaddr_city.length; k++) {
										if (this.fromaddr_defaultRegions[1] == this.fromaddr_city[k].id) {
											// 设置选中市
											this.$set(this.fromaddr_multiIndex, 1, k)

											// 查询区县
											this.getAreas(this.fromaddr_city[k].id, data => {
												this.fromaddr_district = data

												// 设置选中区县
												for (let u = 0; u < this.fromaddr_district
													.length; u++) {
													if (this.fromaddr_defaultRegions[2] == this
														.fromaddr_district[u]
														.id) {
														this.$set(this.fromaddr_multiIndex, 2, u)
														this.fromaddr_handleValueChange({
															detail: {
																value: [j, k, u]
															}
														})
														break
													}
												}
											})

											break
										}
									}
								})
							}
						}
					}
					if (this.fromaddr_isLoadDefaultAreas) clearInterval(time)
				}, 100)
			},
			toaddr_handleDefaultRegions() {
				var time = setInterval(() => {
					if (!this.toaddr_isLoadDefaultAreas) return
					this.toaddr_isInitMultiArray = false

					for (let i = 0; i < this.toaddr_defaultRegions.length; i++) {
						for (let j = 0; j < this.toaddr_pickerValueArray[i].length; j++) {
							this.toaddr_province = this.toaddr_pickerValueArray[0]

							// 匹配省
							if (this.toaddr_defaultRegions[i] == this.toaddr_pickerValueArray[i][j].id) {
								// 设置选中省
								this.$set(this.toaddr_multiIndex, i, j)
								// 查询市
								this.getAreas(this.toaddr_pickerValueArray[i][j].id, data => {
									this.toaddr_city = data

									for (let k = 0; k < this.toaddr_city.length; k++) {
										if (this.toaddr_defaultRegions[1] == this.toaddr_city[k].id) {
											// 设置选中市
											this.$set(this.toaddr_multiIndex, 1, k)

											// 查询区县
											this.getAreas(this.toaddr_city[k].id, data => {
												this.toaddr_district = data

												// 设置选中区县
												for (let u = 0; u < this.toaddr_district
													.length; u++) {
													if (this.toaddr_defaultRegions[2] == this
														.toaddr_district[u]
														.id) {
														this.$set(this.toaddr_multiIndex, 2, u)
														this.toaddr_handleValueChange({
															detail: {
																value: [j, k, u]
															}
														})
														break
													}
												}
											})

											break
										}
									}
								})
							}
						}
					}
					if (this.toaddr_isLoadDefaultAreas) clearInterval(time)
				}, 100)
			},
			fromaddr_handleValueChange(e) {
				// 结构赋值
				let [index0, index1, index2] = e.detail.value
				let [arr0, arr1, arr2] = this.fromaddr_pickedArr
				let address = [arr0[index0], arr1[index1], arr2[index2]]

				this.formData.full_address = ""
				for (let i = 0; i < address.length; i++) {
					if (this.formData.full_address) {
						this.formData.full_address = this.formData.full_address + "-" + address[i].name
					} else {
						this.formData.full_address = this.formData.full_address + address[i].name
					}
				}
			},
			toaddr_handleValueChange(e) {
				// 结构赋值
				let [index0, index1, index2] = e.detail.value
				let [arr0, arr1, arr2] = this.toaddr_pickedArr
				let address = [arr0[index0], arr1[index1], arr2[index2]]

				this.formData.full_address = ""
				for (let i = 0; i < address.length; i++) {
					if (this.formData.full_address) {
						this.formData.full_address = this.formData.full_address + "-" + address[i].name
					} else {
						this.formData.full_address = this.formData.full_address + address[i].name
					}
				}
			},
			getAreas(pid, callback) {
				getArea({
						pid: pid
					})
					.then(res => {
						if (res.code == 0) {
							var data = []
							res.data.forEach((item, index) => {
								data.push(item)
							})
							if (callback) callback(data)
						}
					})
					.catch(err => {})
			},
			/**
			 * 改变省
			 */
			changefromaddr_Provice(id) {
				this.getAreas(id, data => (this.fromaddr_city = data))
				let obj = {}
				obj = this.fromaddr_province.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.fromaddr_city_id = ""
				this.formData.fromaddr_district_id = ""
				this.formData.fromaddr_province_name = obj.name // 设置选中的地址
			},
			changetoaddr_Provice(id) {
				this.getAreas(id, data => (this.toaddr_city = data))
				let obj = {}
				obj = this.toaddr_province.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.toaddr_city_id = ""
				this.formData.toaddr_district_id = ""
				this.formData.toaddr_province_name = obj.name // 设置选中的地址
			},
			/**
			 * 改变市
			 */
			changefromaddr_City(id) {
				this.getAreas(id, data => (this.fromaddr_district = data))
				let obj = {}
				obj = this.fromaddr_city.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.fromaddr_district_id = ""
				this.formData.fromaddr_city_name = obj.name
			},
			changetoaddr_City(id) {
				this.getAreas(id, data => (this.toaddr_district = data))
				let obj = {}
				obj = this.toaddr_city.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.toaddr_district_id = ""
				this.formData.toaddr_city_name = obj.name
			},
			/**
			 * 改变区
			 */
			changefromaddr_District(id) {
				let obj = {}
				obj = this.fromaddr_district.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.fromaddr_district_name = obj.name
				console.log(this.formData.fromaddr_district_name)
			},
			changetoaddr_District(id) {
				let obj = {}
				obj = this.toaddr_district.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.toaddr_district_name = obj.name
				console.log(this.formData.toaddr_district_name)
			},
			//获取省市区
			getDefaultAreas(pid, obj) {
				getArea({
						pid: pid
					})
					.then(res => {
						if (res.code == 0) {
							var data = []
							var selected = undefined
							res.data.forEach((item, index) => {
								if (obj != undefined) {
									if (obj.level == 0 && obj.province_id != undefined) {
										selected = obj.province_id
									} else if (obj.level == 1 && obj.city_id != undefined) {
										selected = obj.city_id
									} else if (obj.level == 2 && obj.district_id != undefined) {
										selected = obj.district_id
									}
								}

								if (selected == undefined && index == 0) {
									selected = item.id
								}
								data.push(item)
							})

							// this.pickerValueArray[obj.level] = data
							this.fromaddr_pickerValueArray[obj.level] = data
							this.toaddr_pickerValueArray[obj.level] = data
							if (obj.level + 1 < 3) {
								obj.level++
								this.getDefaultAreas(selected, obj)
							} else {
								this.fromaddr_isInitMultiArray = true
								this.fromaddr_isLoadDefaultAreas = true
								this.toaddr_isInitMultiArray = true
								this.toaddr_isLoadDefaultAreas = true
								// this.isInitMultiArray = true
								// this.isLoadDefaultAreas = true
							}

							// this.province = this.pickerValueArray[0]
							this.fromaddr_province = this.fromaddr_pickerValueArray[0]
							this.toaddr_province = this.toaddr_pickerValueArray[0]
						}
						this.loading = false
					})
					.catch(err => {
						this.loading = false
					})
			},
			addBtn() {
				this.formData = {
					fromaddr_province_id: "",
					fromaddr_province_name: "",
					fromaddr_city_id: "",
					fromaddr_city_name: "",
					fromaddr_district_id: "",
					fromaddr_district_name: "",
					toaddr_province_id: "",
					toaddr_province_name: "",
					toaddr_city_id: "",
					toaddr_city_name: "",
					toaddr_district_id: "",
					toaddr_district_name: "",
					contacts: "",
					phone: "",
					lightprice: "",
					heavyprice: "",
					time: "",
				}
				this.fromaddr_defaultRegions = [0, 0, 0]
				this.toaddr_defaultRegions = [0, 0, 0]
				this.addLogistics = true
			},

			getOrderList() {
				myLogistics({
						page: this.currentPage,
						page_size: this.pageSize,
					})
					.then(res => {
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						this.orderList = list;
						this.loading = false;
					})
					.catch(res => {
						this.loading = false;
					});
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			refresh() {
				this.loading = true;
				this.getOrderList();
			},
			operation(action, orderData) {
				let index = this.status;
				switch (action) {
					case 'orderPay': // 支付
						this.orderPay(orderData);
						break;
					case 'orderClose': //关闭
						this.orderClose(orderData.order_id, () => {
							this.refresh();
						});
						break;
					case 'memberTakeDelivery': //收货
						this.orderDelivery(orderData.order_id, () => {
							this.refresh();
						});
						break;
					case 'trace': //查看物流
						this.$router.push({
							path: '/member/logistics',
							query: {
								order_id: orderData.order_id
							}
						});
						break;
					case 'memberOrderEvaluation': //评价
						this.$router.pushToTab({
							path: '/evaluate',
							query: {
								order_id: orderData.order_id
							}
						});
						break;
				}
			},
			orderDetail(data) {
				switch (parseInt(data.order_type)) {
					case 2:
						// 自提订单
						this.$router.push({
							path: '/member/order_detail_pickup',
							query: {
								order_id: data.order_id
							}
						});
						break;
					case 3:
						// 本地配送订单
						this.$router.push({
							path: '/member/order_detail_local_delivery',
							query: {
								order_id: data.order_id
							}
						});
						break;
					case 4:
						// 虚拟订单
						this.$router.push({
							path: '/member/order_detail_virtual',
							query: {
								order_id: data.order_id
							}
						});
						break;
					default:
						this.$router.push({
							path: '/member/order_detail',
							query: {
								order_id: data.order_id
							}
						});
						break;
				}
			},
			imageError(orderIndex, goodsIndex) {
				this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage;
			}
		}
	};
</script>
<style scoped>
	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #11773a;
	}

	/deep/.el-radio__input.is-checked .el-radio__inner {
		border-color: #11773a;
		background: #11773a;
	}

	/deep/.el-radio__inner:hover {
		border-color: #11773a;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-form-item.is-error .el-input__inner {
		border-color: #11773a;
	}

	/deep/.el-form-item .el-input__inner:focus {
		border-color: #11773a;
	}

	/deep/.el-textarea__inner:focus {
		border-color: #11773a;
	}

	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}

	/deep/.info_wrap .el-form-item__label {
		line-height: 20px;
	}

	/deep/.el-tabs__item:hover {
		color: #11773a;
	}

	/deep/.el-upload--picture-card:hover {
		border-color: #11773a;
	}

	/deep/.el-upload--picture-card:focus {
		border-color: #11773a;
	}

	/deep/.el-range-editor.is-active {
		border-color: #11773a;
	}

	/deep/.el-tabs__item.is-active {
		color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #fff !important;
	}

	/deep/.el-pager .number:hover {
		color: #11773a !important;
	}
</style>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.el-button--primary {
		background-color: $base-supply-color;
		border-color: $base-supply-color;
	}

	.order-list {
		.el-button--primary {
			background-color: $base-supply-color;
			border-color: $base-supply-color;
		}

		.el-button--text {
			color: $base-supply-color;
		}

		nav {
			overflow: hidden;
			padding: 10px 0;
			background: #fff;
			margin-bottom: 10px;
			border-bottom: 1px solid #eeeeee;

			li {
				float: left;

				&:nth-child(1) {
					width: 45%;
				}

				&:nth-child(2) {
					width: 10%;
				}

				&:nth-child(3) {
					width: 10%;
				}

				&:nth-child(4) {
					width: 10%;
				}

				&:nth-child(5) {
					width: 15%;
				}

				&:nth-child(6) {
					width: 10%;
				}
			}
		}

		.list {
			.item {
				margin-bottom: 20px;
				border: 1px solid #eeeeee;
				border-top: 0;

				.head {
					padding: 8px 10px;
					background: #f7f7f7;
					font-size: 12px;

					.create-time {
						margin-right: 10px;
					}

					border-bottom: 1px solid #eeeeee;

					a {
						margin: 0 10px 0 20px;
					}

					.order-type {
						margin-left: 30px;
					}
				}
			}

			ul {
				background-color: #fff;
				padding: 10px;
				overflow: hidden;

				li {
					float: left;
					line-height: 60px;

					&:nth-child(1) {
						width: 45%;
						line-height: inherit;

						.img-wrap {
							width: 60px;
							height: 60px;
							float: left;
							margin-right: 10px;
							cursor: pointer;
						}

						.info-wrap {
							margin-left: 70px;

							h5 {
								font-weight: normal;
								font-size: $ns-font-size-base;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
								display: inline-block;
								cursor: pointer;

								&:hover {
									color: $base-color;
								}
							}

							span {
								font-size: $ns-font-size-sm;
								color: #9a9a9a;
							}
						}
					}

					&:nth-child(2) {
						width: 10%;
					}

					&:nth-child(3) {
						width: 10%;
					}

					&:nth-child(4) {
						width: 10%;
					}

					&:nth-child(5) {
						width: 15%;
						line-height: 30px;

						a {
							display: block;
						}
					}

					&:nth-child(6) {
						width: 10%;
						line-height: initial;

						button {
							margin-left: 0;
							margin-bottom: 10px;

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		.empty-wrap {
			text-align: center;
			padding: 10px;
		}
	}
</style>