<template>
	<div id="leftLoops">
		<div class="bg-color-black">
			<div class="d-flex pt-2 pl-2">
				<!-- <span style="color:#5cd9e8">
                  <icon name="chart-line"></icon>
                </span> -->
				<div class="d-flex" style="justify-content: space-between;align-items: center;width:100%">
					<span class=" text mx-2" style="font-size: 0.22rem;">公共采购</span>
					<router-link to="/supplyView/zyjc" class=" text mx-2" style="font-size: 0.22rem;">更多>></router-link>
				</div>
			</div>

			<div class="d-flex jc-center body-box">
				<dv-scroll-board class="leftLoop" :config="config" @click="onCountyRowClick"
					style="width: 100%;height:3.6rem" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		bidding_listsIndex
	} from "@/api/supply/supply"
	export default {
		data() {
			return {
				countyCheckData: [],
				config: {

					rowNum: 6, //表格行数
					headerBGC: "#0f1325", //表头
					oddRowBGC: "#0f1325", //奇数行
					evenRowBGC: "#171c33", //偶数行
					// index: true,
					// columnWidth: [60],
					align: ["center"],
					data: [],
				},
				list: []
			};
		},
		mounted() {
			this.setData();
		},
		methods: {
			setData() {
				bidding_listsIndex({
					page: 1,
					page_size: 10,
					source: 1
				}).then(res => {
					if (res.code == 0 && res.data) {
						this.list = res.data.list
						this.config = {
							rowNum: 6, //表格行数
							headerBGC: "#0f1325", //表头
							oddRowBGC: "#0f1325", //奇数行
							evenRowBGC: "#171c33", //偶数行
							// index: true,
							columnWidth: [170],
							// align: ["center"],
							data: res.data.list.map(item => {
								return [item.title, item.create_time]
							})
						}
						console.log(this.config.data)
					} else {
						this.config = {
							rowNum: 6, //表格行数
							headerBGC: "#0f1325", //表头
							oddRowBGC: "#0f1325", //奇数行
							evenRowBGC: "#171c33", //偶数行
							// index: true,
							columnWidth: [170],
							// align: ["center"],
							data: []
						}
					}
					this.$forceUpdate()
				})
				// this.config.data = [
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// 	['县级名称', "<span class=" + 'color' + Math.round(Math.random()) + ">" + (Math.round(Math.random()) ?
				// 		'已提交' : '未提交') + "</span>"],
				// ];

			},
			onCountyRowClick(row) {
				console.log(row, this.list[row.rowIndex].id);

				let routerJump = this.$router.resolve({
					path: "/supplyView/zyjcDetail",
					query: {
						id: this.list[row.rowIndex].id
					}
				});
				window.open(routerJump.href, '_blank');
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep#leftLoops {
		padding: 0.2rem;
		height: 4.5rem;
		border-radius: 0.0625rem;

		.bg-color-black {
			height: 4.1rem;
			border-radius: 0.125rem;
		}

		.text {
			color: #c3cbde;
		}

		.color0 {
			color: #ff5722;
		}

		.color1 {
			color: #409EFF;
		}
	}
</style>