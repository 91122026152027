<template>
	<div class="container">
		<!-- 广告图 -->
		<div class="banner">
			<div class="main">
				<div class="breadcrumb-text">
					<h2>应急保障储备社会化数字协同平台之</h2>
					<p>Socialized Digital Collaborative Platform for emergency security reserve</p>
					<h2 class="mt-10">数智集采</h2>
					<p>Shuzhi Jicai</p>
				</div>
			</div>
		</div>
		<div class="scfl">
			<div class="main">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>数智集采</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main_wrap">
			<div class="main">
				<div class="suppliers_wrap">
					<div class="suppliers_tab" style="margin-right: 0;">
						<el-card class="box-card order-list">
							<div slot="header" class="clearfix">
								<div class="header_wrap">
									<span>国内采购标讯</span>
								</div>
							</div>

							<!-- <el-tabs type="card" v-model="companyStatus" @tab-click="handleClickcompany">
								<el-tab-pane label="交易失信" name="0"></el-tab-pane>
							</el-tabs> -->

							<div v-loading="inloading">
								<div class="list" v-if="inDataList.length > 0">
									
									<div class="item_company" v-for="(companyItem, companyIndex) in inDataList"
										:key="companyIndex">
										<h4 class="gjh4">
											<router-link :to="'/supplyView/zyjcDetail?id='+companyItem.id"
												target="_blank">{{companyItem.title}}</router-link>
											<span>{{$timeStampTurnTime(companyItem.create_time,1)}}</span>
										</h4>
									</div>
									
								</div>
								<div v-else-if="!inloading && inDataList.length == 0" class="empty-wrap">暂无相关数据
								</div>
							</div>
							<div class="pager" style="margin-top: 20px;">
								<el-pagination background :pager-count="5" :total="intotal" prev-text="上一页"
									next-text="下一页" :current-page.sync="incurrentPage" :page-size.sync="inpageSize"
									@size-change="handlePageSizeChangeIn" @current-change="handleCurrentPageChangeIn"
									hide-on-single-page></el-pagination>
							</div>
						</el-card>
					</div>
					<div class="suppliers_tab" style="margin-right: 0;">
						<el-card class="box-card order-list">
							<div slot="header" class="clearfix">
								<div class="header_wrap">
									<span>国际采购标讯</span>
								</div>
							</div>

							<!-- <el-tabs type="card" v-model="companyStatus" @tab-click="handleClickcompany">
								<el-tab-pane label="交易失信" name="0"></el-tab-pane>
							</el-tabs> -->

							<div v-loading="outloading">
								<div class="list" v-if="outDataList.length > 0">
									<div class="item_company" v-for="(companyItem, companyIndex) in outDataList"
										:key="companyIndex">
										<h4 class="gjh4">
											<router-link :to="'/supplyView/zyjcDetail?id='+companyItem.id"
												target="_blank">{{companyItem.title}}</router-link>
											<span>{{$timeStampTurnTime(companyItem.create_time,1)}}</span>
										</h4>
									</div>
								</div>
								<div v-else-if="!outloading && outDataList.length == 0" class="empty-wrap">暂无相关数据
								</div>
							</div>
							<div class="pager" style="margin-top: 20px;">
								<el-pagination background :pager-count="5" :total="outtotal" prev-text="上一页"
									next-text="下一页" :current-page.sync="outcurrentPage" :page-size.sync="outpageSize"
									@size-change="handlePageSizeChangeOut" @current-change="handleCurrentPageChangeOut"
									hide-on-single-page></el-pagination>
							</div>
						</el-card>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		bidding_listsIndex
	} from '@/api/supply/supply';
	export default {
		name: "supplyZyjc",
		components: {},
		data: () => {
			return {
				inDataList: [],
				inloading: true,
				incurrentPage: 1,
				inpageSize: 10,
				intotal: 0,
				outDataList: [],
				outloading: true,
				outcurrentPage: 1,
				outpageSize: 10,
				outtotal: 0,
			};
		},
		created() {
			this.getInList();
			this.getOutList();
		},
		methods: {
			handlePageSizeChangeIn(size) {
				this.inpageSize = size;
				this.inrefresh();
			},
			handleCurrentPageChangeIn(page) {
				this.incurrentPage = page;
				this.inrefresh();
			},
			inrefresh() {
				this.inloading = true;
				this.getInList();
			},
			getInList() {
				bidding_listsIndex({
						page: this.incurrentPage,
						page_size: this.inpageSize,
						source: 1
					})
					.then(res => {
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.intotal = res.data.count;
						}
						this.inDataList = list;
						this.inloading = false;
					})
					.catch(res => {
						this.inloading = false;
					});
			},
			handlePageSizeChangeOut(size) {
				this.outpageSize = size;
				this.outrefresh();
			},
			handleCurrentPageChangeOut(page) {
				this.outcurrentPage = page;
				this.outrefresh();
			},
			outrefresh() {
				this.outloading = true;
				this.getOutList();
			},
			getOutList() {
				bidding_listsIndex({
						page: this.outcurrentPage,
						page_size: this.outpageSize,
						source: 2
					})
					.then(res => {
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.outtotal = res.data.count;
						}
						this.outDataList = list;
						this.outloading = false;
					})
					.catch(res => {
						this.outloading = false;
					});
			},
		}
	};
</script>
<style scoped>
	/deep/.nowidth .el-form-item__content {
		width: 360px;
	}

	.el-select {
		width: 120px;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}

	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #fff !important;
	}

	/deep/.el-pager .number:hover {
		color: #11773a !important;
	}
</style>
<style scoped lang="scss">
	.el-button--primary {
		background-color: #57c5a5;
		border-color: #57c5a5;
	}

	.container {
		.banner {
			background: url('../../../assets/images/supply/cpk.png') no-repeat;
			background-size: 100% 100%;
			position: relative;
			z-index: 1;
			width: 100%;
			height: 300px;
			margin-top: 80px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.breadcrumb-text {
					height: 300px;
					position: relative;
					z-index: 1;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h2 {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 36px;
					}

					p {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 18px;
					}
				}

			}

		}

		.scfl {
			padding-top: 30px;



			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;
				padding-bottom: 10px;
				border-bottom: 1px #c1c1c1 solid;
			}
		}

		.main_wrap {
			padding-bottom: 50px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.suppliers_wrap {
					margin-top: 40px;
					display: flex;
					justify-content: space-between;
				}

				.suppliers_tab {
					width: 49%;
				}

				.header_wrap {
					border-left: 6px solid #009688;
					padding-left: 10px;

					span {
						color: #009688 !important;
						font-size: 18px;
						line-height: 1;
						font-weight: bold !important;
					}

					a {
						color: #2871d5 !important;
						line-height: 1;
						font-weight: bold !important;
					}
				}

				.list {
					

					min-height: 630px;
					.item_goods {
						border-bottom: 1px dashed #ccc;
						padding: 12px;
						cursor: pointer;

						div {
							color: #3c3c3c;
							font-size: 14px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							line-height: 1.5;

							span {
								font-size: 14px;
								color: #3c3c3c;
							}
						}
					}

					.item_company {
						border-bottom: 1px dashed #ccc;
						padding: 16px 12px;
						// margin: 4px 10px;
						cursor: pointer;

						h4 {
							height: 30px;
							line-height: 30px;
							display: flex;
							align-items: center;

							a {
								font-size: 14px;
								color: #3c3c3c;
								font-weight: normal;
								width: 75%;
								display: inline-block;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								margin-bottom: 0;

								&:hover {
									color: $base-supplyfuzhu-color;
									text-decoration: underline;


								}
							}

							span {
								flex-shrink: 0;
								flex: 1;
								color: #777;
								text-align: right;
								font-size: 13px;
								font-weight: bold;
							}
						}

					}


				}

			}
		}
	}

	.empty-wrap {
		text-align: center;
		padding: 10px;
		min-height: 630px;
		box-sizing: border-box;
	}
</style>