<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<div class="member-index" v-loading="loading">
			<div class="member-top">
				<div class="info-wrap">
					<div class="info-top">
						<div class="avtar">
							<router-link to="/supplymember/info">
								<img v-if="supplymember.avatar" :src="$img(supplymember.avatar)"
									@error="supplymember.avatar = defaultHeadImage" />
								<img v-else :src="$img(defaultHeadImage)" />
							</router-link>
						</div>
						<div class="member-wrap">
							<template v-if="supplymember">
								<div class="name member-name" v-if="supplymember.username">
									<router-link to="/supplymember/info">{{ supplymember.username }}</router-link>
								</div>
								<div class="level">
									{{ supplymember.is_auth==0?'未认证':supplymember.is_auth==1?'待审核':supplymember.is_auth==2?'已认证':'已拒绝' }}
								</div>
								<div class="level" style="margin-left: 10px;">
									{{ supplymember.class==1?'合格供应商':supplymember.class==2?'认证供应商':'预选供应商' }}
								</div>
								<div class="growth">
									手机号：{{supplymember.mobile}}
								</div>
							</template>
							<div class="no-login name" v-else>未登录</div>
						</div>

					</div>
					<div class="account">
						<div class="content">
							<div class="item">
								<router-link to="/supplymember/logisticsList" class="item-content">
									<img src="@/assets/images/coupon.png" alt />
									<div class="name">物流线路</div>
									<!-- <div class="num">0</div> -->
								</router-link>
							</div>

							<div class="item">
								<router-link to="/supplymember/mygoods" class="item-content">
									<img src="@/assets/images/point.png" alt />
									<div class="name">我的商品</div>
									<!-- <div class="num">0</div> -->
								</router-link>
							</div>
							<div class="item">
								<router-link to="/supplymember/myJcxx" class="item-content">
									<img src="@/assets/images/balance.png" alt />
									<div class="name">集采信息</div>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="member-bottom">
				中国合格供应商管理平台
			</div>
		</div>
	</div>
</template>

<script>
	import {
		user_info
	} from "@/api/supply/supply"
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: 'supplymember',
		components: {},
		data: () => {
			return {
				couponNum: 0,
				orderNum: {
					waitPay: 0, //待付款
					readyDelivery: 0, //待发货
					waitDelivery: 0, //待收货
					refunding: 0 // 退款中
				},
				orderList: [],
				orderStatus: 'all',
				footInfo: {
					page: 1,
					page_size: 6
				},
				total: 0,
				footList: [],
				currentPage: 1,
				loading: true,
				goodsTotal: 0,
				shopTotal: 0,
				state: '',
				growth: '',
				levelList: [],
				member_level: {},
				progress: 0,
				yes: true
			};
		},
		created() {
			this.memberInfo();
		},
		computed: {
			...mapGetters(['defaultHeadImage', 'defaultGoodsImage', 'supplymember'])
		},
		watch: {
			// supplymember: {
			// 	handler() {
			// 		if (this.supplymember) 
			// 	},
			// 	immediate: true,
			// 	deep: true
			// }
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false;
			}, 300);
		},
		methods: {
			memberInfo() {
				console.log(123)
				user_info()
					.then(res => {
						const {
							data
						} = res
						this.$store.commit("supplymember/SET_MEMBER_SUPPLY", data)
						this.loading = false
					})
					.catch(err => {
						console.log('err')
					})
			},

		}
	};
</script>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
		height: 100%;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #ffffff;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.member-index {
		height: 100%;
		display: flex;
		flex-direction: column;
		.member-top {
			width: 100%;
			display: flex;

			.info-wrap {
				width: 100%;
				height: 160px;
				border: 1px solid #e9e9e9;
				background: #ffffff;
				display: flex;

				.info-top {
					display: flex;
					align-items: center;
					margin: 22px;
					margin-right: 0;
					width: 42%;
					border-right: 1px solid #e9e9e9;

					.avtar {
						width: 84px;
						height: 84px;
						margin: 20px 0 10px 0;
						border: 1px solid #e9e9e9;
						border-radius: 50%;
						overflow: hidden;
						display: block;
						cursor: pointer;
						margin-left: 21px;
						text-align: center;
						line-height: 84px;

						img {
							display: inline-block;
						}
					}

					.member-wrap {
						margin-left: 20px;

						.name {
							font-size: 18px;
							font-weight: 600;
							cursor: pointer;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.growth {
							display: flex;
							align-items: center;
						}

						.el-progress {
							width: 100px;
						}

						.level {
							padding: 3px 4px;
							line-height: 1;
							color: #ffffc1;
							margin: 6px 0;
							cursor: default;
							background: linear-gradient(#636362, #4e4e4d);
							border-radius: 3px;
							display: inline-block;
						}
					}
				}

				.account {
					width: 58%;
					display: flex;
					align-items: center;

					.content {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;

						.item {
							flex: 1;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;

							.item-content {
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
							}

							img {
								width: 50px;
								height: 50px;
							}

							.name {
								margin-top: 5px;
								color: #666666;

								&:hover {
									color: $base-color;
								}
							}

							.num {
								color: $ns-text-color-black;
							}
						}
					}
				}
			}

			.collection {
				background: #ffffff;
				margin-left: 20px;
				width: 210px;
				border: 1px solid #e9e9e9;
				padding-left: 20px;

				.title {
					padding: 10px 0;
					display: inline-block;
					border-bottom: 1px solid $base-color;
				}

				.xian {
					height: 1px;
					background: #f1f1f1;
				}

				.item-wrap {
					display: flex;
					justify-content: center;
					align-items: center;

					.item {
						flex: 1;
						margin-top: 37px;

						.num {}

						.collect {
							color: #666666;
						}
					}
				}
			}
		}

		.member-bottom {
			background-color: #fff;
			width: 100%;
			margin-top: 15px;
			overflow: hidden;
			padding: 200px 0;
			font-size: 25px;
			text-align: center;
			font-weight: bold;
			flex: 1;
		}
	}

	.empty {
		text-align: center;
		margin-top: 65px;
	}
</style>