export const cityProvincesMap = {
    provincesCode: {
        '110000': '北京市',
        '120000': '天津市',
        '130000': '河北省',
        '140000': '山西省',
        '150000': '内蒙古自治区',
        '210000': '辽宁省',
        '220000': '吉林省',
        '230000': '黑龙江省',
        '310000': '上海市',
        '320000': '江苏省',
        '330000': '浙江省',
        '340000': '安徽省',
        '350000': '福建省',
        '360000': '江西省',
        '370000': '山东省',
        '410000': '河南省',
        '420000': '湖北省',
        '430000': '湖南省',
        '440000': '广东省',
        '450000': '广西壮族自治区',
        '460000': '海南省',
        '500000': '重庆市',
        '510000': '四川省',
        '520000': '贵州省',
        '530000': '云南省',
        '540000': '西藏自治区',
        '610000': '陕西省',
        '620000': '甘肃省',
        '630000': '青海省',
        '640000': '宁夏回族自治区',
        '650000': '新疆维吾尔自治区',
        '710000': '台湾',
        '810000': '香港',
        '820000': '澳门'
    },
    provincesMap: {
        //23个省
        "台湾": "taiwan",
        "河北省": "hebei",
        "山西省": "shanxi",
        "辽宁省": "liaoning",
        "吉林省": "jilin",
        "黑龙江省": "heilongjiang",
        "江苏省": "jiangsu",
        "浙江省": "zhejiang",
        "安徽省": "anhui",
        "福建省": "fujian",
        "江西省": "jiangxi",
        "山东省": "shandong",
        "河南省": "henan",
        "湖北省": "hubei",
        "湖南省": "hunan",
        "广东省": "guangdong",
        "海南省": "hainan",
        "四川省": "sichuan",
        "贵州省": "guizhou",
        "云南省": "yunnan",
        "陕西省": "shanxi1",
        "甘肃省": "gansu",
        "青海省": "qinghai",
        //5个自治区
        "新疆维吾尔自治区": "xinjiang",
        "广西壮族自治区": "guangxi",
        "内蒙古自治区": "neimenggu",
        "宁夏回族自治区": "ningxia",
        "西藏自治区": "xizang",
        //4个直辖市
        "北京市": "beijing",
        "天津市": "tianjin",
        "上海市": "shanghai",
        "重庆": "chongqing",
        //2个特别行政区
        "香港": "xianggang",
        "澳门": "aomen",
    },
    areaMap: {
        "北京市": "110100",
        "天津市": "120100",
        "上海市": "310100",
        "重庆市": "500100",
        "崇明县": "310200",
        "湖北省直辖县市": "429000",
        "铜仁市": "522200",
        "毕节市": "522400",
        "石家庄市": "130100",
        "唐山市": "130200",
        "秦皇岛市": "130300",
        "邯郸市": "130400",
        "邢台市": "130500",
        "保定市": "130600",
        "张家口市": "130700",
        "承德市": "130800",
        "沧州市": "130900",
        "廊坊市": "131000",
        "衡水市": "131100",
        "太原市": "140100",
        "大同市": "140200",
        "阳泉市": "140300",
        "长治市": "140400",
        "晋城市": "140500",
        "朔州市": "140600",
        "晋中市": "140700",
        "运城市": "140800",
        "忻州市": "140900",
        "临汾市": "141000",
        "吕梁市": "141100",
        "呼和浩特市": "150100",
        "包头市": "150200",
        "乌海市": "150300",
        "赤峰市": "150400",
        "通辽市": "150500",
        "鄂尔多斯市": "150600",
        "呼伦贝尔市": "150700",
        "巴彦淖尔市": "150800",
        "乌兰察布市": "150900",
        "兴安盟": "152200",
        "锡林郭勒盟": "152500",
        "阿拉善盟": "152900",
        "沈阳市": "210100",
        "大连市": "210200",
        "鞍山市": "210300",
        "抚顺市": "210400",
        "本溪市": "210500",
        "丹东市": "210600",
        "锦州市": "210700",
        "营口市": "210800",
        "阜新市": "210900",
        "辽阳市": "211000",
        "盘锦市": "211100",
        "铁岭市": "211200",
        "朝阳市": "211300",
        "葫芦岛市": "211400",
        "长春市": "220100",
        "吉林市": "220200",
        "四平市": "220300",
        "辽源市": "220400",
        "通化市": "220500",
        "白山市": "220600",
        "松原市": "220700",
        "白城市": "220800",
        "延边朝鲜族自治州": "222400",
        "哈尔滨市": "230100",
        "齐齐哈尔市": "230200",
        "鸡西市": "230300",
        "鹤岗市": "230400",
        "双鸭山市": "230500",
        "大庆市": "230600",
        "伊春市": "230700",
        "佳木斯市": "230800",
        "七台河市": "230900",
        "牡丹江市": "231000",
        "黑河市": "231100",
        "绥化市": "231200",
        "大兴安岭地区": "232700",
        "南京市": "320100",
        "无锡市": "320200",
        "徐州市": "320300",
        "常州市": "320400",
        "苏州市": "320500",
        "南通市": "320600",
        "连云港市": "320700",
        "淮安市": "320800",
        "盐城市": "320900",
        "扬州市": "321000",
        "镇江市": "321100",
        "泰州市": "321200",
        "宿迁市": "321300",
        "杭州市": "330100",
        "宁波市": "330200",
        "温州市": "330300",
        "嘉兴市": "330400",
        "湖州市": "330500",
        "绍兴市": "330600",
        "金华市": "330700",
        "衢州市": "330800",
        "舟山市": "330900",
        "台州市": "331000",
        "丽水市": "331100",
        "合肥市": "340100",
        "芜湖市": "340200",
        "蚌埠市": "340300",
        "淮南市": "340400",
        "马鞍山市": "340500",
        "淮北市": "340600",
        "铜陵市": "340700",
        "安庆市": "340800",
        "黄山市": "341000",
        "滁州市": "341100",
        "阜阳市": "341200",
        "宿州市": "341300",
        "六安市": "341500",
        "亳州市": "341600",
        "池州市": "341700",
        "宣城市": "341800",
        "福州市": "350100",
        "厦门市": "350200",
        "莆田市": "350300",
        "三明市": "350400",
        "泉州市": "350500",
        "漳州市": "350600",
        "南平市": "350700",
        "龙岩市": "350800",
        "宁德市": "350900",
        "南昌市": "360100",
        "景德镇市": "360200",
        "萍乡市": "360300",
        "九江市": "360400",
        "新余市": "360500",
        "鹰潭市": "360600",
        "赣州市": "360700",
        "吉安市": "360800",
        "宜春市": "360900",
        "抚州市": "361000",
        "上饶市": "361100",
        "济南市": "370100",
        "青岛市": "370200",
        "淄博市": "370300",
        "枣庄市": "370400",
        "东营市": "370500",
        "烟台市": "370600",
        "潍坊市": "370700",
        "济宁市": "370800",
        "泰安市": "370900",
        "威海市": "371000",
        "日照市": "371100",
        "莱芜市": "371200",
        "临沂市": "371300",
        "德州市": "371400",
        "聊城市": "371500",
        "滨州市": "371600",
        "菏泽市": "371700",
        "郑州市": "410100",
        "开封市": "410200",
        "洛阳市": "410300",
        "平顶山市": "410400",
        "安阳市": "410500",
        "鹤壁市": "410600",
        "新乡市": "410700",
        "焦作市": "410800",
        "濮阳市": "410900",
        "许昌市": "411000",
        "漯河市": "411100",
        "三门峡市": "411200",
        "南阳市": "411300",
        "商丘市": "411400",
        "信阳市": "411500",
        "周口市": "411600",
        "驻马店市": "411700",
        "省直辖县级行政区划": "469000",
        "武汉市": "420100",
        "黄石市": "420200",
        "十堰市": "420300",
        "宜昌市": "420500",
        "襄阳市": "420600",
        "鄂州市": "420700",
        "荆门市": "420800",
        "孝感市": "420900",
        "荆州市": "421000",
        "黄冈市": "421100",
        "咸宁市": "421200",
        "随州市": "421300",
        "恩施土家族苗族自治州": "422800",
        "长沙市": "430100",
        "株洲市": "430200",
        "湘潭市": "430300",
        "衡阳市": "430400",
        "邵阳市": "430500",
        "岳阳市": "430600",
        "常德市": "430700",
        "张家界市": "430800",
        "益阳市": "430900",
        "郴州市": "431000",
        "永州市": "431100",
        "怀化市": "431200",
        "娄底市": "431300",
        "湘西土家族苗族自治州": "433100",
        "广州市": "440100",
        "韶关市": "440200",
        "深圳市": "440300",
        "珠海市": "440400",
        "汕头市": "440500",
        "佛山市": "440600",
        "江门市": "440700",
        "湛江市": "440800",
        "茂名市": "440900",
        "肇庆市": "441200",
        "惠州市": "441300",
        "梅州市": "441400",
        "汕尾市": "441500",
        "河源市": "441600",
        "阳江市": "441700",
        "清远市": "441800",
        "东莞市": "441900",
        "中山市": "442000",
        "潮州市": "445100",
        "揭阳市": "445200",
        "云浮市": "445300",
        "南宁市": "450100",
        "柳州市": "450200",
        "桂林市": "450300",
        "梧州市": "450400",
        "北海市": "450500",
        "防城港市": "450600",
        "钦州市": "450700",
        "贵港市": "450800",
        "玉林市": "450900",
        "百色市": "451000",
        "贺州市": "451100",
        "河池市": "451200",
        "来宾市": "451300",
        "崇左市": "451400",
        "海口市": "460100",
        "三亚市": "460200",
        "三沙市": "460300",
        "成都市": "510100",
        "自贡市": "510300",
        "攀枝花市": "510400",
        "泸州市": "510500",
        "德阳市": "510600",
        "绵阳市": "510700",
        "广元市": "510800",
        "遂宁市": "510900",
        "内江市": "511000",
        "乐山市": "511100",
        "南充市": "511300",
        "眉山市": "511400",
        "宜宾市": "511500",
        "广安市": "511600",
        "达州市": "511700",
        "雅安市": "511800",
        "巴中市": "511900",
        "资阳市": "512000",
        "阿坝藏族羌族自治州": "513200",
        "甘孜藏族自治州": "513300",
        "凉山彝族自治州": "513400",
        "贵阳市": "520100",
        "六盘水市": "520200",
        "遵义市": "520300",
        "安顺市": "520400",
        "黔西南布依族苗族自治州": "522300",
        "黔东南苗族侗族自治州": "522600",
        "黔南布依族苗族自治州": "522700",
        "昆明市": "530100",
        "曲靖市": "530300",
        "玉溪市": "530400",
        "保山市": "530500",
        "昭通市": "530600",
        "丽江市": "530700",
        "普洱市": "530800",
        "临沧市": "530900",
        "楚雄彝族自治州": "532300",
        "红河哈尼族彝族自治州": "532500",
        "文山壮族苗族自治州": "532600",
        "西双版纳傣族自治州": "532800",
        "大理白族自治州": "532900",
        "德宏傣族景颇族自治州": "533100",
        "怒江傈僳族自治州": "533300",
        "迪庆藏族自治州": "533400",
        "拉萨市": "540100",
        "昌都地区": "542100",
        "山南地区": "542200",
        "日喀则地区": "542300",
        "那曲地区": "542400",
        "阿里地区": "542500",
        "林芝地区": "542600",
        "西安市": "610100",
        "铜川市": "610200",
        "宝鸡市": "610300",
        "咸阳市": "610400",
        "渭南市": "610500",
        "延安市": "610600",
        "汉中市": "610700",
        "榆林市": "610800",
        "安康市": "610900",
        "商洛市": "611000",
        "兰州市": "620100",
        "嘉峪关市": "620200",
        "金昌市": "620300",
        "白银市": "620400",
        "天水市": "620500",
        "武威市": "620600",
        "张掖市": "620700",
        "平凉市": "620800",
        "酒泉市": "620900",
        "庆阳市": "621000",
        "定西市": "621100",
        "陇南市": "621200",
        "临夏回族自治州": "622900",
        "甘南藏族自治州": "623000",
        "西宁市": "630100",
        "海东地区": "632100",
        "海北藏族自治州": "632200",
        "黄南藏族自治州": "632300",
        "海南藏族自治州": "632500",
        "果洛藏族自治州": "632600",
        "玉树藏族自治州": "632700",
        "海西蒙古族藏族自治州": "632800",
        "银川市": "640100",
        "石嘴山市": "640200",
        "吴忠市": "640300",
        "固原市": "640400",
        "中卫市": "640500",
        "乌鲁木齐市": "650100",
        "克拉玛依市": "650200",
        "吐鲁番地区": "652100",
        "哈密地区": "652200",
        "昌吉回族自治州": "652300",
        "博尔塔拉蒙古自治州": "652700",
        "巴音郭楞蒙古自治州": "652800",
        "阿克苏地区": "652900",
        "克孜勒苏柯尔克孜自治州": "653000",
        "喀什地区": "653100",
        "和田地区": "653200",
        "伊犁哈萨克自治州": "654000",
        "塔城地区": "654200",
        "阿勒泰地区": "654300",
        "自治区直辖县级行政区划": "659000",
        "台湾省": "710000",
        "香港特别行政区": "810100",
        "澳门特别行政区": "820000"
    },
    provincesCode1: {
        //23个省
        "台湾": "710000",
        "河北省": "130000",
        "山西省": "140000",
        "辽宁省": "210000",
        "吉林省": "220000",
        "黑龙江省": "230000",
        "江苏省": "320000",
        "浙江省": "330000",
        "安徽省": "340000",
        "福建省": "350000",
        "江西省": "360000",
        "山东省": "370000",
        "河南省": "410000",
        "湖北省": "420000",
        "湖南省": "430000",
        "广东省": "440000",
        "海南省": "460000",
        "四川省": "510000",
        "贵州省": "520000",
        "云南省": "530000",
        "陕西省": "610000",
        "甘肃省": "620000",
        "青海省": "630000",
        //5个自治区
        "新疆维吾尔自治区": "650000",
        "广西壮族自治区": "450000",
        "内蒙古自治区": "150000",
        "宁夏回族自治区": "640000",
        "西藏自治区": "540000",
        //4个直辖市
        "北京市": "110000",
        "天津市": "120000",
        "上海市": "310000",
        "重庆市": "500000",
        //2个特别行政区
        "香港": "810000",
        "澳门": "820000"
    },
    provincesMap1: {
        taiwan: '台湾',
        hebei: '河北省',
        shanxi: '山西省',
        liaoning: '辽宁省',
        jilin: '吉林省',
        heilongjiang: '黑龙江省',
        jiangsu: '江苏省',
        zhejiang: '浙江省',
        anhui: '安徽省',
        fujian: '福建省',
        jiangxi: '江西省',
        shandong: '山东省',
        henan: '河南省',
        hubei: '湖北省',
        hunan: '湖南省',
        guangdong: '广东省',
        hainan: '海南省',
        sichuan: '四川省',
        guizhou: '贵州省',
        yunnan: '云南省',
        shanxi1: '陕西省',
        gansu: '甘肃省',
        qinghai: '青海省',
        xinjiang: '新疆维吾尔自治区',
        guangxi: '广西壮族自治区',
        neimenggu: '内蒙古自治区',
        ningxia: '宁夏回族自治区',
        xizang: '西藏自治区',
        beijing: '北京市',
        tianjin: '天津市',
        shanghai: '上海市',
        chongqing: '重庆',
        xianggang: '香港',
        aomen: '澳门'
    },
    areaMap1: {
        '110100': '北京市',
        '120100': '天津市',
        '130100': '石家庄市',
        '130200': '唐山市',
        '130300': '秦皇岛市',
        '130400': '邯郸市',
        '130500': '邢台市',
        '130600': '保定市',
        '130700': '张家口市',
        '130800': '承德市',
        '130900': '沧州市',
        '131000': '廊坊市',
        '131100': '衡水市',
        '140100': '太原市',
        '140200': '大同市',
        '140300': '阳泉市',
        '140400': '长治市',
        '140500': '晋城市',
        '140600': '朔州市',
        '140700': '晋中市',
        '140800': '运城市',
        '140900': '忻州市',
        '141000': '临汾市',
        '141100': '吕梁市',
        '150100': '呼和浩特市',
        '150200': '包头市',
        '150300': '乌海市',
        '150400': '赤峰市',
        '150500': '通辽市',
        '150600': '鄂尔多斯市',
        '150700': '呼伦贝尔市',
        '150800': '巴彦淖尔市',
        '150900': '乌兰察布市',
        '152200': '兴安盟',
        '152500': '锡林郭勒盟',
        '152900': '阿拉善盟',
        '210100': '沈阳市',
        '210200': '大连市',
        '210300': '鞍山市',
        '210400': '抚顺市',
        '210500': '本溪市',
        '210600': '丹东市',
        '210700': '锦州市',
        '210800': '营口市',
        '210900': '阜新市',
        '211000': '辽阳市',
        '211100': '盘锦市',
        '211200': '铁岭市',
        '211300': '朝阳市',
        '211400': '葫芦岛市',
        '220100': '长春市',
        '220200': '吉林市',
        '220300': '四平市',
        '220400': '辽源市',
        '220500': '通化市',
        '220600': '白山市',
        '220700': '松原市',
        '220800': '白城市',
        '222400': '延边朝鲜族自治州',
        '230100': '哈尔滨市',
        '230200': '齐齐哈尔市',
        '230300': '鸡西市',
        '230400': '鹤岗市',
        '230500': '双鸭山市',
        '230600': '大庆市',
        '230700': '伊春市',
        '230800': '佳木斯市',
        '230900': '七台河市',
        '231000': '牡丹江市',
        '231100': '黑河市',
        '231200': '绥化市',
        '232700': '大兴安岭地区',
        '310100': '上海市',
        '310200': '崇明县',
        '320100': '南京市',
        '320200': '无锡市',
        '320300': '徐州市',
        '320400': '常州市',
        '320500': '苏州市',
        '320600': '南通市',
        '320700': '连云港市',
        '320800': '淮安市',
        '320900': '盐城市',
        '321000': '扬州市',
        '321100': '镇江市',
        '321200': '泰州市',
        '321300': '宿迁市',
        '330100': '杭州市',
        '330200': '宁波市',
        '330300': '温州市',
        '330400': '嘉兴市',
        '330500': '湖州市',
        '330600': '绍兴市',
        '330700': '金华市',
        '330800': '衢州市',
        '330900': '舟山市',
        '331000': '台州市',
        '331100': '丽水市',
        '340100': '合肥市',
        '340200': '芜湖市',
        '340300': '蚌埠市',
        '340400': '淮南市',
        '340500': '马鞍山市',
        '340600': '淮北市',
        '340700': '铜陵市',
        '340800': '安庆市',
        '341000': '黄山市',
        '341100': '滁州市',
        '341200': '阜阳市',
        '341300': '宿州市',
        '341500': '六安市',
        '341600': '亳州市',
        '341700': '池州市',
        '341800': '宣城市',
        '350100': '福州市',
        '350200': '厦门市',
        '350300': '莆田市',
        '350400': '三明市',
        '350500': '泉州市',
        '350600': '漳州市',
        '350700': '南平市',
        '350800': '龙岩市',
        '350900': '宁德市',
        '360100': '南昌市',
        '360200': '景德镇市',
        '360300': '萍乡市',
        '360400': '九江市',
        '360500': '新余市',
        '360600': '鹰潭市',
        '360700': '赣州市',
        '360800': '吉安市',
        '360900': '宜春市',
        '361000': '抚州市',
        '361100': '上饶市',
        '370100': '济南市',
        '370200': '青岛市',
        '370300': '淄博市',
        '370400': '枣庄市',
        '370500': '东营市',
        '370600': '烟台市',
        '370700': '潍坊市',
        '370800': '济宁市',
        '370900': '泰安市',
        '371000': '威海市',
        '371100': '日照市',
        '371200': '莱芜市',
        '371300': '临沂市',
        '371400': '德州市',
        '371500': '聊城市',
        '371600': '滨州市',
        '371700': '菏泽市',
        '410100': '郑州市',
        '410200': '开封市',
        '410300': '洛阳市',
        '410400': '平顶山市',
        '410500': '安阳市',
        '410600': '鹤壁市',
        '410700': '新乡市',
        '410800': '焦作市',
        '410900': '濮阳市',
        '411000': '许昌市',
        '411100': '漯河市',
        '411200': '三门峡市',
        '411300': '南阳市',
        '411400': '商丘市',
        '411500': '信阳市',
        '411600': '周口市',
        '411700': '驻马店市',
        '420100': '武汉市',
        '420200': '黄石市',
        '420300': '十堰市',
        '420500': '宜昌市',
        '420600': '襄阳市',
        '420700': '鄂州市',
        '420800': '荆门市',
        '420900': '孝感市',
        '421000': '荆州市',
        '421100': '黄冈市',
        '421200': '咸宁市',
        '421300': '随州市',
        '422800': '恩施土家族苗族自治州',
        '429000': '湖北省直辖县市',
        '430100': '长沙市',
        '430200': '株洲市',
        '430300': '湘潭市',
        '430400': '衡阳市',
        '430500': '邵阳市',
        '430600': '岳阳市',
        '430700': '常德市',
        '430800': '张家界市',
        '430900': '益阳市',
        '431000': '郴州市',
        '431100': '永州市',
        '431200': '怀化市',
        '431300': '娄底市',
        '433100': '湘西土家族苗族自治州',
        '440100': '广州市',
        '440200': '韶关市',
        '440300': '深圳市',
        '440400': '珠海市',
        '440500': '汕头市',
        '440600': '佛山市',
        '440700': '江门市',
        '440800': '湛江市',
        '440900': '茂名市',
        '441200': '肇庆市',
        '441300': '惠州市',
        '441400': '梅州市',
        '441500': '汕尾市',
        '441600': '河源市',
        '441700': '阳江市',
        '441800': '清远市',
        '441900': '东莞市',
        '442000': '中山市',
        '445100': '潮州市',
        '445200': '揭阳市',
        '445300': '云浮市',
        '450100': '南宁市',
        '450200': '柳州市',
        '450300': '桂林市',
        '450400': '梧州市',
        '450500': '北海市',
        '450600': '防城港市',
        '450700': '钦州市',
        '450800': '贵港市',
        '450900': '玉林市',
        '451000': '百色市',
        '451100': '贺州市',
        '451200': '河池市',
        '451300': '来宾市',
        '451400': '崇左市',
        '460100': '海口市',
        '460200': '三亚市',
        '460300': '三沙市',
        '469000': '省直辖县级行政区划',
        '500100': '重庆市',
        '510100': '成都市',
        '510300': '自贡市',
        '510400': '攀枝花市',
        '510500': '泸州市',
        '510600': '德阳市',
        '510700': '绵阳市',
        '510800': '广元市',
        '510900': '遂宁市',
        '511000': '内江市',
        '511100': '乐山市',
        '511300': '南充市',
        '511400': '眉山市',
        '511500': '宜宾市',
        '511600': '广安市',
        '511700': '达州市',
        '511800': '雅安市',
        '511900': '巴中市',
        '512000': '资阳市',
        '513200': '阿坝藏族羌族自治州',
        '513300': '甘孜藏族自治州',
        '513400': '凉山彝族自治州',
        '520100': '贵阳市',
        '520200': '六盘水市',
        '520300': '遵义市',
        '520400': '安顺市',
        '522200': '铜仁市',
        '522300': '黔西南布依族苗族自治州',
        '522400': '毕节市',
        '522600': '黔东南苗族侗族自治州',
        '522700': '黔南布依族苗族自治州',
        '530100': '昆明市',
        '530300': '曲靖市',
        '530400': '玉溪市',
        '530500': '保山市',
        '530600': '昭通市',
        '530700': '丽江市',
        '530800': '普洱市',
        '530900': '临沧市',
        '532300': '楚雄彝族自治州',
        '532500': '红河哈尼族彝族自治州',
        '532600': '文山壮族苗族自治州',
        '532800': '西双版纳傣族自治州',
        '532900': '大理白族自治州',
        '533100': '德宏傣族景颇族自治州',
        '533300': '怒江傈僳族自治州',
        '533400': '迪庆藏族自治州',
        '540100': '拉萨市',
        '542100': '昌都地区',
        '542200': '山南地区',
        '542300': '日喀则地区',
        '542400': '那曲地区',
        '542500': '阿里地区',
        '542600': '林芝地区',
        '610100': '西安市',
        '610200': '铜川市',
        '610300': '宝鸡市',
        '610400': '咸阳市',
        '610500': '渭南市',
        '610600': '延安市',
        '610700': '汉中市',
        '610800': '榆林市',
        '610900': '安康市',
        '611000': '商洛市',
        '620100': '兰州市',
        '620200': '嘉峪关市',
        '620300': '金昌市',
        '620400': '白银市',
        '620500': '天水市',
        '620600': '武威市',
        '620700': '张掖市',
        '620800': '平凉市',
        '620900': '酒泉市',
        '621000': '庆阳市',
        '621100': '定西市',
        '621200': '陇南市',
        '622900': '临夏回族自治州',
        '623000': '甘南藏族自治州',
        '630100': '西宁市',
        '632100': '海东地区',
        '632200': '海北藏族自治州',
        '632300': '黄南藏族自治州',
        '632500': '海南藏族自治州',
        '632600': '果洛藏族自治州',
        '632700': '玉树藏族自治州',
        '632800': '海西蒙古族藏族自治州',
        '640100': '银川市',
        '640200': '石嘴山市',
        '640300': '吴忠市',
        '640400': '固原市',
        '640500': '中卫市',
        '650100': '乌鲁木齐市',
        '650200': '克拉玛依市',
        '652100': '吐鲁番地区',
        '652200': '哈密地区',
        '652300': '昌吉回族自治州',
        '652700': '博尔塔拉蒙古自治州',
        '652800': '巴音郭楞蒙古自治州',
        '652900': '阿克苏地区',
        '653000': '克孜勒苏柯尔克孜自治州',
        '653100': '喀什地区',
        '653200': '和田地区',
        '654000': '伊犁哈萨克自治州',
        '654200': '塔城地区',
        '654300': '阿勒泰地区',
        '659000': '自治区直辖县级行政区划',
        '710000': '台湾省',
        '810100': '香港特别行政区',
        '820000': '澳门特别行政区',


    }
};
