<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		<!-- <el-descriptions class="margin-top" title="带边框列表" :column="2" border>
			<template slot="extra">
				<el-button type="primary" size="small">操作</el-button>
			</template>
			<el-descriptions-item>
				<template slot="label">
					<i class="el-icon-user"></i>
					用户名
				</template>
				kooriookami
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="el-icon-mobile-phone"></i>
					手机号
				</template>
				18100000000
			</el-descriptions-item>
			<el-descriptions-item span="2">
				<template slot="label">
					<i class="el-icon-location-outline"></i>
					居住地
				</template>
				苏州市
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="el-icon-tickets"></i>
					备注
				</template>
				<el-tag size="small">学校</el-tag>
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="el-icon-office-building"></i>
					联系地址
				</template>
				江苏省苏州市吴中区吴中大道 1188 号
			</el-descriptions-item>
		</el-descriptions> -->
		<el-card class="box-card order-list">
			<div slot="header" class="clearfix">
				<span>商品列表</span>

				<el-button style="margin-left: 15px;" type="primary" size="small " @click="addBtn">新增</el-button>
			</div>
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item >
					<el-input v-model="formInline.goodsname" placeholder="商品名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small " @click="onSubmit">查询</el-button>
				</el-form-item>
			</el-form>
			<div v-loading="loading">
				<el-table :data="orderList" border style="width: 100%">
					<el-table-column label="商品名称" prop="goods_name" width="150">

					</el-table-column>
					<el-table-column label="产品类别" width="150">
						<template slot-scope="scope">
							{{orderList[scope.$index].one_category_name}}/{{orderList[scope.$index].two_category_name}}
						</template>
					</el-table-column>
					<el-table-column prop="expire_data" label="有效期" width="100">
					</el-table-column>
					<el-table-column prop="address" label="生产地" width="120">
					</el-table-column>
					<el-table-column prop="brand" label="品牌" width="120">
					</el-table-column>
					<el-table-column prop="js_supply" label="即时供给能力" width="120">
					</el-table-column>
					<el-table-column prop="year_supply" label="年供给量" width="120">
					</el-table-column>
					<el-table-column label="是否显示" width="120">
						<template slot-scope="scope">
							<el-switch :value="orderList[scope.$index].status==1?true:false" active-color="#11773a"
								@change="changeSwitch($event,scope.row.id,scope.$index)">
							</el-switch>
						</template>

					</el-table-column>
					<el-table-column fixed="right" label="操作" width="100">
						<template slot-scope="scope">

							<el-button type="text" size="small" @click="handleClick(scope.row)">编辑</el-button>
							<el-button @click="deleteClick(scope.row)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="pager" style="margin-top: 20px;">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
					:current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange"
					@current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
			</div>
		</el-card>
		<el-dialog title="商品详情" :visible.sync="addLogistics" width="80%">
			<el-form :model="formData" :rules="formrules" ref="ruleFormData" label-width="120px">
				<el-form-item label="商品名称" prop="goods_name">
					<el-input v-model="formData.goods_name" placeholder="请输入商品名称" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="商品图片" prop="goods_image">
					<el-upload list-type="picture-card" :action="uploadActionUrl" :limit="9"
						:on-success="handleHjSuccess" :file-list="hjList" :on-remove="handleHjRemove" class="upload"
						multiple>
						<i class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="产品类别" prop="category_id">
					<!-- <el-select v-model="formData.category_id" placeholder="请选择产品类别" @change="categoryChange">
						<el-option v-for="item in categoryList" :key="item.class_id" :label="item.class_name"
							:value="item.class_id">
						</el-option>
					</el-select> -->
					<el-cascader v-model="formData.category_id" :options="categoryList"
						:props="{ expandTrigger: 'hover',children:'two',label:'class_name',value:'class_id' }"
						@change="categoryChange"></el-cascader>
				</el-form-item>
				<el-form-item label="生产许可证号">
					<el-input v-model="formData.number" placeholder="请输入生产许可证号" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="产品规格">
					<el-input v-model="formData.product_spec" placeholder="请输入产品规格" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="包装规格">
					<el-input v-model="formData.package_spec" placeholder="请输入包装规格" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="有效期">
					<el-input v-model="formData.expire_data" placeholder="请输入有效期" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="即时供给能力">
					<el-input type="number" v-model="formData.js_supply"  placeholder="请输入即时供给能力" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="年供给量">
					<el-input type="number" v-model="formData.year_supply"  placeholder="请输入年供给量" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="生产地">
					<el-input v-model="formData.address" placeholder="请输入生产地" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="执行标准">
					<el-input v-model="formData.zxbz" placeholder="请输入执行标准" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="品牌">
					<el-input v-model="formData.brand" placeholder="请输入品牌" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="药监局备案证号">
					<el-input v-model="formData.yj_number" placeholder="请输入药监局备案证号" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="备案人组织机构代码">
					<el-input v-model="formData.filings_code" placeholder="请输入备案人组织机构代码"
						class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="商品详情">
					<!-- <el-input v-model="formData.content" placeholder="请输入商品详情" class="ns-len-input"></el-input> -->
					<div style="border: 1px solid #ccc;">
						<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
							:mode="mode" />
						<Editor style="height: 500px; overflow-y: hidden;" v-model="formData.content"
							:defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
					</div>
				</el-form-item>
				<el-form-item label="标准号">
					<el-input v-model="formData.bz_number" placeholder="请输入标准号" class="ns-len-input"></el-input>
				</el-form-item>

				<el-form-item label="仓储地址">
					<el-input v-model="formData.house_address" placeholder="请输入仓储地址" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="批准文号">
					<el-input v-model="formData.approve_nuber" placeholder="请输入批准文号" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="卫生标准">
					<el-input v-model="formData.bz_health" placeholder="请输入卫生标准" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="环保标识">
					<el-input v-model="formData.hb_logo" placeholder="请输入环保标识" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="有机标识">
					<el-radio-group v-model="formData.organic_logo">
						<el-radio :label="1">有</el-radio>
						<el-radio :label="0">无</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="产品等级">
					<el-input v-model="formData.level" placeholder="请输入产品等级" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="包装内">
					<el-input v-model="formData.inside_package" placeholder="请输入包装内" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="包装外">
					<el-input v-model="formData.outside_package" placeholder="请输入包装外" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="外包装数量单位">
					<el-input v-model="formData.package_unit" placeholder="请输入外包装数量单位" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="外包装长*宽*高">
					<el-input v-model="formData.package_area" placeholder="请输入外包装长*宽*高" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="24小时内最大供应量">
					<el-input type="number" v-model="formData.twenty_four_supply"  placeholder="请输入24小时内最大供应量"
						class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="72小时内最大供应量">
					<el-input type="number" v-model="formData.seventy_two_supply"  placeholder="请输入72小时内最大供应量"
						class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="240小时内最大供应量">
					<el-input  type="number" v-model="formData.two_hundred_forty_supply"  placeholder="请输入240小时内最大供应量"
						class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="medium" @click="saveInfo('ruleFormData')">保存</el-button>
				</el-form-item>
			</el-form>

		</el-dialog>
	</div>
</template>

<script>
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	// import { DomEditor } from '@wangeditor/editor'

	import {
		mapGetters
	} from 'vuex';
	import Config from '@/utils/config';
	import {
		my_goods,
		add_goods,
		goodsCategory,
		modifyGoodsState,
		delGoods
	} from '@/api/supply/supply';

	export default {
		name: 'supplymemberMygoods',
		components: {
			Editor,
			Toolbar
		},
		data() {
			let self = this
			var isMobile = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("手机号不能为空"))
				} else {
					const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

					if (reg.test(value)) {
						callback()
					} else {
						callback(new Error("请输入正确的手机号"))
					}
				}
			}
			return {
				editor: null,
				html: '<p>hello</p>',
				toolbarConfig: {
					excludeKeys: [
						'group-video'
					]
				},
				editorConfig: {
					placeholder: '请输入内容...',
					MENU_CONF: {
						uploadImage: {
							server: Config.baseUrl + '/companyapi/Company/images',
							fieldName: "file"
						}
					}
				},
				mode: 'default', // or 'simple'
				uploadActionUrl: Config.baseUrl + '/companyapi/Company/image',
				loading: true,
				addLogistics: false,
				orderList: [],
				currentPage: 1,
				pageSize: 10,
				total: 0,
				yes: true,
				formData: {
					goods_name: "",
					category_id: "",
					one_category_name: "",
					one_category_id: "",
					two_category_id: "",
					two_category_name: "",
					number: "",
					product_spec: "",
					goods_image: "",
					package_spec: "",
					expire_data: "",
					js_supply: "",
					year_supply: "",
					address: "",
					zxbz: "",
					content: "",
					brand: "",
					yj_number: "",
					filings_code: '',
					bz_number: "",
					house_address: "",
					approve_nuber: "",
					bz_health: "",
					hb_logo: "",
					organic_logo: "",
					level: "",
					inside_package: "",
					outside_package: "",
					package_unit: "",
					package_area: "",
					twenty_four_supply: "",
					seventy_two_supply: "",
					two_hundred_forty_supply: "",
				},
				formrules: {
					goods_name: [{
						required: true,
						message: "请输入商品名称",
						trigger: "blur"
					}],
					category_id: [{
						required: true,
						message: "请选择产品类别",
						trigger: "blur"
					}],
					goods_image: [{
						required: true,
						message: "请上传商品图片",
						trigger: "blur"
					}]
					// phone: [{
					// 	required: true,
					// 	validator: isMobile,
					// 	trigger: "blur"
					// }],
				},
				categoryList: [],
				hjList: [],
				formInline: {
					goodsname: ""
				}
			};
		},
		created() {
			this.getGoodsCategory()
			this.getOrderList();

		},
		computed: {
			...mapGetters(['defaultGoodsImage']),

		},
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		},
		mounted() {
			let self = this;

			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		methods: {
			onCreated(editor) {
				this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
			},
			getGoodsCategory() {
				goodsCategory().then(res => {
						let data = res.data
						if (data != null) {
							this.categoryList = data
						}
					})
					.catch(err => {})
			},
			categoryChange(e) {
				this.categoryList.forEach(item => {
					if (item.class_id == e[0]) {
						this.formData.one_category_name = item.class_name
						item.two.forEach(it => {
							if (it.class_id == e[1]) {
								this.formData.two_category_name = it.class_name
							}
						})
					}
				})
				console.log(e, this.formData)
			},
			handleHjRemove(file, fileList) {
				this.hjList = fileList.map(item => {
					return {
						url: this.$img(item.response.data.pic_path)
					}
				})
				let data = fileList.map(item => {
					return item.response.data.pic_path
				})
				this.formData.goods_image = data.join(',')
			},
			handleHjSuccess(res, file, fileList) {
				this.hjList.push({
					url: this.$img(res.data.pic_path)
				})
				let data = fileList.map(item => {
					console.log(item, 123)
					if (item.response) {
						return item.response.data.pic_path
					} else {
						return item.url
					}

				})
				this.formData.goods_image = data.join(',')
			},
			//删除
			deleteClick(data) {
				this.$confirm("您确定要删除吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					delGoods({
						goods_id: data.id
					}).then(res => {
						this.$message({
							message: "删除成功",
							type: "success"
						})
						this.refresh()
						typeof callback == "function" && callback()
					})
				})
			},
			//编辑
			handleClick(data) {
				this.formData = data
				this.formData.category_id = [this.formData.one_category_id, this.formData.two_category_id]
				if (this.formData.goods_image) {
					this.hjList = data.goods_image.split(",").map(item => {
						return {
							url: this.$img(item)
						}
					})
				} else {
					this.hjList = []
				}
				this.addLogistics = true
			},
			//修改状态
			changeSwitch(e, goods_id, index) {
				console.log(e, goods_id)
				modifyGoodsState({
						goods_id,
						status: e ? '1' : '0'
					})
					.then(res => {
						if (res.code == 0) {
							this.$message({
								message: "修改成功！",
								type: "success"
							})
							this.refresh()
						} else {
							this.$message({
								message: res.message,
								type: "warning"
							})
						}
					})
					.catch(err => {
						this.flag = false
						this.$message.error(err.message)
					})
			},
			saveInfo(formName) {
				this.$refs[formName].validate(valid => {
					console.log(valid)
					if (valid) {
						var data = {
							goods_name: this.formData.goods_name,
							one_category_id: this.formData.category_id[0],
							one_category_name: this.formData.one_category_name,
							two_category_id: this.formData.category_id[1],
							two_category_name: this.formData.two_category_name,
							number: this.formData.number,
							product_spec: this.formData.product_spec,
							goods_image: this.formData.goods_image,
							package_spec: this.formData.package_spec,
							expire_data: this.formData.expire_data,
							js_supply: this.formData.js_supply,
							year_supply: this.formData.year_supply,
							address: this.formData.address,
							zxbz: this.formData.zxbz,
							content: this.formData.content,
							brand: this.formData.brand,
							yj_number: this.formData.yj_number,
							filings_code: this.formData.filings_code,
							bz_number: this.formData.bz_number,
							house_address: this.formData.house_address,
							approve_nuber: this.formData.approve_nuber,
							bz_health: this.formData.bz_health,
							hb_logo: this.formData.hb_logo,
							organic_logo: this.formData.organic_logo,
							level: this.formData.level,
							inside_package: this.formData.inside_package,
							outside_package: this.formData.outside_package,
							package_unit: this.formData.package_unit,
							package_area: this.formData.package_area,
							twenty_four_supply: this.formData.twenty_four_supply,
							seventy_two_supply: this.formData.seventy_two_supply,
							two_hundred_forty_supply: this.formData.two_hundred_forty_supply,
						}
						if (this.formData.id) {
							data.id = this.formData.id
						} else {
							data.id = 0
						}
						if (this.flag) return
						this.flag = true

						add_goods(data)
							.then(res => {
								if (res.code == 0) {
									this.flag = false
									this.$message({
										message: "提交成功！",
										type: "success"
									})
									this.currentPage = 1
									this.refresh()
									this.addLogistics = false
								} else {
									this.flag = false
									this.$message({
										message: res.message,
										type: "warning"
									})
								}
							})
							.catch(err => {
								this.flag = false
								this.$message.error(err.message)
							})
					} else {
						return false
					}
				})
			},

			addBtn() {
				this.formData = {
					goods_name: "",
					category_id: "",
					one_category_name: "",
					one_category_id: "",
					two_category_id: "",
					two_category_name: "",
					number: "",
					product_spec: "",
					goods_image: "",
					package_spec: "",
					expire_data: "",
					js_supply: "",
					year_supply: "",
					address: "",
					zxbz: "",
					content: "",
					brand: "",
					yj_number: "",
					filings_code: '',
					bz_number: "",
					house_address: "",
					approve_nuber: "",
					bz_health: "",
					hb_logo: "",
					organic_logo: "",
					level: "",
					inside_package: "",
					outside_package: "",
					package_unit: "",
					package_area: "",
					twenty_four_supply: "",
					seventy_two_supply: "",
					two_hundred_forty_supply: "",
				}
				this.hjList = []
				this.addLogistics = true
				// const toolbar = DomEditor.getToolbar(this.editor)
				// console.log(toolbar.getConfig().toolbarKeys)
			},
			onSubmit() {
				this.currentPage = 1;
				this.refresh();
			},
			getOrderList() {
				my_goods({
						page: this.currentPage,
						page_size: this.pageSize,
						goods_name: this.formInline.goodsname
					})
					.then(res => {
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						this.orderList = list;
						this.loading = false;
					})
					.catch(res => {
						this.loading = false;
					});
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			refresh() {
				this.loading = true;
				this.getOrderList();
			},

			imageError(orderIndex, goodsIndex) {
				this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage;
			}
		}
	};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #11773a;
	}

	/deep/.el-radio__input.is-checked .el-radio__inner {
		border-color: #11773a;
		background: #11773a;
	}

	/deep/.el-radio__inner:hover {
		border-color: #11773a;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-form-item.is-error .el-input__inner {
		border-color: #11773a;
	}

	/deep/.el-form-item .el-input__inner:focus {
		border-color: #11773a;
	}

	/deep/.el-textarea__inner:focus {
		border-color: #11773a;
	}

	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}

	/deep/.info_wrap .el-form-item__label {
		line-height: 20px;
	}

	/deep/.el-tabs__item:hover {
		color: #11773a;
	}

	/deep/.el-upload--picture-card:hover {
		border-color: #11773a;
	}

	/deep/.el-upload--picture-card:focus {
		border-color: #11773a;
	}

	/deep/.el-range-editor.is-active {
		border-color: #11773a;
	}

	/deep/.el-tabs__item.is-active {
		color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #fff !important;
	}

	/deep/.el-pager .number:hover {
		color: #11773a !important;
	}
</style>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.el-button--primary {
		background-color: $base-supply-color;
		border-color: $base-supply-color;
	}

	.order-list {
		.el-button--primary {
			background-color: $base-supply-color;
			border-color: $base-supply-color;
		}

		.el-button--text {
			color: $base-supply-color;
		}

		nav {
			overflow: hidden;
			padding: 10px 0;
			background: #fff;
			margin-bottom: 10px;
			border-bottom: 1px solid #eeeeee;

			li {
				float: left;

				&:nth-child(1) {
					width: 45%;
				}

				&:nth-child(2) {
					width: 10%;
				}

				&:nth-child(3) {
					width: 10%;
				}

				&:nth-child(4) {
					width: 10%;
				}

				&:nth-child(5) {
					width: 15%;
				}

				&:nth-child(6) {
					width: 10%;
				}
			}
		}

		.list {
			.item {
				margin-bottom: 20px;
				border: 1px solid #eeeeee;
				border-top: 0;

				.head {
					padding: 8px 10px;
					background: #f7f7f7;
					font-size: 12px;

					.create-time {
						margin-right: 10px;
					}

					border-bottom: 1px solid #eeeeee;

					a {
						margin: 0 10px 0 20px;
					}

					.order-type {
						margin-left: 30px;
					}
				}
			}

			ul {
				background-color: #fff;
				padding: 10px;
				overflow: hidden;

				li {
					float: left;
					line-height: 60px;

					&:nth-child(1) {
						width: 45%;
						line-height: inherit;

						.img-wrap {
							width: 60px;
							height: 60px;
							float: left;
							margin-right: 10px;
							cursor: pointer;
						}

						.info-wrap {
							margin-left: 70px;

							h5 {
								font-weight: normal;
								font-size: $ns-font-size-base;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
								display: inline-block;
								cursor: pointer;

								&:hover {
									color: $base-color;
								}
							}

							span {
								font-size: $ns-font-size-sm;
								color: #9a9a9a;
							}
						}
					}

					&:nth-child(2) {
						width: 10%;
					}

					&:nth-child(3) {
						width: 10%;
					}

					&:nth-child(4) {
						width: 10%;
					}

					&:nth-child(5) {
						width: 15%;
						line-height: 30px;

						a {
							display: block;
						}
					}

					&:nth-child(6) {
						width: 10%;
						line-height: initial;

						button {
							margin-left: 0;
							margin-bottom: 10px;

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		.empty-wrap {
			text-align: center;
			padding: 10px;
		}
	}
</style>