<template>
	<div class="container">
		<!-- 广告图 -->
		<div class="banner">
			<div class="main">
				<div class="breadcrumb-text">
					<h2>应急保障储备社会化数字协同平台之</h2>
					<p>Socialized Digital Collaborative Platform for emergency security reserve</p>
					<h2 class="mt-10">公共采购</h2>
					<p>Public procurement</p>
				</div>
			</div>
		</div>
		<div class="scfl">
			<div class="main">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/supplyView/zyjc' }">公共采购</el-breadcrumb-item>
					<el-breadcrumb-item>详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main_wrap">
			<div class="main">
				<div class="detail_wrap" v-loading="loading" v-if="detailData">
					<div class="detail_title">{{detailData.title}}</div>
					<div class="detail_date">
						发布时间：{{detailData.create_time}}
					</div>
					<div class="des_wrap">
						<el-descriptions :column="2" border>
							<el-descriptions-item :span="1" label-class-name="my-label" content-class-name="my-content"
								label="联系人:">
								{{detailData.buy_contacts}}
							</el-descriptions-item>
							<el-descriptions-item :span="1" label-class-name="my-label" content-class-name="my-content"
								label="联系电话:">
								{{detailData.buy_mobile}}
							</el-descriptions-item>
						</el-descriptions>
					</div>
					<div class="detail_article">
						<div class="detail_content" v-html="detailData.content">

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		bidding_info
	} from '@/api/supply/supply';
	export default {
		name: "supplyZyjcDetail",
		components: {},
		data: () => {
			return {
				detailData: null,
				loading: true,
			};
		},
		created() {
			this.getDetail();
		},
		methods: {
			getDetail() {
				bidding_info({
						bidding_id: this.$route.query.id
					})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.detailData = res.data
						}
						this.loading = false;
					})
					.catch(res => {
						this.loading = false;
					});
			},
		}
	};
</script>
<style scoped>
	/deep/.nowidth .el-form-item__content {
		width: 360px;
	}

	.el-select {
		width: 120px;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}

	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #fff !important;
	}

	/deep/.el-pager .number:hover {
		color: #11773a !important;
	}

	/deep/.my-label {
		font-size: 14px;
		font-weight: bold !important;
		color: #707070;
		background-color: #F4F4F9;
		width: 18%;
	}

	/deep/.goodsname {
		margin-top: 20px;
	}

	/deep/.my-content {
		font-size: 16px;
		color: #333;
	}
</style>
<style scoped lang="scss">
	.el-button--primary {
		background-color: #57c5a5;
		border-color: #57c5a5;
	}

	.container {
		.banner {
			background: url('../../../assets/images/supply/cpk.png') no-repeat;
			background-size: 100% 100%;
			position: relative;
			z-index: 1;
			width: 100%;
			height: 300px;
			margin-top: 80px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.breadcrumb-text {
					height: 300px;
					position: relative;
					z-index: 1;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h2 {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 36px;
					}

					p {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 18px;
					}
				}

			}

		}

		.scfl {
			padding-top: 30px;



			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;
				padding-bottom: 10px;
				border-bottom: 1px #c1c1c1 solid;
			}
		}

		.main_wrap {
			padding-bottom: 50px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.detail_wrap {
					.detail_title {
						font-size: 24px;
						color: rgb(29, 29, 29);
						text-align: center;
						padding: 40px 50px 15px;
					}

					.detail_date {
						text-align: center;
						margin-bottom: 30px;
						font-size: 14px;
						color: #707070;
					}

					.des_wrap {}

					.detail_article {
						margin-top: 50px;
						min-height: 300px;
						padding: 5%;
						border: 1px solid #F1F1F1;
						background: #fcfcfc;
						box-shadow: 1px 2px 10px #ccc;
						-moz-box-shadow: 1px 2px 10px #ccc;
						-webkit-box-shadow: 1px 2px 6px #ccc;
					}
				}
			}
		}
	}

	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
</style>