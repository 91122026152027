<template>
	<div class="container">
		<!-- 广告图 -->
		<div class="banner">
			<div class="main">
				<div class="breadcrumb-text">
					<h2>应急保障储备社会化数字协同平台之</h2>
					<p>Socialized Digital Collaborative Platform for emergency security reserve</p>
					<h2 class="mt-10">物资寻源</h2>
					<p>Material Sourcing</p>
				</div>
			</div>
		</div>
		<div class="scfl">
			<div class="main">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>物资寻源</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main_wrap">
			<div class="main">
				<div class="main_left">
					<div class="search_wrap">
						<el-form :inline="true" :model="formData" class="demo-form-inline">
							<el-form-item class="first">
								<div class="label">
									物资寻源
								</div>
								<el-input v-model="formData.keyword" placeholder="关键词"></el-input>
								<el-button type="primary" size="medium " class="kong_btn" icon="el-icon-search"
									@click="search">搜索</el-button>
								<!-- <el-button type="primary" size="medium " class="kong_btn" icon="el-icon-place"
									@click="search">空间定位</el-button> -->
							</el-form-item>
							<el-form-item class="nowidth">
								<el-select v-model="formData.industry_id" placeholder="请选择行业" @change="industryChange">
									<el-option v-for="item in industryList" :key="item.class_id"
										:label="item.class_name" :value="item.class_id">
									</el-option>
								</el-select>
							</el-form-item>
							<!-- <el-form-item class="nowidth">
								<el-select v-model="formData.zczb_id" placeholder="请选择注册资本" @change="zczbChange">
									<el-option v-for="item in zczbList" :key="item.class_id" :label="item.class_name"
										:value="item.class_id">
									</el-option>
								</el-select>
							</el-form-item> -->
							<!-- <el-form-item class="nowidth">
								<el-select v-model="formData.jynx_id" placeholder="请选择经营年限" @change="jynxChange">
									<el-option v-for="item in jynxList" :key="item.class_id" :label="item.class_name"
										:value="item.class_id">
									</el-option>
								</el-select>

							</el-form-item> -->
							<el-form-item class="nowidth">
								<el-select v-model="formData.province_name" placeholder="请选择地区"
									@change="changeProvice(formData.province_name)">
									<el-option v-for="option in province" :key="option.id" :label="option.name"
										:value="option.name">{{ option.name }}</el-option>
								</el-select>

							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="onSubmit">重置</el-button>
							</el-form-item>
						</el-form>
					</div>
					<el-card class="box-card order-list">
						<!-- <div slot="header" class="clearfix">
							<span class="route_list_title">物流线路</span>

						</div> -->
						<div v-loading="loading" class="route_list" v-if="orderList.length > 0">
							<!-- <div v-loading="loading" class="route_list"> -->
							<!-- <div class="qyitem"> -->
							<div class="qyitem" v-for="(item,index) in orderList" :key="index">
								<div class="col-12 col-md-2 col-lg-2 text-center">
									<img :src="item.logo?$img(item.logo):require('@/assets/images/logo.png')">
								</div>
								<div class="col-12 col-md-10 col-lg-10">
									<div class="qy-title" style="width:100%">
										<router-link :to="'/supplier/companyDetail?id='+item.id" target="_blank"
											title="点击查看公司详情">{{item.name}}</router-link>
										<!-- <span class="qy-label">注销</span> -->
									</div>
									<div class="qy-detail"><span>法定代表人：{{item.legal_person}}</span><span
											class="ml-30">注册资本：{{item.zczb}}</span><span
											class="ml-30">成立时间：{{item.cl_time}}</span>
									</div>
									<div class="qy-detail">
										地址：{{item.province_name}}{{item.city_name}}{{item.district_name}}{{item.address}}
									</div>
									<div class="qy-detail"><span class="jyfw">经营范围：{{item.jyfw}}</span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!loading && orderList.length == 0" class="empty-wrap">暂无相关数据</div>
						<div class="pager">
							<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
								:current-page.sync="currentPage" :page-size.sync="pageSize"
								@size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
								hide-on-single-page></el-pagination>
						</div>
					</el-card>
				</div>
				<div class="main_right">
					<div class="login_st complain" style="height: 180px;">
						<router-link to="/">
							<div class="form-group" style="text-align:center; color: #ffffff;" id="onetime">Hi，你好！</div>
							<div class="form-group"
								style="text-align: center; font-size: 15px; color: #fff; padding: 10px 0px;">
								欢迎进入“应急保障平台”</div>
						</router-link>
						<div style="display: flex;align-items: center;justify-content: center;margin-top: 20px;">
							<router-link to="/supplyLogin/login" class="button button-green">登录</router-link>
							<router-link to="/supplyLogin/register" class="button button-purple">注册</router-link>
						</div>
					</div>
					<div class="complain login_st" id="sbhggys">
						<div style="margin-bottom: 5px;">
							<router-link to="/">
								<span style="float: left; margin-left: 22%;font-size: 18px;">申请合格供应商</span><br>
								<span class="hggys" style="margin-top: 5px;margin-left: -3px;">合格供应商规则</span>
								<span style="color: #fff; font-size: 16px; padding: 0 3px;">|</span>
								<span class="hggys">新手指南</span>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		companyIndustry,
		getArea,
		indexSearch
	} from '@/api/supply/supply';
	export default {
		name: "supplyProductSearch",
		components: {},
		data: () => {
			return {
				formData: {
					keyword: "",
					industry_id: "",
					province_name: "",
					// zczb_id: "",
					// jynx_id: "",
					// province_id: ""
				},
				loading: true,
				industryList: [],
				zczbList: [{
						class_name: "0-100万",
						class_id: '0'
					},
					{
						class_name: "100-500万",
						class_id: '100'
					},
					{
						class_name: "500-1000万",
						class_id: '500'
					},
					{
						class_name: "1000-5000万",
						class_id: '1000'
					},
					{
						class_name: "5000-1亿",
						class_id: '5000'
					},
					{
						class_name: "1亿-10亿",
						class_id: '10000'
					},
					{
						class_name: "10亿以上",
						class_id: '100000'
					}
				],
				jynxList: [{
						class_name: "1年以内",
						class_id: '0'
					},
					{
						class_name: "1-3年",
						class_id: '1'
					},
					{
						class_name: "3-8年",
						class_id: '3'
					},
					{
						class_name: "8-12年",
						class_id: '8'
					},
					{
						class_name: "12-15年",
						class_id: '12'
					},
					{
						class_name: "15年以上",
						class_id: '15'
					},
				],
				province: [],
				orderList: [],
				currentPage: 1,
				pageSize: 10,
				total: 0,
			};
		},
		created() {
			if (this.$route.query.keyword) {
				this.formData.keyword = this.$route.query.keyword
			}
			this.getcompanyIndustry()
			this.getDefaultAreas(0, {
				level: 0
			})
			this.getOrderList()
		},
		methods: {
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			search() {
				this.currentPage = 1;
				this.refresh();
			},
			refresh() {
				this.loading = true
				this.getOrderList()
			},
			onSubmit() {
				this.formData = {
					keyword: "",
					industry_id: "",
					province_name: ""
				}
				this.currentPage = 1;
				this.refresh();
			},
			jynxChange(e) {

			},
			zczbChange(e) {

			},
			changeProvice(e) {
				this.currentPage = 1;
				this.refresh();
			},
			industryChange(e) {
				this.currentPage = 1;
				this.refresh();
			},
			getcompanyIndustry() {
				companyIndustry().then(res => {
						let data = res.data
						if (data != null) {
							this.industryList = data
						}
					})
					.catch(err => {})
			},
			getDefaultAreas(pid, obj) {
				getArea({
						pid: pid
					})
					.then(res => {
						if (res.code == 0) {
							var data = []
							var selected = undefined
							res.data.forEach((item, index) => {
								if (obj != undefined) {
									if (obj.level == 0 && obj.province_id != undefined) {
										selected = obj.province_id
									} else if (obj.level == 1 && obj.city_id != undefined) {
										selected = obj.city_id
									} else if (obj.level == 2 && obj.district_id != undefined) {
										selected = obj.district_id
									}
								}

								if (selected == undefined && index == 0) {
									selected = item.id
								}
								data.push(item)
							})

							this.province = data
						}
						this.loading = false
					})
					.catch(err => {
						this.loading = false
					})
			},
			getOrderList() {
				indexSearch({
						page: this.currentPage,
						page_size: this.pageSize,
						search_text: this.formData.keyword,
						province_name: this.formData.province_name,
						industry_id: this.formData.industry_id
					})
					.then(res => {
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						this.orderList = list;
						this.loading = false;
					})
					.catch(res => {
						this.loading = false;
					});
			},
		}
	};
</script>
<style scoped>
	/deep/.first .el-form-item__content {
		display: flex;
		align-items: center;
		height: 100%;
		background-color: rgba(5, 123, 60, 0.8);
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-form-item.is-error .el-input__inner {
		border-color: #11773a;
	}

	/deep/.el-form-item .el-input__inner:focus {
		border-color: #11773a;
	}

	/deep/.el-textarea__inner:focus {
		border-color: #11773a;
	}

	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}
</style>
<style scoped lang="scss">
	.el-button--primary {
		background-color: rgba(5, 123, 60, 0.8);
		border-color: rgba(5, 123, 60, 0.8);
	}

	.container {
		.banner {
			background: url('../../../assets/images/supply/gywm.png') no-repeat;
			background-size: 100% 100%;
			position: relative;
			z-index: 1;
			width: 100%;
			height: 300px;
			margin-top: 80px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.breadcrumb-text {
					height: 300px;
					position: relative;
					z-index: 1;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h2 {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 36px;
					}

					p {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 18px;
					}
				}

			}

		}

		.scfl {
			padding-top: 30px;



			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;
				padding-bottom: 10px;
				border-bottom: 1px #c1c1c1 solid;
			}
		}

		.main_wrap {
			padding-bottom: 50px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 30px auto;
				display: flex;

				.main_left {
					flex: 1;

					.search_wrap {
						.demo-form-inline {
							.first {
								width: 100%;

								height: 48px;

								.label {
									flex-shrink: 0;
									font-size: 16px;
									line-height: 48px;
									text-align: center;
									font-weight: 600;
									color: white;
									width: 15%;
								}

								.search_btn {
									background-color: #fff;
									border-color: #fff;
									color: rgba(5, 123, 60, 0.8);
									margin-left: 4px;
									height: 40px;
									font-weight: bold;
									font-size: 16px;

									&:hover {
										background-color: rgba(5, 123, 60, 0.8);
										border-color: rgba(5, 123, 60, 0.8);
										color: #fff;
									}
								}

								.kong_btn {
									background-color: transparent;
									border-color: transparent;
								}
							}

							.nowidth {
								width: 20%;
								margin-right: 1%;

								&:last-child {}
							}
						}
					}

					.box-card {
						.route_list_title {
							height: 18px;
							line-height: 18px;
							padding: 0 0 0 6px;
							font-size: 16px;
							border-left: 3px solid #57c5a5;
							color: #57c5a5;
							overflow: hidden;
						}

						.route_list {
							border: 1px #eee solid;

							.qyitem {
								display: flex;
								padding: 25px 10px;
								margin: 0;
								position: relative;
								border-bottom: 1px #eee solid;
								align-items: center;

								img {
									height: 90px;
									width: 90px;
									flex-shrink: 0;
									margin-right: 30px;
								}

								.qy-title {
									margin-bottom: 15px;

									a {
										color: #333;
										font-size: 18px;
										line-height: 20px;
										font-weight: 700;
										margin-right: 30px;

										&:hover {
											color: #11773a;
										}
									}

									.qy-label {
										color: #00CD4A;
										border: 1px solid #00CD4A;
										top: -2px;
										font-weight: 400;
										margin-right: 5px;
										padding: 0.1em 0.6em;
										line-height: 1.2em;
										border-radius: 2px;
									}
								}

								.qy-detail {
									margin-top: 5px;

									span {
										color: #707070;
									}

									.ml-30 {
										margin-left: 30px;

									}
								}

								.jyfw {
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
								}
							}
						}
					}
				}

				.main_right {
					width: 25%;
					flex-shrink: 0;
					margin-left: 20px;

					.login_st {
						width: 89%;
						padding: 25px 15px 25px 15px;
						background-color: rgba(5, 123, 60, 0.8);
						margin: 0 auto;
						border-radius: 5px;
					}

					.complain {
						width: 95%;
						height: 130px;
						border-radius: 5px;
						/* line-height: 50px; */
						text-align: center;
						cursor: pointer;
						background: rgba(5, 123, 60, 0.8);
						margin-top: 15px;
						box-sizing: border-box;

						&:hover {
							background: rgba(255, 0, 0, 1);
						}

						a {
							font-weight: bold;
							font-family: "微软雅黑";
							font-size: 18px;
							color: white;
						}

						.hggys {
							display: inline-block;
							padding: 23px 0px;
							font-size: 15px;
						}

						.button-green {
							background: -webkit-gradient(linear, left top, left bottom, from(#c8dd95), to(#428739));
						}

						.button-purple {
							background: -webkit-gradient(linear, left top, left bottom, from(#B8A9F3), to(#6F50E7));
						}

						.button {
							width: 40%;
							margin: 0 10px;
							padding: 7px;
							border-radius: 5px;
							font-size: 14px;
						}
					}
				}
			}
		}
	}

	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
</style>