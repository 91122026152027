<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card order-list">
			<div slot="header" class="clearfix">
				<span>集采信息</span>
				<el-button style="margin-left: 15px;" type="primary" size="small " @click="addBtn">新增</el-button>
			</div>
			<el-tabs v-model="activeName" @tab-click="tabhandleClick">
				<el-tab-pane label="国内" name="1">
				</el-tab-pane>
				<el-tab-pane label="国际" name="2">
				</el-tab-pane>
			</el-tabs>
			<div v-loading="loading">
				<el-table :data="orderList" border style="width: 100%">
					<el-table-column label="标题" prop="title" >
					</el-table-column>
					<el-table-column prop="buy_contacts" label="联系人" width="100">
					</el-table-column>
					<el-table-column prop="buy_mobile" label="手机号" width="120">
					</el-table-column>
					<el-table-column prop="create_time" label="发布时间" width="120">
						<template slot-scope="scope">
							{{$timeStampTurnTime(orderList[scope.$index].create_time,1)}}
						</template>
					</el-table-column>
					<!-- <el-table-column label="是否显示" width="120">
						<template slot-scope="scope">
							<el-switch :value="orderList[scope.$index].is_show?true:false" active-color="#11773a"
								@change="changeSwitch($event,scope.row.id,scope.$index)">
							</el-switch>
						</template>

					</el-table-column> -->
					<el-table-column fixed="right" label="操作" width="100">
						<template slot-scope="scope">

							<el-button type="text" size="small" @click="handleClick(scope.row)">编辑</el-button>
							<el-button @click="deleteClick(scope.row)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pager" style="margin-top: 20px;">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
					:current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange"
					@current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
			</div>
		</el-card>
		<el-dialog title="集采详情" :visible.sync="addLogistics" width="80%">
			<el-form :model="formData" :rules="formrules" ref="ruleFormData" label-width="80px">

				<el-form-item label="标题" prop="title">
					<el-input v-model="formData.title" placeholder="请输入标题" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="发布类型" prop="source">
					<el-radio-group v-model="formData.source">
						<el-radio :label="1">国内</el-radio>
						<el-radio :label="2">国际</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="联系人" prop="buy_contacts">
					<el-input v-model="formData.buy_contacts" placeholder="请输入联系人" class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="buy_mobile">
					<el-input v-model="formData.buy_mobile" maxlength="11" placeholder="请输入手机号"
						class="ns-len-input"></el-input>
				</el-form-item>
				<el-form-item label="集采内容" prop="content">

					<div style="border: 1px solid #ccc;">
						<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
							:mode="mode" />
						<Editor style="height: 500px; overflow-y: hidden;" v-model="formData.content"
							:defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
					</div>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="medium" @click="saveInfo('ruleFormData')">保存</el-button>
				</el-form-item>
			</el-form>

		</el-dialog>
	</div>
</template>

<script>
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import {
		mapGetters
	} from 'vuex';
	import Config from '@/utils/config';
	import {
		bidding_list,
		bidding_add,
		modifyState,
		bidding_del
	} from '@/api/supply/supply';
	export default {
		name: 'supplymemberMyJcxx',
		components: {
			Editor,
			Toolbar
		},
		data() {
			let self = this
			var isMobile = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("手机号不能为空"))
				} else {
					const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

					if (reg.test(value)) {
						callback()
					} else {
						callback(new Error("请输入正确的手机号"))
					}
				}
			}
			return {
				editor: null,
				html: '<p>hello</p>',
				toolbarConfig: {
					excludeKeys: [
						'group-video'
					]
				},
				editorConfig: {
					placeholder: '请输入内容...',
					MENU_CONF: {
						uploadImage: {
							server: Config.baseUrl + '/companyapi/Company/images',
							fieldName: "file"
						}
					}
				},
				mode: 'default', // or 'simple'
				activeName: '1',
				loading: true,
				addLogistics: false,
				orderList: [],
				currentPage: 1,
				pageSize: 10,
				total: 0,
				yes: true,
				formData: {
					title: "",
					content: "",
					source: 1,
					buy_contacts: "",
					buy_mobile: ""
				},
				formrules: {
					title: [{
						required: true,
						message: "请输入标题",
						trigger: "blur"
					}],
					source: [{
						required: true,
						message: "请选择类型",
						trigger: "blur"
					}],
					content: [{
						required: true,
						message: "请输入内容",
						trigger: "blur"
					}],
					buy_contacts: [{
						required: true,
						message: "请输入联系人",
						trigger: "blur"
					}],
					buy_mobile: [{
						required: true,
						validator: isMobile,
						trigger: "blur"
					}],
				},
			};
		},
		created() {
			this.getOrderList();
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		methods: {
			onCreated(editor) {
				this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
			},
			tabhandleClick(tab, event) {
				this.currentPage = 1;
				this.refresh();
			},
			//删除
			deleteClick(data) {
				this.$confirm("您确定要删除吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					bidding_del({
						bidding_id: data.id
					}).then(res => {
						this.$message({
							message: "删除成功",
							type: "success"
						})
						this.refresh()
						typeof callback == "function" && callback()
					})
				})
			},
			//编辑
			handleClick(data) {
				this.formData = data

				this.addLogistics = true
			},
			//修改状态
			changeSwitch(e, route_id, index) {
				console.log(e, route_id)
				modifyState({
						route_id,
						is_show: e ? '1' : '0'
					})
					.then(res => {
						if (res.code == 0) {
							this.$message({
								message: "修改成功！",
								type: "success"
							})
							this.refresh()
						} else {
							this.$message({
								message: res.message,
								type: "warning"
							})
						}
					})
					.catch(err => {
						this.flag = false
						this.$message.error(err.message)
					})
			},
			saveInfo(formName) {
				this.$refs[formName].validate(valid => {
					console.log(valid)
					if (valid) {
						var data = {
							title: this.formData.title,
							content: this.formData.content,
							source: this.formData.source,
							buy_contacts: this.formData.buy_contacts,
							buy_mobile: this.formData.buy_mobile

						}
						if (this.formData.id) {
							data.id = this.formData.id
						}
						if (this.flag) return
						this.flag = true
						console.log(data)
						bidding_add(data)
							.then(res => {
								if (res.code == 0) {
									this.flag = false
									this.$message({
										message: "提交成功！",
										type: "success"
									})
									this.currentPage = 1
									this.refresh()
									this.addLogistics = false
								} else {
									this.flag = false
									this.$message({
										message: res.message,
										type: "warning"
									})
								}
							})
							.catch(err => {
								this.flag = false
								this.$message.error(err.message)
							})
					} else {
						return false
					}
				})
			},
			addBtn() {
				this.formData = {
					title: "",
					content: "",
					source: 1,
					buy_contacts: "",
					buy_mobile: ""
				}
				this.addLogistics = true
			},

			getOrderList() {
				bidding_list({
						page: this.currentPage,
						page_size: this.pageSize,
						source: this.activeName
					})
					.then(res => {
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						this.orderList = list;
						this.loading = false;
					})
					.catch(res => {
						this.loading = false;
					});
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			refresh() {
				this.loading = true;
				this.getOrderList();
			},

		}
	};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #11773a;
	}

	/deep/.el-radio__input.is-checked .el-radio__inner {
		border-color: #11773a;
		background: #11773a;
	}

	/deep/.el-radio__inner:hover {
		border-color: #11773a;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-form-item.is-error .el-input__inner {
		border-color: #11773a;
	}

	/deep/.el-form-item .el-input__inner:focus {
		border-color: #11773a;
	}

	/deep/.el-textarea__inner:focus {
		border-color: #11773a;
	}

	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}

	/deep/.info_wrap .el-form-item__label {
		line-height: 20px;
	}

	/deep/.el-tabs__item:hover {
		color: #11773a;
	}

	/deep/.el-upload--picture-card:hover {
		border-color: #11773a;
	}

	/deep/.el-upload--picture-card:focus {
		border-color: #11773a;
	}

	/deep/.el-range-editor.is-active {
		border-color: #11773a;
	}

	/deep/.el-tabs__item.is-active {
		color: #11773a;
		font-size: 18px;
	}

	/deep/.el-tabs__item {
		font-size: 18px;
	}

	/deep/.el-tabs__active-bar {
		background-color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #fff !important;
	}

	/deep/.el-pager .number:hover {
		color: #11773a !important;
	}
</style>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.el-button--primary {
		background-color: $base-supply-color;
		border-color: $base-supply-color;
	}

	.order-list {
		.el-button--primary {
			background-color: $base-supply-color;
			border-color: $base-supply-color;
		}

		.el-button--text {
			color: $base-supply-color;
		}

		nav {
			overflow: hidden;
			padding: 10px 0;
			background: #fff;
			margin-bottom: 10px;
			border-bottom: 1px solid #eeeeee;

			li {
				float: left;

				&:nth-child(1) {
					width: 45%;
				}

				&:nth-child(2) {
					width: 10%;
				}

				&:nth-child(3) {
					width: 10%;
				}

				&:nth-child(4) {
					width: 10%;
				}

				&:nth-child(5) {
					width: 15%;
				}

				&:nth-child(6) {
					width: 10%;
				}
			}
		}

		.list {
			.item {
				margin-bottom: 20px;
				border: 1px solid #eeeeee;
				border-top: 0;

				.head {
					padding: 8px 10px;
					background: #f7f7f7;
					font-size: 12px;

					.create-time {
						margin-right: 10px;
					}

					border-bottom: 1px solid #eeeeee;

					a {
						margin: 0 10px 0 20px;
					}

					.order-type {
						margin-left: 30px;
					}
				}
			}

			ul {
				background-color: #fff;
				padding: 10px;
				overflow: hidden;

				li {
					float: left;
					line-height: 60px;

					&:nth-child(1) {
						width: 45%;
						line-height: inherit;

						.img-wrap {
							width: 60px;
							height: 60px;
							float: left;
							margin-right: 10px;
							cursor: pointer;
						}

						.info-wrap {
							margin-left: 70px;

							h5 {
								font-weight: normal;
								font-size: $ns-font-size-base;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
								display: inline-block;
								cursor: pointer;

								&:hover {
									color: $base-color;
								}
							}

							span {
								font-size: $ns-font-size-sm;
								color: #9a9a9a;
							}
						}
					}

					&:nth-child(2) {
						width: 10%;
					}

					&:nth-child(3) {
						width: 10%;
					}

					&:nth-child(4) {
						width: 10%;
					}

					&:nth-child(5) {
						width: 15%;
						line-height: 30px;

						a {
							display: block;
						}
					}

					&:nth-child(6) {
						width: 10%;
						line-height: initial;

						button {
							margin-left: 0;
							margin-bottom: 10px;

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		.empty-wrap {
			text-align: center;
			padding: 10px;
		}
	}
</style>