export default {
	path: "/supplier",
	component: () => import("@/views/supplyView/supplier"),
	redirect: "/supplyView/supplier",
	name: "supplierIndexPage",
	children: [{
			path: "/supplier/index",
			name: "supplierIndex",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/supplier/index")
		},
		{
			path: "/supplier/companyDetail",
			name: "companyDetail",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/supplier/companyDetail")
		},
		{
			path: "/supplier/ggxy",
			name: "supplyGgxy",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/supplier/ggxy")
		},
		{
			path: "/supplier/gys",
			name: "supplyGys",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/supplier/gys")
		},
		{
			path: "/supplier/gysDetail",
			name: "supplyGysDetail",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/supplier/gysDetail")
		},
		{
			path: "/supplier/home",
			name: "supplyGysHome",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/supplier/home")
		}
	]

}