<template>
	<div class="container">
		<!-- 广告图 -->
		<div class="banner">
			<div class="main">
				<div class="breadcrumb-text">
					<h2>供应商库</h2>
					<p>Vendor library</p>
					<!-- 	<h2 class="mt-10">应急物流</h2>
					<p>Emergency logistics</p> -->
				</div>
			</div>
		</div>
		<div class="scfl">
			<div class="main">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>供应商库</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main_wrap">
			<div class="main">

				<div v-loading="loading" style="margin-top: 30px;">
					<el-descriptions class="mydes" :column="2" border v-if="companyData">
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content"
							label="企业名称">
							{{companyData.name}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content"
							label="统一信用代码">
							{{companyData.unicode}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content"
							label="供应商类型">
							{{companyData.class==1?'合格供应商':'认证供应商'}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content"
							label="供应商编号">
							{{companyData.gys_number}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content"
							label="首次发证日期">
							{{companyData.first_time}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label " content-class-name="my-content" label="有效期">
							{{companyData.start_time}} 至 {{companyData.end_time}}
						</el-descriptions-item>
					</el-descriptions>
					<div style="margin: 50px 0px 80px;text-align: center;" v-if="companyData.gys_image">
						<img :src="$img(companyData.gys_image)"
							style="max-width: 85%;">
					</div>
				</div>


			</div>
		</div>

	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		companyInfo
	} from '@/api/supply/supply';
	export default {
		name: "supplyGysDetail",
		components: {},
		data: () => {
			return {
				companyData: null,
				currentPage: 1,
				pageSize: 10,
				total: 0,
				loading: true,
				source: 0,
			};
		},
		created() {

			this.getOrderList();

		},
		methods: {

			getOrderList() {
				companyInfo({
					id:this.$route.query.id
				}).then(res => {
					if (res.code == 0 && res.data) {
						this.companyData = res.data
						this.loading = false
					}
				})

			},
		}
	};
</script>
<style scoped>
	/deep/.nowidth .el-form-item__content {
		width: 360px;
	}

	.el-select {
		width: 120px;
	}

	/deep/.my-label {
		font-size: 16px;
		color: #333;
		width: 18%;
		background-color: #f4f8fb !important;
	}

	/deep/.my-content {
		font-size: 16px;
		color: #333;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}

	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #fff !important;
	}

	/deep/.el-pager .number:hover {
		color: #11773a !important;
	}
</style>
<style scoped lang="scss">
	.el-button--primary {
		background-color: #57c5a5;
		border-color: #57c5a5;
	}

	.container {
		.banner {
			background: url('../../../assets/images/supply/common.png') no-repeat;
			background-size: 100% 100%;
			position: relative;
			z-index: 1;
			width: 100%;
			padding: 6% 0;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.breadcrumb-text {
					// height: 300px;
					position: relative;
					z-index: 1;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h2 {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 36px;
					}

					p {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 18px;
					}
				}

			}

		}

		.scfl {
			padding-top: 30px;



			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;
				padding-bottom: 10px;
				border-bottom: 1px #c1c1c1 solid;
			}
		}

		.main_wrap {
			padding-bottom: 50px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.mydes {
					margin-top: 30px;
				}


			}
		}
	}

	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
</style>