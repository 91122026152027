<template>
	<div class="container">
		<!-- 广告图 -->
		<div class="banner">
			<div class="main">
				<div class="breadcrumb-text">
					<h2>供应商库</h2>
					<p>Vendor library</p>
					<!-- 	<h2 class="mt-10">应急物流</h2>
					<p>Emergency logistics</p> -->
				</div>
			</div>
		</div>
		<div class="scfl">
			<div class="main">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>供应商库</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main_wrap">
			<div class="main">
				<div class="gysdiv" style="clear: both;height:50px;margin-bottom: 30px;">
					<span id="hg" class="gysspan" :class="{gysactive:source==1}" @click="changeIndex(1)">合格供应商</span>
					<span id="rz" class="gysspan" :class="{gysactive:source==2}" @click="changeIndex(2)">认证供应商</span>
					<span id="yx" class="gysspan" :class="{gysactive:source==3}" @click="changeIndex(3)">预选供应商</span>
				</div>
				<div v-loading="loading" style="margin-top: 30px;">
					<el-table :data="orderList" border style="width: 100%">

						<el-table-column label="企业名称">
							<template slot-scope="scope">
								<router-link class="routecolor"
									:to="'/supplier/companyDetail?id='+scope.row.id">{{scope.row.name}}</router-link>

							</template>
						</el-table-column>
						<el-table-column prop="unicode" label="统一信用代码">
						</el-table-column>
						<el-table-column prop="gys_number" v-if="source==1" label="合格供应商编号">
						</el-table-column>
						<el-table-column prop="gys_number" v-if="source==2" label="认证供应商编号">
						</el-table-column>
						<el-table-column v-if="source!=3" label="报告有效期" width="200">
							<template slot-scope="scope">
								{{scope.row.start_time}} 至 {{scope.row.end_time}}
							</template>
						</el-table-column>
						<el-table-column v-else label="入驻日期" width="200">
							<template slot-scope="scope">
								{{scope.row.create_time}}
							</template>
						</el-table-column>
						<el-table-column width="200">
							<template slot-scope="scope">

								<el-button type="text" size="medium"
									@click="$router.push({ path: '/supplier/gysDetail', query: { id: scope.row.id} })"
									v-if="source!=3">详细信息</el-button>
								<el-button
									@click="$router.push({ path: '/supplier/companyDetail', query: { id: scope.row.id} })"
									type="text" size="medium">企业空间</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>

				<div class="pager" style="margin-top: 20px;">
					<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
						:current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange"
						@current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		gys_lists
	} from '@/api/supply/supply';
	export default {
		name: "supplyGys",
		components: {},
		data: () => {
			return {
				orderList: [],
				currentPage: 1,
				pageSize: 10,
				total: 0,
				loading: true,
				source: 1,
			};
		},
		created() {

			this.getOrderList();

		},
		methods: {
			changeIndex(index) {
				this.source = index
				this.currentPage = 1;
				this.orderList = []
				this.refresh();
			},
			goclick(row, column, event, cell) {
				console.log(row, column, event, cell)
				this.goodsDetail = row
				this.dialogTableVisible = true
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			refresh() {
				this.loading = true;
				this.getOrderList();
			},
			getOrderList() {
				gys_lists({
						page: this.currentPage,
						page_size: this.pageSize,
						class: this.source
					})
					.then(res => {
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						this.orderList = list;
						this.loading = false;
						this.$forceUpdate()
					})
					.catch(res => {
						this.loading = false;
					});

			},
		}
	};
</script>
<style scoped>
	/deep/.nowidth .el-form-item__content {
		width: 360px;
	}

	.el-select {
		width: 120px;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}



	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}

	.routecolor:hover {
		color: #11773a !important;
	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #fff !important;
	}

	/deep/.el-pager .number:hover {
		color: #11773a !important;
	}
</style>
<style scoped lang="scss">
	.el-button--primary {
		background-color: #57c5a5;
		border-color: #57c5a5;
	}

	.container {
		.banner {
			background: url('../../../assets/images/supply/common.png') no-repeat;
			background-size: 100% 100%;
			position: relative;
			z-index: 1;
			width: 100%;
			padding: 6% 0;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.breadcrumb-text {
					// height: 300px;
					position: relative;
					z-index: 1;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h2 {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 36px;
					}

					p {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 18px;
					}
				}

			}

		}

		.scfl {
			padding-top: 30px;



			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;
				padding-bottom: 10px;
				border-bottom: 1px #c1c1c1 solid;
			}
		}

		.main_wrap {
			padding-bottom: 50px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.el-button--primary {
					background-color: #1a7ac0;
					border-color: #1a7ac0;
				}

				.el-button--text {
					color: #1a7ac0;
				}

				.gysdiv {
					margin: 20px;
					display: flex;
					align-items: center;

					.gysspan {
						flex: 1;
						text-align: center;
						cursor: pointer;
						height: 49px;
						line-height: 49px;
						font-size: 18px;
						font-weight: bold;
						color: #1a7ac0;
						border: 1px solid #eaedf4;

						&.gysactive {
							color: #fff;
							background-color: #1a7ac0;
							border: none;
						}
					}
				}


			}
		}
	}

	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
</style>