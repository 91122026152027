<template>
	<div class="container">
		<!-- 广告图 -->
		<div class="banner">
			<div class="main">
				<div class="zbdw">
					<p>国家标准《合格供应商信用评价规范》</p>
					<p>应用试点示范平台</p>
					<p class="text">全国社会信用标准化技术委员会授权</p>
				</div>
			</div>
		</div>
		<div class="zygn">
			<div class="main">
				<div class="zygn_wrap">
					<router-link :to="item.path" class="item" v-for="(item,index) in zygnList" :key="index">
						<img :src="item.img" alt="">
						<div class="item_title">
							{{item.title}}
						</div>
					</router-link>
				</div>
			</div>
		</div>
		<div class="zygn zxrz">
			<div class="main">
				<div class="section-heading">
					最新入驻
				</div>
				<div class="zxrz_wrap">
					<div class="item" v-for="(item,index) in zxrzList" :key="index">
						<div class="item_wrap">
							<router-link :to="'/supplier/companyDetail?id='+item.id" target="_blank">
								<div class="item_top">
									<div class="zxrz_item_img">
										<img :src="item.logo?$img(item.logo):require('@/assets/images/logo.png')">

									</div>
									<div class="zxrz_item_name">{{item.name}}</div>
								</div>
							</router-link>
							<div class="zxrz_item_nr">
								<span>法人代表：</span>{{item.legal_person}}
							</div>
							<div class="zxrz_item_nr">
								<span>统一代码：</span>{{item.unicode}}
							</div>
							<div class="zxrz_item_nr">
								<span>成立日期：</span>{{item.cl_time}}
							</div>
							<div class="zxrz_item_nr">
								<span>所属行业：</span>{{item.industry_name}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="zygn suppliers">
			<div class="main">
				<div class="suppliers_wrap">
					<div class="suppliers_tab" style="flex:1;">
						<el-card class="box-card order-list">
							<div slot="header" class="clearfix">
								<div class="header_wrap">
									<span>中国合格供应商库</span>
									<router-link to="/supplier/gys">查看更多>></router-link>
								</div>
							</div>

							<el-tabs v-model="orderStatus" @tab-click="handleClick">
								<el-tab-pane label="合格供应商" name="1"></el-tab-pane>
								<el-tab-pane label="认证供应商" name="2"></el-tab-pane>
								<el-tab-pane label="预选供应商" name="3"></el-tab-pane>
							</el-tabs>

							<div v-loading="loading">
								<div class="list" v-if="orderList.length > 0">
									<div class="item" v-for="(orderItem, orderIndex) in orderList" :key="orderIndex">
										<h4>
											<router-link
												:to="'/supplier/companyDetail?id='+orderItem.id">{{orderItem.name}}</router-link>
										</h4>
										<div v-if="orderStatus!=3">
											<span>{{orderItem.start_time}}</span>
											<span> 至 </span>
											<span >{{orderItem.end_time}}</span>
										</div>
										<div v-else>
											<span>{{orderItem.create_time}}</span>
										</div>
									</div>
								</div>
								<div v-else-if="!loading && orderList.length == 0" class="empty-wrap">暂无相关数据</div>
							</div>

						</el-card>
					</div>
					<div class="suppliers_right">
						<router-link to="/supplyLogin/register" class="sq">
							<p>申请合格供应商</p>
						</router-link>
						<router-link to="/supplyLogin/register" class="fwcp">
							<p>合格供应商适用领域<br>支持信用服务产品</p>
						</router-link>
						<div class="index_list">
							<ul style="display: inline-block;">
								<li>供给资源保障信用服务体系</li>
								<li>大宗商品交易信用服务体系</li>
								<li>大型商贸营销信用服务体系</li>
								<li>区域经济数据平台解决方案</li>
								<li>产业经济数据平台解决方案</li>
								<li>企业集团征信数据服务体系</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="zygn suppliers ggxy">
			<div class="main">
				<div class="section-heading">
					公共信用记录
				</div>
				<div class="suppliers_wrap">
					<div class="suppliers_tab" style="margin-right: 0;">
						<el-card class="box-card order-list">
							<div slot="header" class="clearfix">
								<div class="header_wrap">
									<span>主体</span>
									<router-link to="/supplier/ggxy">更多></router-link>
								</div>
							</div>

							<el-tabs type="card" v-model="companyStatus" @tab-click="handleClickcompany">
								<el-tab-pane label="交易失信" name="0"></el-tab-pane>
							</el-tabs>

							<div v-loading="companyloading">
								<div class="list" v-if="companyList.length > 0">
									<div class="item_company" v-for="(companyItem, companyIndex) in companyList"
										:key="companyIndex">
										<div class="company_top">
											<router-link
												to="/supplier/ggxy?source=0">{{companyItem.company_name}}</router-link>
											<span>{{companyItem.create_time}}</span>
										</div>
										<p>统一社会信用代码/组织机构代码：{{companyItem.unicode}}</p>
									</div>
								</div>
								<div v-else-if="!companyloading && companyList.length == 0" class="empty-wrap">暂无相关数据
								</div>
							</div>

						</el-card>
					</div>
					<div class="suppliers_tab" style="margin-right: 0;">
						<el-card class="box-card order-list">
							<div slot="header" class="clearfix">
								<div class="header_wrap">
									<span>产品</span>
									<router-link
										:to="goodsStatus==1?'/supplier/ggxy?source=1':'/supplier/ggxy?source=2'">更多></router-link>
								</div>
							</div>

							<el-tabs v-model="goodsStatus" type="card" @tab-click="handleClickgoods">
								<el-tab-pane label="抽检不合格" name="1"></el-tab-pane>
								<el-tab-pane label="食品不合格" name="2"></el-tab-pane>
							</el-tabs>

							<div v-loading="goodsloading">
								<div class="list" v-if="goodsList.length > 0">
									<div class="item_goods" v-for="(goodsItem, goodsIndex) in goodsList"
										:key="goodsIndex" @click="openShow(goodsItem)">
										<div>{{goodsStatus==1?'样品名称':'食品名称'}}：<span>{{goodsItem.goods_name}}</span>
										</div>
										<div>被抽样单位：<span>{{goodsItem.company_name}}</span></div>
										<div v-if="goodsStatus==1">生产企业：<span>{{goodsItem.bc_company_name}}</span></div>
										<div v-if="goodsStatus==2">生产日期/批号：<span>{{goodsItem.sc_time}}</span></div>
									</div>
								</div>
								<div v-else-if="!goodsloading && goodsList.length == 0" class="empty-wrap">暂无相关数据</div>
							</div>

						</el-card>
					</div>
				</div>
			</div>
		</div>
		<div class="zygn hzhb">
			<div class="main">
				<div class="section-heading">
					合作伙伴
				</div>
				<div class="row" style="margin-top: 30px;">
					<el-select v-model="hyxhvalue" placeholder="行业协会" @change="change">
						<el-option v-for="item in hyxhoptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<el-select v-model="zxjgvalue" placeholder="征信机构" @change="zxjgvalue=''">
						<el-option v-for="item in zxjgoptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<el-select v-model="qyjtvalue" placeholder="企业集团" @change="qyjtvalue=''">
						<el-option v-for="item in qyjtoptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<div class="btn-group hzjg">
						<a class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false"></a>
						<ul class="dropdown-menu" role="menu" x-placement="bottom-start"
							style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 38px, 0px);">

						</ul>
						<div class="clear"></div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="详情" :visible.sync="dialogTableVisible">
			<table class="table" v-if="goodsStatus==1&&goodsDetail">
				<tbody class="info-detail_body">
					<tr>
						<td>抽样编号：</td>
						<td>{{goodsDetail.cy_number}}</td>
					</tr>
					<tr>
						<td>产品种类：</td>
						<td>{{goodsDetail.one_category_name}}/{{goodsDetail.two_category_name}}</td>
					</tr>
					<tr>
						<td>样品名称：</td>
						<td>{{goodsDetail.goods_name}}</td>
					</tr>
					<tr>
						<td>标称生产企业名称：</td>
						<td>{{goodsDetail.bc_company_name}}</td>
					</tr>
					<tr>
						<td>标称生产企业地址：</td>
						<td>{{goodsDetail.bc_company_address}}</td>
					</tr>
					<tr>
						<td>被抽样单位名称：</td>
						<td>{{goodsDetail.company_name}}</td>
					</tr>
					<tr>
						<td>被抽样单位地址：</td>
						<td>{{goodsDetail.province_name}}{{goodsDetail.city_name}}{{goodsDetail.district_name}}{{goodsDetail.address}}
						</td>
					</tr>
					<!-- <tr>
						<td>功能类别：</td>
						<td>抗疲劳、免疫调节</td>
					</tr> -->
					<tr>
						<td>标称批准文号：</td>
						<td>{{goodsDetail.bc_number}}</td>
					</tr>
					<!-- <tr>
						<td>批号：</td>
						<td>/</td>
					</tr> -->
					<tr>
						<td>规格型号：</td>
						<td>{{goodsDetail.product_spec}}</td>
					</tr>
					<!-- <tr>
						<td>商标：</td>
						<td>椰岛</td>
					</tr> -->
					<tr>
						<td>生产日期/批号：</td>
						<td>{{goodsDetail.sc_time}}</td>
					</tr>
					<tr>
						<td>保质期：</td>
						<td>{{goodsDetail.expire_data}}</td>
					</tr>
					<tr>
						<td>不合格项目：</td>
						<td>{{goodsDetail.check_project}}</td>
					</tr>
					<tr>
						<td>检测值：</td>
						<td>{{goodsDetail.check_value}}</td>
					</tr>
					<tr>
						<td>标准值：</td>
						<td>{{goodsDetail.standard_value}}</td>
					</tr>
					<tr>
						<td>产品包装标签明示值：</td>
						<td>{{goodsDetail.express_value}}</td>
					</tr>
					<tr>
						<td>检验机构：</td>
						<td>{{goodsDetail.check_agency}}</td>
					</tr>
					<tr>
						<td>备注：</td>
						<td>{{goodsDetail.remarks}}</td>
					</tr>
				</tbody>
			</table>
			<table class="table" v-if="goodsStatus==2&&goodsDetail">
				<tbody class="info-detail_body">
					<tr>
						<td>食品名称：</td>
						<td>{{goodsDetail.goods_name}}</td>
					</tr>
					<tr>
						<td>被抽检单位名称：</td>
						<td>{{goodsDetail.company_name}}</td>
					</tr>
					<tr>
						<td>被抽检单位所在省份：</td>
						<td>{{goodsDetail.province_name}}</td>
					</tr>
					<!-- <tr>
						<td>商标：</td>
						<td>/</td>
					</tr> -->
					<tr>
						<td>标称生产企业地址：</td>
						<td>{{goodsDetail.bc_company_address}}</td>
					</tr>
					<tr>
						<td>生产日期/批号：</td>
						<td>{{goodsDetail.sc_time}}</td>
					</tr>
					<tr>
						<td>规格型号：</td>
						<td>{{goodsDetail.product_spec}}</td>
					</tr>
					<tr>
						<td>食物类型：</td>
						<td>{{goodsDetail.one_category_name}}/{{goodsDetail.two_category_name}}</td>
					</tr>
					<tr>
						<td>抽检单位：</td>
						<td>{{goodsDetail.check_agency}}</td>
					</tr>
					<tr>
						<td>检测项目名称：</td>
						<td>{{goodsDetail.check_project}}</td>
					</tr>
					<tr>
						<td>标准值：</td>
						<td>{{goodsDetail.standard_value}}</td>
					</tr>
					<tr>
						<td>检测值：</td>
						<td>{{goodsDetail.check_value}}</td>
					</tr>
					<tr>
						<td>抽检结果：</td>
						<td>{{goodsDetail.check_result}}</td>
					</tr>
				</tbody>
			</table>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		new_settled,
		dishonesty_lists,
		spotcheck_lists,
		gys_lists
	} from '@/api/supply/supply';
	export default {
		name: "supplyIndex",
		components: {},
		data: () => {
			return {
				zygnList: [{
					title: "试点方案",
					img: require("@/assets/images/supplier/intro1.png"),
					path: '/'
				}, {
					title: "评价方法",
					img: require("@/assets/images/supplier/intro2.png"),
					path: '/'
				}, {
					title: "技术路线",
					img: require("@/assets/images/supplier/intro3.png"),
					path: '/'
				}, {
					title: "国家标准",
					img: require("@/assets/images/supplier/intro4.png"),
					path: '/'
				}, ],
				zxrzList: [],
				orderStatus: '1',
				loading: true,
				orderList: [],
				companyStatus: '0',
				companyloading: true,
				companyList: [],
				goodsStatus: '1',
				goodsloading: true,
				goodsList: [],
				dialogTableVisible: false,
				goodsDetail: null,
				hyxhvalue: '',
				hyxhoptions: [{
					value: "1",
					label: '北京信用协会'
				}, {
					value: "2",
					label: '广东省信用协会'
				}, {
					value: "3",
					label: '深圳市信用促进会'
				}, {
					value: "4",
					label: '中国肉类协会'
				}, {
					value: "5",
					label: '中国蔬菜协会'
				}, {
					value: "6",
					label: '中国蔬菜流通协会'
				}, {
					value: "7",
					label: '中国林业协会'
				}, {
					value: "8",
					label: '中国服务贸易协会'
				}, {
					value: "9",
					label: '中国建筑装饰协会'
				}, {
					value: "10",
					label: '黑龙江省诚信建设促进会'
				}],
				zxjgvalue: '',
				zxjgoptions: [{
					value: "1",
					label: '北京信构信用管理有限公司'
				}],
				qyjtvalue: '',
				qyjtoptions: [{
					value: "1",
					label: '中国人寿财产保险股份有限公司',
				}, {
					value: "2",
					label: '上海旺链科技有限公司'
				}, {
					value: "3",
					label: '中国银联'
				}, {
					value: "4",
					label: '韵达快递'
				}, {
					value: "5",
					label: '黑龙江省禄瑞达投资集团有限公司'
				}, {
					value: "6",
					label: '华人天下文化传播有限公司'
				}, ]
			};
		},
		computed: {

		},
		created() {
			this.getOrderList();
			this.getCompanyList()
			this.getGoodsList()
			this.getNewCompany()
		},
		methods: {
			getNewCompany() {
				new_settled().then(res => {
						if (res.code == 0 && res.data) {
							this.zxrzList = res.data;
						}
					})
					.catch(err => {
						this.$message.error(err.message);
					});
			},
			change() {
				this.hyxhvalue = ''
			},
			openShow(data) {
				this.goodsDetail = data
				this.dialogTableVisible = true
			},
			handleClickgoods(tab, event) {
				this.goodsStatus = tab.name;
				this.goodsrefresh();
			},
			handleClickcompany(tab, event) {
				this.companyStatus = tab.name;
				this.companyrefresh();
			},
			handleClick(tab, event) {
				this.currentPage = 1;
				this.orderStatus = tab.name;
				this.orderList =[ ]
				this.refresh();
			},
			goodsrefresh() {
				this.goodsloading = true;
				this.getGoodsList();
			},
			companyrefresh() {
				this.companyloading = true;
				this.getCompanyList();
			},
			refresh() {
				this.loading = true;
				this.getOrderList();
			},
			getGoodsList() {
				// this.orderList = list;

				this.goodsloading = false;
				spotcheck_lists({
						page: 1,
						page_size: 10,
						source: this.goodsStatus
					})
					.then(res => {
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							// this.total = res.data.count;
						}
						this.goodsList = list;
						this.goodsloading = false;
					})
					.catch(res => {
						this.goodsloading = false;
					});
			},
			getCompanyList() {

				dishonesty_lists({
						page: 1,
						page_size: 10,
					})
					.then(res => {
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							// this.total = res.data.count;
						}
						this.companyList = list;
						this.companyloading = false;
					})
					.catch(res => {
						this.companyloading = false;
					});
			},
			getOrderList() {
				this.loading = false;
				gys_lists({
						page: 1,
						page_size: 10,
						class: this.orderStatus
					})
					.then(res => {
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							// this.total = res.data.count;
						}
						// this.orderList = list;
						this.$set(this,'orderList',list)
						this.$forceUpdate()
						this.loading = false;
						
					})
					.catch(res => {
						this.loading = false;
					});
			},
		}
	};
</script>
<style scoped>
	/deep/.el-tabs__item.is-active {
		color: #0b72e7;

	}

	/deep/.el-tabs__active-bar {
		background: #0b72e7;


	}

	/deep/.el-tabs__item:hover {
		color: #0b72e7;
	}

	/deep/.el-input__inner::placeholder {
		color: #333;
	}

	/deep/.el-input__inner {
		text-align: center;
		font-size: 16px;
		border-color: #333;
	}

	/deep/.el-input__inner:hover {
		border-color: #333;
	}

	/deep/.el-input__inner:blur {
		border-color: #333;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #0b72e7;
	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #0b72e7;
	}
</style>
<style scoped lang="scss">
	.container {
		.banner {
			width: 100%;
			background-image: url('../../../assets/images/supplier/banner.png');
			background-repeat: no-repeat;
			background-position: center 0;
			background-size: 100% 100%;
			color: #FFFFFF;
			text-align: center;
			font-size: 30px;
			padding: 150px 0;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.zbdw {
					p {
						line-height: 1.5;
						text-align: center;
						font-size: 40px;
						text-shadow: #ffffff 1px 1px 1px;
						letter-spacing: 10px;
					}

					.text {
						text-align: center;
						font-size: 25px;
						text-shadow: #ffffff 1px 1px 1px;
						letter-spacing: 5px;
						margin-top: 30px;
					}
				}

			}

		}

		.zygn {
			background: #fff;
			margin: 70px 0;

			.main {
				width: 90%;
				max-width: 1140px;
				margin: 0 auto;

				.section-heading {
					padding: 50px 0;
					font-size: 32px;
					color: #000;
					text-align: center;

				}

				.zygn_wrap {
					display: flex;
					align-items: center;
					justify-content: center;

					.item {
						flex: 1;
						box-shadow: 0px 0px 19px 2px rgb(153 153 153 / 25%);
						-webkit-box-sizing: border-box;
						box-sizing: border-box;
						padding: 80px 0;
						-webkit-transition: all .3s;
						transition: all .3s;
						text-align: center;

						&:hover {
							-webkit-transform: translateY(-20px);
							transform: translateY(-20px);
							-webkit-box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
							box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%)
						}

						img {
							width: 120px;
							height: 120px;
						}

						.item_title {
							font-size: 20px;
							line-height: 26px;
							margin: 30px 0 15px;
							font-weight: bold;
							color: #333333;
						}
					}
				}
			}

			&.zxrz {
				background: url('../../../assets/images/supplier/zxrz_bj.png') bottom no-repeat #dae5f9;
				min-height: 530px;
				margin: 0;

				.zxrz_wrap {
					display: flex;
					align-items: center;
					justify-content: center;

					.item {
						flex: 1;
						width: 25%;
						max-width: 25%;
						padding: 0 15px;

						.item_wrap {
							padding: 20px 20px 40px;
							color: #666;
							background-image: url('../../../assets/images/supplier/zxrz_bj.jpg');
							background-size: 100% 100%;
							border-radius: 5px;

							.item_top {
								padding-top: 12px;

								.zxrz_item_img {
									width: 100%;
									height: 48px;
									position: relative;
									border-radius: 7px;
									overflow: hidden;

									img {
										max-width: 100%;
										max-height: 48px;
										position: absolute;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%);
										-webkit-transform: translate(-50%, -50%);
									}
								}

								.zxrz_item_name {
									font-size: 16px;
									color: #0b72e7;
									font-weight: 700;
									text-align: center;
									height: 48px;
									margin: 25px 0 10px;
								}
							}

							.zxrz_item_nr {
								clear: both;
								color: #7f7f7f;
								font-size: 12px;
								line-height: 24px;
								font-weight: 700;
								margin-top: 3px;

								span {
									font-size: 14px;
									color: #404040 !important;
								}
							}
						}

					}
				}

				.enterp-server {
					margin-top: 80px;
					padding: 0 20px;
					display: flex;
					align-items: flex-end;
					justify-content: space-between;

					.enterp_left {
						width: 52%;

						.left_button {
							line-height: 24px;
							position: relative;

							&:before {
								content: "";
								display: block;
								width: 8px;
								height: 24px;
								border-radius: 24px;
								background: #91b8f1;
								position: absolute;
								left: 0;
								top: 0;
							}

							a {
								background: #91b8f1;
								border-radius: 20px;
								padding: 3px 10px;
								color: #fff;
								font-size: 18px;
							}
						}

						.left_img {
							width: 100%;

							position: relative;
							margin-left: 24px;
							margin-top: 30px;

							img {
								height: auto;
								max-width: 100%;
							}

							a {
								display: inline-block;
								width: 120px;
								height: 40px;
								border-radius: 3px;
								background: #91b8f1;
								font-size: 18px;
								text-align: center;
								line-height: 40px;
								color: #fff;
								position: absolute;
								right: 21px;
								bottom: 14px;
							}
						}
					}

					.enterp_center {
						width: 14%;
						flex-shrink: 0;
						margin-left: 44px;
					}

					.enterp_right {
						width: 20%;
						flex-shrink: 0;

						.title {
							color: #fff;
							font-size: 20px;
							margin-bottom: 20px;
							line-height: 1.3;
						}

						ul {
							margin-bottom: 30px;

							li {
								box-sizing: border-box;
								width: 100%;
								height: 33px;
								border-radius: 3px;
								background: #fc6b51;
								font-weight: 700;
								margin-top: 45px;
								line-height: 33px;
								padding: 0 15px;

								&:first-child {
									margin-top: 10px;
								}

								p {
									display: inline-block;
									min-width: 135px;
									font-size: 14px;
									color: #fff;
									font-weight: 400;
								}
							}
						}
					}
				}
			}

			&.suppliers {
				margin: 0;
				padding: 70px 15px;

				.suppliers_wrap {
					display: flex;

					.suppliers_tab {
						// flex: 1;
						margin-right: 20px;

						.order-list {
							.header_wrap {
								display: flex;
								align-items: center;
								justify-content: space-between;

								span {
									font-size: 18px;
									font-weight: 500;
									color: #007bff;
								}

								a {
									color: #007bff;
									font-size: 14px;
								}
							}

							.list {
								// min-height: 650px;

								.item {
									border-bottom: 1px dashed #ccc;
									padding: 12px;
									margin: 4px 10px;
									cursor: pointer;
									display: flex;
									align-items: center;
									justify-content: space-between;

									h4 {
										flex: 1;

										a {
											font-size: 14px;
											color: #3c3c3c;
											font-weight: normal;
											width: 75%;
											display: inline-block;
											overflow: hidden;
											white-space: nowrap;
											text-overflow: ellipsis;
											margin-bottom: 0;

											&:hover {
												color: #007bff;
											}
										}
									}
								}


							}

							.empty-wrap {
								text-align: center;
								padding: 10px;
							}
						}
					}

					.suppliers_right {
						width: 25%;

						.sq {
							display: block;
							background: url('../../../assets/images/supplier/sq.png');
							background-size: 100% 100%;

							p {
								font-size: 20px;
								text-align: center;
								color: #fff;
								text-shadow: #000000 0px 0px 1px;
								font-weight: bold;
								padding: 13% 0;
								margin-bottom: 16px;
							}
						}

						.fwcp {
							display: block;
							background: url('../../../assets/images/supplier/fwcp.png');
							background-size: 100% 100%;

							p {
								font-size: 20px;
								text-align: center;
								color: #fff;
								text-shadow: #000000 0px 0px 1px;
								font-weight: bold;
								padding: 8% 0;
								margin-bottom: 0;
							}
						}

						.index_list {
							border: 2px solid #fef1b8;
							border-top: none;

							ul {
								padding: 15px;

								li {
									list-style: disc;
									float: left;
									line-height: 30px;
									margin-left: 20px;
									font-size: 16px;
								}
							}
						}
					}
				}
			}

			&.ggxy {
				background: #f9f9f9;
				padding: 0 0 80px;

				.suppliers_wrap {
					justify-content: space-between;
				}

				.suppliers_tab {
					width: 49%;
				}

				.header_wrap {
					border-left: 6px solid #2871d5;
					padding-left: 10px;

					span {
						color: #2871d5 !important;
						font-size: 18px;
						line-height: 1;
						font-weight: bold !important;
					}

					a {
						color: #2871d5 !important;
						line-height: 1;
						font-weight: bold !important;
					}
				}

				.list {
					height: 450px;

					// min-height: 650px;
					.item_goods {
						border-bottom: 1px dashed #ccc;
						padding: 12px;
						cursor: pointer;

						div {
							color: #3c3c3c;
							font-size: 14px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							line-height: 1.5;

							span {
								font-size: 14px;
								color: #3c3c3c;
							}
						}
					}

					.item_company {
						border-bottom: 1px dashed #ccc;
						padding: 16px 12px;
						// margin: 4px 10px;
						cursor: pointer;


						.company_top {
							display: flex;
							align-items: center;
							justify-content: space-between;
							line-height: 30px;

							a {
								font-size: 14px;
								color: #3c3c3c;
								font-weight: normal;
								width: 75%;
								display: inline-block;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								margin-bottom: 0;

								&:hover {
									color: #007bff;
								}
							}

							span {
								color: #777;
								font-size: 13px;
							}


						}

						p {
							font-size: 13px;
							color: #707070;
						}
					}


				}
			}

			&.hzhb {
				margin: 0;
				padding: 0 0 80px;

				.row {
					justify-content: space-between;
					display: -ms-flexbox;
					display: flex;
					-ms-flex-wrap: wrap;
					flex-wrap: wrap;
					margin-right: -15px;
					margin-left: -15px;

					.el-select {
						width: 30%;

					}
				}
			}
		}
	}

	.table>tbody>tr>td {
		text-align: center;
		background: #fff;
		height: 30px;
		line-height: 30px;
		font-size: 16px;
		// border-bottom: 1px solid #e2e2e2;
	}

	.table td,
	.table th {
		padding: 10px;
		vertical-align: top;
		border-top: 1px solid #dee2e6;
	}

	.table {
		// border-spacing: 0;
		width: 100%;
	}

	.info-detail_body tr td:first-child {
		width: 142px;
		text-align: right;
	}
</style>