export default {
	path: "/supplyLogin",
	component: () => import("@/views/supplyView/login"),
	redirect: "/supplyView/login",
	name: "supplyLoginPage",
	children: [{
			path: "/supplyLogin/login",
			name: "supplyLogin",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/login/supplyLogin")
		},
		{
			path: "/supplyLogin/register",
			name: "supplyRegister",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/login/supplyRegister")
		},
		{
			path: "/supplyLogin/supplyFind",
			name: "supplyFind",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/supplyView/login/supplyFind")
		}
	]

}