<template>
	<el-container>
		<router-view />
	</el-container>
</template>
<script>
	// import CopyRight from "./components/CopyRight"
	// import NsHeaderMid from "./components/NsHeaderMid"
	import {
		mapGetters
	} from "vuex"

	export default {
		data: () => {
			return {
				login_register: '欢迎登陆'
			}
		},
		name: "Layout",
		components: {
			// CopyRight,
			// NsHeaderMid
		},
		created() {
			this.login_register = this.$route.path == "/login" ? '欢迎登陆' : '欢迎注册';
			this.$store.dispatch("site/siteInfo")
		},
		watch: {
			$route(to, from) {
				this.login_register = to.path == "/login" ? '欢迎登陆' : '欢迎注册';
			}
		},
		computed: {
			...mapGetters(["siteInfo"])
		},
		methods: {}
	}
</script>
<style lang="scss" scoped>
	.el-container {
		min-height: 100vh;
		background: url('../../assets/images/supply/login-background.jpg') no-repeat center fixed;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}

	.ns-login-header {
		height: 89px !important;
	}

	.header-in {
		width: $width;
		height: 89px;
		margin: 20px auto 0;

		.logo-wrap {
			width: 240px;
			height: 68px;
			display: block;
			line-height: 68px;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		p {
			font-size: 28px;
		}
	}

	.login-footer .footer-bottom p .footer-link,
	.login-footer .footer-bottom p .el-link.el-link--default {
		color: #303133;
	}

	.login-footer .footer-bottom p .footer-link:hover {
		color: $base-color;
	}
</style>