<template>
	<div id="rightCounty">
		<div class="bg-color-black">
			<div class="d-flex pt-2 pl-2">
				<!-- <span style="color:#5cd9e8">
                  <icon name="chart-bar"></icon>
                </span> -->
				<div class="d-flex">
					<span class=" text mx-2" style="font-size: 0.22rem;">潜力指数</span>
					<dv-decoration-3 style="width:1.25rem;height:.25rem; position:relative;top:-.0375rem;" />
				</div>
			</div>
			<div class="d-flex jc-center">
				<!-- <dv-water-level-pond style="width: 3.25rem;height:3.25rem;margin-top: .5rem;" :config="options" /> -->
				<dv-charts style="width:100%;height:3.8rem;" class="ring-charts" :option="options" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "rightCounty",
		props: {
			fzDataList: {
				type: Array,
				defalut: () => []
			}
		},
		data() {
			return {
				options: {
					xAxis: {
						axisTick: {
							style: {
								stroke: '#fff',
							}
						},
						axisLine: {
							style: {
								stroke: '#fff',
							}
						},
						axisLabel: {
							style: {
								fill: "#fff"
							}
						},
						data: this.fzDataList.map(item => {
							return item.year
						}),
					},
					yAxis: {
						axisTick: {
							style: {
								stroke: '#fff',
							}
						},
						axisLabel: {
							style: {
								fill: "#fff"
							}
						},
						axisLine: {
							style: {
								stroke: '#fff',
							}
						},
						data: 'value'
					},

					series: [{
						data: this.fzDataList.map(item => {
							return item.qlzz - 0
						}),
						type: 'line',
						lineArea: {
							show: true,
							gradient: ['rgba(55, 162, 218, 0.6)', 'rgba(55, 162, 218, 0)']
						}
					}]
				},

			}
		},
		watch: {
			'fzDataList': function(v) {
				console.log(v)
				this.updateHandler(v)
			},
		},
		methods: {
			updateHandler(fzDataList) {
				const {
					options
				} = this

				/**
				 * 只是这样做是无效
				 * options指向的内存地址没有发生变化
				 * 组件无法侦知数据变化
				 */
				this.options.xAxis.data = fzDataList.map(item => {
					return item.year
				})
				this.options.series[0].data = fzDataList.map(item => {
					return item.qlzz - 0
				})
				/**
				 * 使用ES6拓展运算符生成新的props对象
				 * 组件侦知数据变化 自动刷新状态
				 */
				this.options = {
					...this.options
				}
			}
		}

	}
</script>

<style lang="scss" scoped>
	#rightCounty {
		padding: 0.2rem;
		height: 4.5rem;
		min-width: 3.75rem;
		border-radius: 0.0625rem;

		.bg-color-black {
			height: 4.2rem;
			border-radius: 0.125rem;
		}

		.text {
			color: #c3cbde;
		}
	}
</style>