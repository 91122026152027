<template>
	<div class=" wrap">
		<el-header height="80px"><zh-header /></el-header>
		<router-view></router-view>
		<el-backtop ></el-backtop>
		<el-footer height="auto"><zh-footer /></el-footer>
	</div>
</template>

<script>
	import ZhHeader from "./components/ZhHeader"
	import ZhFooter from "./components/ZhFooter"
	export default {
		name: "supplyIndex",
		components: {
			ZhHeader,
			ZhFooter
		},
	};
</script>
<style scoped>
	/deep/.el-header {
		padding: 0 !important;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		/* width: 100vw; */
		z-index: 99;
	}

	/deep/.el-footer {
		height: auto !important;
		padding: 0 !important;
	}
</style>
<style scoped lang="scss">
	.wrap {

		background: #fff;
	}
</style>