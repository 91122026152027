<template>
	<div class="container">
		<!-- 广告图 -->
		<div class="banner">
			<div class="main">
				<div class="breadcrumb-text">
					<h2>应急保障储备社会化数字协同平台之</h2>
					<p>Socialized Digital Collaborative Platform for emergency security reserve</p>
					<h2 class="mt-10">应急物流</h2>
					<p>Emergency logistics</p>
				</div>
			</div>
		</div>
		<div class="scfl">
			<div class="main">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>应急物流</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main_wrap">
			<div class="main">
				<div class="search_wrap">
					<el-form :inline="true" :model="formData" class="demo-form-inline">
						<el-form-item label="出发地" class="nowidth">
							<el-select v-model="formData.fromaddr_province_id" placeholder="请选择省"
								@change="changefromaddr_Provice(formData.fromaddr_province_id)">
								<el-option v-for="option in fromaddr_province" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name }}</el-option>
							</el-select>
							<el-select v-model="formData.fromaddr_city_id" placeholder="请选择市"
								@change="changefromaddr_City(formData.fromaddr_city_id)">
								<el-option v-for="option in fromaddr_city" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name }}</el-option>
							</el-select>
							<el-select v-model="formData.fromaddr_district_id" placeholder="请选择区/县"
								@change="changefromaddr_District(formData.fromaddr_district_id)">
								<el-option v-for="option in fromaddr_district" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name }}</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="到达地" class="nowidth">
							<el-select v-model="formData.toaddr_province_id" placeholder="请选择省"
								@change="changetoaddr_Provice(formData.toaddr_province_id)">
								<el-option v-for="option in toaddr_province" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name }}</el-option>
							</el-select>
							<el-select v-model="formData.toaddr_city_id" placeholder="请选择市"
								@change="changetoaddr_City(formData.toaddr_city_id)">
								<el-option v-for="option in toaddr_city" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name }}</el-option>
							</el-select>
							<el-select v-model="formData.toaddr_district_id" placeholder="请选择区/县"
								@change="changetoaddr_District(formData.toaddr_district_id)">
								<el-option v-for="option in toaddr_district" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name }}</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmit">查询</el-button>
						</el-form-item>
					</el-form>
				</div>
				<el-card class="box-card order-list">
					<div slot="header" class="clearfix">
						<span class="route_list_title">物流线路</span>

					</div>
					<div v-loading="loading" class="route_list" v-if="orderList.length > 0">
						<div class="list_item" v-for="(item,index) in orderList" :key="index">
							<div class="title">
								{{item.fromaddr_province_name}}{{item.fromaddr_city_name}}{{item.fromaddr_district_name}}&nbsp;⇒&nbsp;{{item.toaddr_province_name}}{{item.toaddr_city_name}}{{item.toaddr_district_name}}
							</div>
							<div>
								<div class="info">企业：<router-link :to="'/supplier/home?id='+item.company_id"
										target="_blank">{{item.company_name}}</router-link></div>
								<div class="info">联系人：{{item.contacts}}</div>
								<div class="info">联系电话：{{item.phone}}</div>
							</div>
							<div>
								<div class="info">
									运价-轻货：{{(item.lightprice&&item.lightprice>0)?item.lightprice+'元/立方':'面议'}}</div>
								<div class="info">
									运价-重货：{{(item.heavyprice&&item.heavyprice>0)?item.heavyprice+'元/立方':'面议'}}</div>
								<div class="info">时效：{{(item.time&&item.time>0)?item.time+'天':'不详'}}</div>
							</div>
						</div>
					</div>
					<div v-if="!loading && orderList.length == 0" class="empty-wrap">暂无相关数据</div>
					<div class="pager">
						<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
							:current-page.sync="currentPage" :page-size.sync="pageSize"
							@size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
							hide-on-single-page></el-pagination>
					</div>
				</el-card>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		myLogistics,
		getArea,
		routelist
	} from '@/api/supply/supply';
	export default {
		name: "supplyLogistics",
		components: {},
		data: () => {
			return {
				formData: {
					fromaddr_province_id: "",
					fromaddr_city_id: "",
					fromaddr_district_id: "",
					toaddr_province_id: "",
					toaddr_city_id: "",
					toaddr_district_id: "",
				},
				fromaddr_province: [],
				fromaddr_city: [],
				fromaddr_district: [],
				fromaddr_pickerValueArray: [],
				fromaddr_multiIndex: [0, 0, 0],
				fromaddr_isInitMultiArray: false,
				// 是否加载完默认地区
				fromaddr_isLoadDefaultAreas: false,
				fromaddr_defaultRegions: [],
				toaddr_province: [],
				toaddr_city: [],
				toaddr_district: [],
				toaddr_pickerValueArray: [],
				toaddr_multiIndex: [0, 0, 0],
				toaddr_isInitMultiArray: false,
				// 是否加载完默认地区
				toaddr_isLoadDefaultAreas: false,
				toaddr_defaultRegions: [],
				orderList: [],
				currentPage: 1,
				pageSize: 10,
				total: 0,
				loading: true
			};
		},
		computed: {
			fromaddr_pickedArr() {
				// 进行初始化
				if (this.fromaddr_isInitMultiArray) {
					return [this.fromaddr_pickerValueArray[0], this.fromaddr_pickerValueArray[1], this
						.fromaddr_pickerValueArray[2]
					]
				}
				return [this.fromaddr_pickerValueArray[0], this.fromaddr_city, this.fromaddr_district]
			},
			toaddr_pickedArr() {
				// 进行初始化
				if (this.toaddr_isInitMultiArray) {
					return [this.toaddr_pickerValueArray[0], this.toaddr_pickerValueArray[1], this.toaddr_pickerValueArray[
						2]]
				}
				return [this.toaddr_pickerValueArray[0], this.toaddr_city, this.toaddr_district]
			}
		},
		watch: {
			fromaddr_defaultRegions: {
				handler(arr, oldArr = []) {
					// 避免传的是字面量的时候重复触发
					if (arr.length !== 3 || arr.join("") === oldArr.join("")) return
					this.fromaddr_handleDefaultRegions()
				},
				immediate: true
			},
			toaddr_defaultRegions: {
				handler(arr, oldArr = []) {
					// 避免传的是字面量的时候重复触发
					if (arr.length !== 3 || arr.join("") === oldArr.join("")) return
					this.toaddr_handleDefaultRegions()
				},
				immediate: true
			}
		},
		created() {
			this.getOrderList();
			this.getDefaultAreas(0, {
				level: 0
			})
		},
		methods: {
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			refresh() {
				this.loading = true;
				this.getOrderList();
			},
			getOrderList() {
				routelist({
						page: this.currentPage,
						page_size: this.pageSize,
						fromaddr_province_id: this.formData.fromaddr_province_id,
						fromaddr_city_id: this.formData.fromaddr_city_id,
						fromaddr_district_id: this.formData.fromaddr_district_id,
						toaddr_province_id: this.formData.toaddr_province_id,
						toaddr_city_id: this.formData.toaddr_city_id,
						toaddr_district_id: this.formData.toaddr_district_id,
					})
					.then(res => {
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						this.orderList = list;
						this.loading = false;
					})
					.catch(res => {
						this.loading = false;
					});
			},
			//搜索
			onSubmit() {
				this.currentPage = 1
				this.refresh()
			},
			fromaddr_handleDefaultRegions() {
				var time = setInterval(() => {
					if (!this.fromaddr_isLoadDefaultAreas) return
					this.fromaddr_isInitMultiArray = false

					for (let i = 0; i < this.fromaddr_defaultRegions.length; i++) {
						for (let j = 0; j < this.fromaddr_pickerValueArray[i].length; j++) {
							this.fromaddr_province = this.fromaddr_pickerValueArray[0]

							// 匹配省
							if (this.fromaddr_defaultRegions[i] == this.fromaddr_pickerValueArray[i][j].id) {
								// 设置选中省
								this.$set(this.fromaddr_multiIndex, i, j)
								// 查询市
								this.getAreas(this.fromaddr_pickerValueArray[i][j].id, data => {
									this.fromaddr_city = data

									for (let k = 0; k < this.fromaddr_city.length; k++) {
										if (this.fromaddr_defaultRegions[1] == this.fromaddr_city[k].id) {
											// 设置选中市
											this.$set(this.fromaddr_multiIndex, 1, k)

											// 查询区县
											this.getAreas(this.fromaddr_city[k].id, data => {
												this.fromaddr_district = data

												// 设置选中区县
												for (let u = 0; u < this.fromaddr_district
													.length; u++) {
													if (this.fromaddr_defaultRegions[2] == this
														.fromaddr_district[u]
														.id) {
														this.$set(this.fromaddr_multiIndex, 2, u)
														this.fromaddr_handleValueChange({
															detail: {
																value: [j, k, u]
															}
														})
														break
													}
												}
											})

											break
										}
									}
								})
							}
						}
					}
					if (this.fromaddr_isLoadDefaultAreas) clearInterval(time)
				}, 100)
			},
			toaddr_handleDefaultRegions() {
				var time = setInterval(() => {
					if (!this.toaddr_isLoadDefaultAreas) return
					this.toaddr_isInitMultiArray = false

					for (let i = 0; i < this.toaddr_defaultRegions.length; i++) {
						for (let j = 0; j < this.toaddr_pickerValueArray[i].length; j++) {
							this.toaddr_province = this.toaddr_pickerValueArray[0]

							// 匹配省
							if (this.toaddr_defaultRegions[i] == this.toaddr_pickerValueArray[i][j].id) {
								// 设置选中省
								this.$set(this.toaddr_multiIndex, i, j)
								// 查询市
								this.getAreas(this.toaddr_pickerValueArray[i][j].id, data => {
									this.toaddr_city = data

									for (let k = 0; k < this.toaddr_city.length; k++) {
										if (this.toaddr_defaultRegions[1] == this.toaddr_city[k].id) {
											// 设置选中市
											this.$set(this.toaddr_multiIndex, 1, k)

											// 查询区县
											this.getAreas(this.toaddr_city[k].id, data => {
												this.toaddr_district = data

												// 设置选中区县
												for (let u = 0; u < this.toaddr_district
													.length; u++) {
													if (this.toaddr_defaultRegions[2] == this
														.toaddr_district[u]
														.id) {
														this.$set(this.toaddr_multiIndex, 2, u)
														this.toaddr_handleValueChange({
															detail: {
																value: [j, k, u]
															}
														})
														break
													}
												}
											})

											break
										}
									}
								})
							}
						}
					}
					if (this.toaddr_isLoadDefaultAreas) clearInterval(time)
				}, 100)
			},
			fromaddr_handleValueChange(e) {
				// 结构赋值
				let [index0, index1, index2] = e.detail.value
				let [arr0, arr1, arr2] = this.fromaddr_pickedArr
				let address = [arr0[index0], arr1[index1], arr2[index2]]

				this.formData.full_address = ""
				for (let i = 0; i < address.length; i++) {
					if (this.formData.full_address) {
						this.formData.full_address = this.formData.full_address + "-" + address[i].name
					} else {
						this.formData.full_address = this.formData.full_address + address[i].name
					}
				}
			},
			toaddr_handleValueChange(e) {
				// 结构赋值
				let [index0, index1, index2] = e.detail.value
				let [arr0, arr1, arr2] = this.toaddr_pickedArr
				let address = [arr0[index0], arr1[index1], arr2[index2]]

				this.formData.full_address = ""
				for (let i = 0; i < address.length; i++) {
					if (this.formData.full_address) {
						this.formData.full_address = this.formData.full_address + "-" + address[i].name
					} else {
						this.formData.full_address = this.formData.full_address + address[i].name
					}
				}
			},
			getAreas(pid, callback) {
				getArea({
						pid: pid
					})
					.then(res => {
						if (res.code == 0) {
							var data = []
							res.data.forEach((item, index) => {
								data.push(item)
							})
							if (callback) callback(data)
						}
					})
					.catch(err => {})
			},
			/**
			 * 改变省
			 */
			changefromaddr_Provice(id) {
				this.getAreas(id, data => (this.fromaddr_city = data))
				let obj = {}
				obj = this.fromaddr_province.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.fromaddr_city_id = ""
				this.formData.fromaddr_district_id = ""
				this.formData.fromaddr_province_name = obj.name // 设置选中的地址
			},
			changetoaddr_Provice(id) {
				this.getAreas(id, data => (this.toaddr_city = data))
				let obj = {}
				obj = this.toaddr_province.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.toaddr_city_id = ""
				this.formData.toaddr_district_id = ""
				this.formData.toaddr_province_name = obj.name // 设置选中的地址
			},
			/**
			 * 改变市
			 */
			changefromaddr_City(id) {
				this.getAreas(id, data => (this.fromaddr_district = data))
				let obj = {}
				obj = this.fromaddr_city.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.fromaddr_district_id = ""
				this.formData.fromaddr_city_name = obj.name
			},
			changetoaddr_City(id) {
				this.getAreas(id, data => (this.toaddr_district = data))
				let obj = {}
				obj = this.toaddr_city.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.toaddr_district_id = ""
				this.formData.toaddr_city_nam = obj.name
			},
			/**
			 * 改变区
			 */
			changefromaddr_District(id) {
				let obj = {}
				obj = this.fromaddr_district.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.fromaddr_district_name = obj.name
				console.log(this.formData.fromaddr_district_name)
			},
			changetoaddr_District(id) {
				let obj = {}
				obj = this.toaddr_district.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.toaddr_district_name = obj.name
				console.log(this.formData.toaddr_district_name)
			},
			//获取省市区
			getDefaultAreas(pid, obj) {
				getArea({
						pid: pid
					})
					.then(res => {
						if (res.code == 0) {
							var data = []
							var selected = undefined
							res.data.forEach((item, index) => {
								if (obj != undefined) {
									if (obj.level == 0 && obj.province_id != undefined) {
										selected = obj.province_id
									} else if (obj.level == 1 && obj.city_id != undefined) {
										selected = obj.city_id
									} else if (obj.level == 2 && obj.district_id != undefined) {
										selected = obj.district_id
									}
								}

								if (selected == undefined && index == 0) {
									selected = item.id
								}
								data.push(item)
							})

							// this.pickerValueArray[obj.level] = data
							this.fromaddr_pickerValueArray[obj.level] = data
							this.toaddr_pickerValueArray[obj.level] = data
							if (obj.level + 1 < 3) {
								obj.level++
								this.getDefaultAreas(selected, obj)
							} else {
								this.fromaddr_isInitMultiArray = true
								this.fromaddr_isLoadDefaultAreas = true
								this.toaddr_isInitMultiArray = true
								this.toaddr_isLoadDefaultAreas = true
								// this.isInitMultiArray = true
								// this.isLoadDefaultAreas = true
							}

							// this.province = this.pickerValueArray[0]
							this.fromaddr_province = this.fromaddr_pickerValueArray[0]
							this.toaddr_province = this.toaddr_pickerValueArray[0]
						}
						this.loading = false
					})
					.catch(err => {
						this.loading = false
					})
			},
		}
	};
</script>
<style scoped>
	/deep/.nowidth .el-form-item__content {
		width: 360px;
	}

	.el-select {
		width: 120px;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}

	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #fff !important;
	}

	/deep/.el-pager .number:hover {
		color: #11773a !important;
	}
</style>
<style scoped lang="scss">
	.el-button--primary {
		background-color: #57c5a5;
		border-color: #57c5a5;
	}

	.container {
		.banner {
			background: url('../../../assets/images/supply/cpk.png') no-repeat;
			background-size: 100% 100%;
			position: relative;
			z-index: 1;
			width: 100%;
			height: 300px;
			margin-top: 80px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.breadcrumb-text {
					height: 300px;
					position: relative;
					z-index: 1;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h2 {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 36px;
					}

					p {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 18px;
					}
				}

			}

		}

		.scfl {
			padding-top: 30px;



			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;
				padding-bottom: 10px;
				border-bottom: 1px #c1c1c1 solid;
			}
		}

		.main_wrap {
			padding-bottom: 50px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.search_wrap {
					height: 48px;
					line-height: 48px;
					text-align: center;
					font-size: 15px;
					margin: 30px 0;

					.el-form-item {
						margin-bottom: 0;

					}
				}

				.box-card {
					.route_list_title {
						height: 18px;
						line-height: 18px;
						padding: 0 0 0 6px;
						font-size: 16px;
						border-left: 3px solid #57c5a5;
						color: #57c5a5;
						overflow: hidden;
					}

					.route_list {
						border: 1px #eee solid;

						.list_item {
							padding: 10px 20px;

							border-bottom: 1px #eee solid;

							.title {
								color: #333;
								font-size: 16px;
								font-weight: bold;
								margin-bottom: 10px;
								display: block;
							}

							.info {
								margin-bottom: 5px;
								display: inline-block;
								width: 33%;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;

								a {
									color: #57c5a5;
									font-size: 14px;
									font-weight: bold;
								}
							}
						}
					}
				}
			}
		}
	}

	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
</style>