<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<div class="member-info" v-loading="loading">
			<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane label="基本信息" name="first">

					<el-form ref="infoRef" :model="memberInfo" :rules="infoRules" label-width="80px">
						<el-form-item label="用户名:" v-if="memberInfo.username">
							<p>{{ memberInfo.username }}</p>
						</el-form-item>

						<el-form-item label="手机号:">
							<p>{{ memberInfo.mobile }}</p>
						</el-form-item>
						<el-form-item label="注册日期:">
							<p>{{ $util.timeStampTurnTime(memberInfo.reg_time) }}</p>
						</el-form-item>
					</el-form>
					<!-- <div class="btn"><el-button size="medium" type="primary" @click="saveInfo">保存</el-button></div> -->
				</el-tab-pane>
				<el-tab-pane label="头像照片" name="second">
					<div class="preview">
						<div class="title">头像预览</div>
						<div class="content">
							完善个人信息资料，请上传头像图片。
							<br />
							头像最佳默认尺寸为120x120像素。
						</div>
					</div>
					<el-upload :action="uploadActionUrl" :show-file-list="false" :on-success="handleAvatarSuccess"
						class="upload">
						<div class="img-wrap"><img :src="$img(memberInfo.userHeadImg)"
								@error="memberInfo.userHeadImg = defaultHeadImage" /></div>
					</el-upload>
					<div class="btn"><el-button size="medium" type="primary" @click="uploadHeadImg">保存</el-button></div>
				</el-tab-pane>
				<el-tab-pane label="修改密码" name="password">
					<div class="pass-form">
						<el-form :model="passWordForm" :rules="passWordRules" ref="passWordRef" label-width="100px">
							<el-form-item label="原密码" prop="oldPass">
								<el-input type="password" placeholder="当前密码" v-model="passWordForm.oldPass"></el-input>
							</el-form-item>
							<el-form-item label="新密码" prop="pass">
								<el-input type="password" placeholder="新密码" v-model="passWordForm.pass"></el-input>
							</el-form-item>
							<el-form-item label="确认密码" prop="checkPass">
								<el-input type="password" placeholder="请确认新密码"
									v-model="passWordForm.checkPass"></el-input>
							</el-form-item>
						</el-form>
					</div>
					<div class="btn">
						<el-button type="primary" @click="save">保存</el-button>
						<!-- <el-button @click="type = 'all'">取消</el-button> -->
					</div>
					<!-- <el-form ref="infoRef" :model="memberInfo" :rules="infoRules" label-width="80px">
						<el-form-item label="用户名:" v-if="memberInfo.username">
							<p>{{ memberInfo.username }}</p>
						</el-form-item>
				
						<el-form-item label="手机号:">
							<p>{{ memberInfo.mobile }}</p>
						</el-form-item>
						<el-form-item label="注册日期:">
							<p>{{ $util.timeStampTurnTime(memberInfo.reg_time) }}</p>
						</el-form-item>
					</el-form> -->
					<!-- <div class="btn"><el-button size="medium" type="primary" @click="saveInfo">保存</el-button></div> -->
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import {
		user_info,
		modify_pass_old
	} from "@/api/supply/supply"
	import Config from '@/utils/config';
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: 'supplymemberinfo',
		components: {},
		data() {
			var validatePass = (rule, value, callback) => {
				console.log(this.passWordForm)
				if (value === "") {
					callback(new Error("请输入新密码"))
				} else if (value == this.passWordForm.oldPass) {
					callback(new Error("新密码不能与原密码相同！"))
				} else {
					if (this.passWordForm.checkPass !== "") {
						this.$refs.passWordRef.validateField("checkPass")
					}
					callback()
				}
			}
			var validatePass2 = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请再次输入密码"))
				} else if (value !== this.passWordForm.pass) {
					callback(new Error("两次输入密码不一致!"))
				} else {
					callback()
				}
			}


			return {
				passWordRules: {
					oldPass: [{
						required: true,
						message: "请输入原密码",
						trigger: "blur"
					}],
					pass: [{
						required: true,
						validator: validatePass,
						trigger: "blur"
					}],
					checkPass: [{
						required: true,
						validator: validatePass2,
						trigger: "blur"
					}]
				},
				passWordForm: {
					oldPass: "",
					pass: "",
					checkPass: ""
				},
				memberInfo: {
					avatar: '',
					username: '', //账号
					mobile: ''
				},
				infoRules: {
					nickName: [{
						required: true,
						message: '请输入昵称',
						trigger: 'blur'
					}, {
						max: 30,
						message: '最大长度为30个字符',
						trigger: 'blur'
					}]
				},
				activeName: 'first',
				loading: true,
				uploadActionUrl: Config.baseUrl + '/api/upload/headimg',
				imgUrl: '',
				yes: true
			};
		},
		created() {
			this.getInfo();
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		methods: {
			save() {
				this.$refs.passWordRef.validate(valid => {
					if (valid) {
						console.log(this.passWordForm)
						modify_pass_old({
								password: this.passWordForm.pass,
								confirm_password: this.passWordForm.checkPass,
								old_password: this.passWordForm.oldPass
							})
							.then(res => {
								this.$message({
									message: "修改密码成功",
									type: "success"
								})
								// this.type = "all"
								this.$store.dispatch("supplymember/member_detail", {
									refresh: 1
								})
								this.passWordForm.pass = ""
								this.passWordForm.oldPass = ""
								this.passWordForm.checkPass = ""
							})
							.catch(err => {
								this.$message.error(err.message)
							})
					} else {
						return false
					}
				})
			},
			getInfo() {
				user_info()
					.then(res => {
						const {
							data
						} = res
						this.memberInfo.avatar = res.data.avatar;
						this.memberInfo.username = res.data.username;
						this.memberInfo.reg_time = res.data.reg_time;
						this.memberInfo.mobile = res.data.mobile;
						this.loading = false;
					})
					.catch(err => {
						console.log('err')
					})
			},
			handleClick(tab, event) {},
			saveInfo() {
				this.$refs.infoRef.validate(valid => {
					if (valid) {
						nickName({
								nickname: this.memberInfo.nickName
							})
							.then(res => {
								if (res.code == 0) {
									this.$message({
										message: '修改昵称成功',
										type: 'success'
									});
									this.getInfo;
									this.$store.dispatch('member/member_detail', {
										refresh: 1
									});
								}
							})
							.catch(err => {
								this.$message.error(err.message);
							});
					} else {
						return false;
					}
				});
			},
			handleAvatarSuccess(res, file) {
				this.imgUrl = res.data.pic_path;
				this.memberInfo.userHeadImg = URL.createObjectURL(file.raw);
			},
			uploadHeadImg() {
				headImg({
						headimg: this.imgUrl
					})
					.then(res => {
						if (res.code == 0) {
							this.$message({
								message: '头像修改成功',
								type: 'success'
							});
							this.$store.dispatch('member/member_detail', {
								refresh: 1
							});
						}
					})
					.catch(err => {
						this.$message.error(err.message);
					});
			}
		},
		computed: {
			...mapGetters(['defaultHeadImage'])
		}
	};
</script>
<style scoped>
	/deep/.member-info .el-upload {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
	}

	/deep/.el-tabs__item:hover {
		color: #11773a;
	}

	/deep/.el-tabs__item.is-active {
		color: #11773a;
	}
</style>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.member-info {
		background: #ffffff;
		padding: 20px;

		.el-tab-pane {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-content: center;

			.preview {
				display: flex;
				justify-content: center;
				margin-bottom: 20px;

				.title {
					margin-right: 20px;
					line-height: 3;
				}

				.content {
					color: $base-supply-color;
					line-height: 1.5;
				}
			}

			.upload {
				display: flex;
				justify-content: center;
			}

			.el-upload {
				width: 120px;
			}

			.img-wrap {
				width: 120px;
				height: 120px;
				display: block;
				line-height: 120px;
				overflow: hidden;
				border: 1px solid #f1f1f1;
				cursor: pointer;

				img {
					display: inline-block;
				}
			}

			.el-form {
				margin-top: 20px;
				width: 500px;
				margin-left: 200px;

				.toBind {
					cursor: pointer;

					&:hover {
						color: $base-supply-color;
					}
				}
			}

			.btn {
				text-align: center;

				.el-button--primary {
					background-color: $base-supply-color;
					border-color: $base-supply-color;
				}
			}
		}
	}
</style>