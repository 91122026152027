import {
	login,
	mobile_login
} from "@/api/auth/login"
import {
	supplyResigter,
	supplyLogin,
	user_info
} from "@/api/supply/supply"
import {
	getsupplyToken,
	setsupplyToken,
	removesupplyToken
} from "@/utils/supplyauth"
// import { memberDetail } from "@/api/member/member"
import {
	memberInfo
} from "@/api/member/index"

const state = {
	supply_token: getsupplyToken(),
	supply_autoLoginRange: 0,
	supply_member: ""
}

const mutations = {
	SET_TOKEN_SUPPLY: (state, token) => {
		state.supply_token = token
	},
	SET_AUTOLOGIN_FLAG_SUPPLY: (state, autologinRange) => {
		state.supply_autoLogin = autologinRange
	},
	SET_MEMBER_SUPPLY: (state, member) => {
		console.log(member)
		state.supply_member = member
	}
}

const actions = {
	login_supply({
		commit
	}, userInfo) {
		const {
			mobile,
			password,
			captcha_id,
			captcha_code,
			autoLoginRange
		} = userInfo

		return new Promise((resolve, reject) => {
			return supplyLogin({
					mobile,
					password,
					captcha_id,
					captcha_code,
					autoLoginRange
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res

					if (code == 0) {
						commit("SET_TOKEN_SUPPLY", data.id)

						if (userInfo.autoLoginRange !== undefined) {
							commit("SET_AUTOLOGIN_FLAG_SUPPLY", userInfo.autoLoginRange)
						}

						setsupplyToken(data.id, userInfo.autoLoginRange)

						resolve(res)
					}

					reject()
				})
				.catch(_err => {
					reject(_err)
				})
		})
	},

	mobile_login({
		commit
	}, userInfo) {
		const {
			mobile,
			key,
			code
		} = userInfo

		return new Promise((resolve, reject) => {
			return mobile_login({
					mobile,
					key,
					code
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res

					if (code == 0) {
						commit("SET_TOKEN_SUPPLY", data.token)

						setsupplyToken(data.token, userInfo.autoLoginRange)

						resolve(res)
					}

					reject()
				})
				.catch(_err => {
					reject(_err)
				})
		})
	},

	remove_token({
		commit
	}) {
		commit("SET_TOKEN_SUPPLY", "")
		removesupplyToken()
	},

	register_supply({
		commit
	}, userInfo) {
		// const { username, password, captcha_id, captcha_code } = userInfo
		console.log(userInfo)
		return new Promise((resolve, reject) => {
			return supplyResigter(userInfo)
				.then(res => {
					const {
						code,
						message,
						data
					} = res

					if (code == 0) {
						commit("SET_TOKEN_SUPPLY", data)

						setsupplyToken(data)

						resolve(res)
					}

					reject()
				})
				.catch(_err => {
					reject(_err)
				})
		})
		commit("SET_TOKEN_SUPPLY", "")
	},

	auto_login_range({
		commit
	}, autologinRange) {
		commit("SET_AUTOLOGIN_FLAG_SUPPLY", autologinRange)
	},

	logout({
		commit
	}) {
		commit("SET_TOKEN_SUPPLY", "")
		commit("SET_MEMBER_SUPPLY", "")
		removesupplyToken()
		// resolve()
	},

	/**
	 * params refresh:1 表示强制请求会员信息
	 */
	member_detail({
		commit,
		state
	}, params) {

		if (!state.supply_member || (params && params.refresh)) {
			return new Promise((resolve, reject) => {
				console.log(123)
				return user_info({
						uid: getsupplyToken()
					})
					.then(res => {
						const {
							data
						} = res
						commit("SET_MEMBER_SUPPLY", data)
						return resolve(res)
					})
					.catch(err => {
						return reject(err)
					})
			})
		}
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}