<template>
	<div class="container">
		<div class="login_wrap">
			<div class="main">
				<h2>中国合格供应商管理平台</h2>
				<div class="grid-content bg-purple login_tab">
					<h3>注册</h3>
					<el-form :model="formData" :rules="accountRules" ref="registerRef">
						<el-form-item prop="mobile">
							<el-input maxlength="11" v-model="formData.mobile" placeholder="请输入手机号">
								<template slot="prepend">
									<i class="iconfont iconshouji-copy"></i>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item prop="code">
							<el-input v-model="formData.code" maxlength="4" placeholder="请输入短信动态码">
								<template slot="prepend">
									<i class="iconfont icondongtaima"></i>
								</template>
								<template slot="append">
									<div class="dynacode"
										:class="dynacodeData.seconds == 120 ? 'ns-text-color' : 'ns-text-color-gray'"
										@click="sendMobileCode('mobileRuleForm')">
										{{ dynacodeData.codeText }}
									</div>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item prop="username">
							<el-input v-model="formData.username" placeholder="请输入用户名">
								<template slot="prepend">
									<i class="iconfont iconzhanghao"></i>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input type="password" v-model="formData.password" autocomplete="off"
								placeholder="请输入登录密码">
								<template slot="prepend">
									<i class="iconfont iconmima"></i>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item prop="confirm_password">
							<el-input type="password" v-model="formData.confirm_password" autocomplete="off"
								placeholder="请确认登录密码">
								<template slot="prepend">
									<i class="iconfont iconmima"></i>
								</template>
							</el-input>
						</el-form-item>
						<!-- 验证码显示与否字段shop_pc_reception_login -->
						<!-- <el-form-item prop="vercode">
							<el-input v-model="formData.vercode" autocomplete="off" placeholder="请输入验证码" maxlength="4">
								<template slot="prepend">
									<i class="iconfont iconyanzhengma"></i>
								</template>
								<template slot="append">
									<img :src="captcha.img" mode class="captcha" @click="getCaptcha" />
								</template>
							</el-input>
						</el-form-item> -->
						<el-form-item>
							<el-row>
								<el-col :span="12">
									<div class="xy-wrap">
										<div class="iconfont" @click="check"
											:class="ischecked ? 'iconxuanze-duoxuan' : 'iconxuanze'"></div>
										<div class="content">
											阅读并同意
											<b @click.stop="getAggrement">《合作协议》</b>
										</div>
									</div>
								</el-col>
							</el-row>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" class="rule-button" @click="register">
								注册</el-button>
						</el-form-item>

						<el-form-item>
							<el-row>
								<el-col :span="24">
									<div class="bg-purple-light">已有账号？<router-link class="register"
											to="/supplyLogin/login">立即登录</router-link>
									</div>
								</el-col>
							</el-row>
						</el-form-item>
					</el-form>

				</div>
			</div>
		</div>
		<el-dialog :title="agreement.title" :visible.sync="aggrementVisible" width="60%" :before-close="aggrementClose"
			:lock-scroll="false" center>
			<div v-html="agreement.content" class="xyContent"></div>
		</el-dialog>
	</div>
</template>

<script>
	var isMobile = (rule, value, callback) => {
		if (!value) {
			return callback(new Error("手机号不能为空"))
		} else {
			const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

			if (reg.test(value)) {
				callback()
			} else {
				callback(new Error("请输入正确的手机号"))
			}
		}
	};
	import {
		mapGetters
	} from "vuex";
	import {
		captcha,
		captchaConfig
	} from "@/api/website"
	import {
		tree,
		categoryConfig
	} from '@/api/goods/goodscategory';
	export default {
		name: "supplyIndex",
		components: {},
		data: () => {
			return {
				formData: {
					username: "",
					mobile: "",
					confirm_password: "",
					password: "",
					key: ""
				},
				ischecked: false,
				agreement: '',
				aggrementVisible: false,
				accountRules: {
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						validator: isMobile,
						trigger: "blur"
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					confirm_password: [{
						required: true,
						message: '请确认密码',
						trigger: 'blur'
					}],
					key: [{
						required: true,
						message: "请输入短信动态码",
						trigger: "blur"
					}]
				},
				captcha: {
					id: "",
					img: ""
				}, // 验证码
				dynacodeData: {
					seconds: 120,
					timer: null,
					codeText: "获取动态码",
					isSend: false
				}, // 动态码

			};
		},
		computed: {

		},
		created() {
			this.getCaptcha()
		},
		methods: {
			register() {
				this.$refs.registerRef.validate(valid => {
					if (valid) {
						if (!this.ischecked) {
							return this.$message({
								message: '请先阅读协议并勾选',
								type: 'warning'
							});
						}
						var data = {
							username: this.formData.username.trim(),
							password: this.formData.password,
							mobile: this.formData.mobile,
							confirm_password: this.formData.confirm_password,
							key: this.formData.key
						};
						
						this.$store
							.dispatch('supplymember/register_supply', data)
							.then(res => {
								if (res.code >= 0) {
									this.$router.push('/member/index');
								}
							})
							.catch(err => {
								this.$message.error(err.message);
								this.getCode();
							});
					} else {
						return false;
					}
				});
			},
			sendMobileCode(formName) {
				if (this.dynacodeData.seconds != 120) return
				this.$refs[formName].clearValidate("dynacode")

				this.$refs[formName].validateField("mobile", valid => {
					if (valid) {
						return false
					}
				})
				this.$refs[formName].validateField("vercode", valid => {
					if (!valid) {
						mobileCode({
								mobile: this.formData.mobile,
								captcha_id: this.captcha.id,
								captcha_code: this.formData.vercode
							})
							.then(res => {
								if (res.code >= 0) {
									this.formData.key = res.data.key
									if (this.dynacodeData.seconds == 120 && this.dynacodeData.timer == null) {
										this.dynacodeData.timer = setInterval(() => {
											this.dynacodeData.seconds--
											this.dynacodeData.codeText = this.dynacodeData.seconds +
												"s后可重新获取"
										}, 1000)
									}
								}
							})
							.catch(err => {
								this.$message.error(err.message)
							})
					} else {
						return false
					}
				})
			},
			getAggrement() {
				this.aggrementVisible = true;
			},
			aggrementClose() {
				this.aggrementVisible = false;
			},
			check() {
				this.ischecked = !this.ischecked;
			},
			//获取验证码
			getCaptcha() {
				captcha({
						captcha_id: this.captcha.id
					})
					.then(res => {
						if (res.code >= 0) {
							this.captcha.id = res.data.id
							this.captcha.img = res.data.img
							this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
						}
					})
					.catch(err => {
						this.$message.error(err.message)
					})
			},
			//立即提价
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

		}
	};
</script>
<style scoped>
	/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #379563;
	}

	/deep/.el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: #379563;
		border-color: #379563;
	}

	/deep/.el-checkbox__inner:hover {
		border-color: #379563;
	}

	/deep/.el-input__inner {
		background: #fff;
		border-radius: 4px;
		/* height: 34px; */
		padding-left: 16px;


	}

	/deep/.el-form-item {
		/* margin-bottom: 10px; */
	}

	/deep/.el-input__inner:focus {
		border-color: #379563;
	}

	/deep/.el-form-item__error {
		font-size: 16px;
	}

	/deep/.el-input__inner::placeholder {
		color: #666;
	}
</style>
<style scoped lang="scss">
	.container {

		.login_wrap {
			overflow: hidden;

			.main {
				width: 380px;
				margin: 10% auto 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				box-sizing: border-box;

				h2 {
					text-align: center;
					margin-bottom: 35px;
				}



				.grid-content {
					border: 1px solid rgb(208, 208, 208);
					-moz-box-shadow: 0 3px 0 rgba(12, 12, 12, .03);
					-webkit-box-shadow: 0 3px 0 rgb(12 12 12 / 3%);
					box-shadow: 0 3px 0 rgb(12 12 12 / 3%);
					-moz-border-radius: 3px;
					-webkit-border-radius: 3px;
					border-radius: 5px;
					padding: 30px;
					width: 100%;
					background-color: #fff;

					h3 {
						text-align: center;
						font-weight: bold;
						font-size: 18px;
						margin-bottom: 30px;
					}

					.el-form {
						.captcha {
							vertical-align: top;
							max-width: inherit;
							max-height: 38px;
							line-height: 38px;
							cursor: pointer;
						}

						.dynacode {
							cursor: pointer;

							&.ns-text-color {
								color: #379563 !important;
							}
						}

						[class*=' el-icon-'],
						[class^='el-icon-'] {
							font-size: 16px;
						}
					}

					.el-form-item__error {
						padding-left: 50px;
					}

					button {
						width: 100%;
						background-color: #379563;
						border-color: #379563;
					}

					.ns-forget-pass {
						text-align: right;

						a {
							color: #337ab7;
						}
					}

					i {
						font-size: 18px;
					}

					.iconxuanze-duoxuan {
						color: #379563;
					}

					.xy-wrap {
						display: flex;
						align-items: center;
						font-size: $ns-font-size-base;
						cursor: pointer;

						.iconfont {
							display: flex;
							align-content: center;
						}

						.toLogin {
							cursor: pointer;
						}

						.content {
							margin-left: 3px;

							b {
								color: #379563;
							}
						}
					}

					.bg-purple-light {
						display: flex;
						justify-content: flex-end;
						align-items: center;

						.register {
							color: #337ab7;
						}
					}
				}
			}
		}
	}
</style>