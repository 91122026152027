import http from "../../utils/http"

/**
 * 注册
 */
export function supplyResigter(params) {
	return http({
		url: "/companyapi/login/register",
		data: params
	})
}
/**
 * 登录
 */
export function supplyLogin(params) {
	return http({
		url: "/companyapi/login/login",
		data: params
	})
}

/**
 * 搜索用户
 */
export function search_user(params) {
	return http({
		url: "/companyapi/login/search_user",
		data: params
	})
}
/**
 * 搜索用户
 */
export function modify_pass(params) {
	return http({
		url: "/companyapi/login/modify_pass",
		data: params
	})
}
/**
 * 用户信息
 */
export function user_info(params) {
	return http({
		url: "/companyapi/login/user_info",
		data: params
	})
}
/**
 * 获取省市区
 */
export function getArea(params) {
	return http({
		url: "/companyapi/Company/area",
		data: params
	})
}
/**
 * 企业认证
 */
export function authCompany(params) {
	return http({
		url: "/companyapi/Company/auth",
		data: params,
		forceLogin: true
	})
}
/**
 * 企业详情
 */
export function companyInfo(params) {
	return http({
		url: "/companyapi/index/info",
		data: params,
		forceLogin: true
	})
}
/**
 * 企业类型列表
 */
export function companyCategory(params) {
	return http({
		url: "/companyapi/Company/category",
		data: params,
		forceLogin: true
	})
}
/**
 * 企业行业列表
 */
export function companyIndustry(params) {
	return http({
		url: "/companyapi/Company/industry",
		data: params,
		forceLogin: true
	})
}
/**
 * 企业行业列表
 */
export function companyPerfect(params) {
	return http({
		url: "/companyapi/Company/perfect",
		data: params,
		forceLogin: true
	})
}
/**
 * 我的物流列表
 */
export function myLogistics(params) {
	return http({
		url: "/companyapi/logistics/lists",
		data: params,
		forceLogin: true
	})
}
/**
 * 添加修改物流信息
 */
export function add_route(params) {
	return http({
		url: "/companyapi/logistics/add_route",
		data: params,
		forceLogin: true
	})
}
/**
 * 修改物流状态
 */
export function modifyState(params) {
	return http({
		url: "/companyapi/logistics/modifyState",
		data: params,
		forceLogin: true
	})
}
/**
 * 删除物流状态
 */
export function delLogistics(params) {
	return http({
		url: "/companyapi/logistics/del",
		data: params,
		forceLogin: true
	})
}
/**
 * 物流列表前台
 */
export function routelist(params) {
	return http({
		url: "/companyapi/index/routelist",
		data: params,
		forceLogin: true
	})
}
/**
 * 商品分类
 */
export function goodsCategory(params) {
	return http({
		url: "/companyapi/index/number_house",
		data: params,
		forceLogin: true
	})
}
/**
 * 添加商品
 */
export function add_goods(params) {
	return http({
		url: "/companyapi/goods/add_goods",
		data: params,
		forceLogin: true
	})
}
/**
 * 添加商品
 */
export function my_goods(params) {
	return http({
		url: "/companyapi/goods/lists",
		data: params,
		forceLogin: true
	})
}
/**
 * 修改状态商品
 */
export function modifyGoodsState(params) {
	return http({
		url: "/companyapi/goods/modifyGoodsState",
		data: params,
		forceLogin: true
	})
}
/**
 * 删除商品
 */
export function delGoods(params) {
	return http({
		url: "/companyapi/goods/delGoods",
		data: params,
		forceLogin: true
	})
}
/**
 * 数字仓库列表
 */
export function indexGoods(params) {
	return http({
		url: "/companyapi/index/lists",
		data: params,
		forceLogin: true
	})
}
/**
 * 最新入住
 */
export function new_settled(params) {
	return http({
		url: "/companyapi/index/new_settled",
		data: params,
		forceLogin: true
	})
}
/**
 * 集采列表
 */
export function bidding_list(params) {
	return http({
		url: "/companyapi/bidding/lists",
		data: params,
		forceLogin: true
	})
}
/**
 * 集采添加
 */
export function bidding_add(params) {
	return http({
		url: "/companyapi/bidding/add",
		data: params,
		forceLogin: true
	})
}
/**
 * 集采删除
 */
export function bidding_del(params) {
	return http({
		url: "/companyapi/bidding/del",
		data: params,
		forceLogin: true
	})
}
/**
 * 集采列表
 */
export function bidding_lists(params) {
	return http({
		url: "/companyapi/bidding/lists",
		data: params,
		forceLogin: true
	})
}
/**
 * 集采详情
 */
export function bidding_info(params) {
	return http({
		url: "/companyapi/bidding/info",
		data: params,
		forceLogin: true
	})
}
/**
 * 物资寻源
 */
export function indexSearch(params) {
	return http({
		url: "/companyapi/index/search",
		data: params,
		forceLogin: true
	})
}
/**
 * 失信列表
 */
export function dishonesty_lists(params) {
	return http({
		url: "/companyapi/index/dishonesty_lists",
		data: params,
		forceLogin: true
	})
}
/**
 * 抽检列表
 */
export function spotcheck_lists(params) {
	return http({
		url: "/companyapi/index/spotcheck_lists",
		data: params,
		forceLogin: true
	})
}
/**
 * 供应商列表
 */
export function gys_lists(params) {
	return http({
		url: "/companyapi/index/gys_lists",
		data: params,
		forceLogin: true
	})
}
/**
 * 供应商列表
 */
export function aboutpage(params) {
	return http({
		url: "/companyapi/index/page",
		data: params,
		forceLogin: true
	})
}
/**
 * 地图行业
 */
export function mapIndustry(params) {
	return http({
		url: "/companyapi/index/industry",
		data: params,
		forceLogin: true
	})
}
/**
 * 地图数据
 */
export function mapData(params) {
	return http({
		url: "/companyapi/index/map",
		data: params,
		forceLogin: true
	})
}
/**
 * 集采列表
 */
export function bidding_listsIndex(params) {
	return http({
		url: "/companyapi/index/bidding_lists",
		data: params,
		forceLogin: true
	})
}
/**
 * 指数列表
 */
export function industry_data(params) {
	return http({
		url: "/companyapi/index/industry_data",
		data: params,
		forceLogin: true
	})
}
/**
 * 实时统计
 */
export function statistics(params) {
	return http({
		url: "/companyapi/index/statistics",
		data: params,
		forceLogin: true
	})
}
/**
 * 实时统计
 */
export function modify_pass_old(params) {
	return http({
		url: "/companyapi/login/modify_pass_old",
		data: params,
		forceLogin: true
	})
}
/**
 * 下一步
 */
// export function nextStep(params) {
//     return http(
//         {
//             url: "/api/member/checkmobile",
//             data: params
//         },
//         -1
//     )
// }