<template>
	<el-container>
		<!-- 头部 -->
		<el-header height="80px"><zh-header /></el-header>
		<el-main class="content">
			<transition name="slide"><router-view /></transition>

			<!-- 右侧栏 -->
			<!-- <ns-aside /> -->
		</el-main>
		<!-- 底部 -->
		<el-footer height="auto"><zh-footer /></el-footer>
	</el-container>
</template>
<script>
	import ZhHeader from "./components/ZhHeader"
	import ZhFooter from "./components/ZhFooter"
	// import NsAside from "./components/NsAside"

	export default {
		created() {},
		data: () => {
			return {}
		},
		mounted() {},
		computed: {},
		watch: {},
		components: {
			ZhHeader,
			// NsAside,
			ZhFooter
		}
	}
</script>
<style scoped>
	/deep/.el-header {
		padding: 0 !important;
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		z-index: 99;
	}

	/deep/.el-footer {
		height: auto !important;
		padding: 0 !important;
	}
</style>
<style lang="scss" scoped>
	.header {
		padding: 0;
	}

	.content {
		max-width: $width;
		margin: 100px auto 0;
		padding: 0;
		width: auto;
	}

	.el-footer {
		padding: 0;
		height: auto !important;
		background-color: #fff;
		padding-top: 45px;
	}

	.el-main {
		border-top: none;
	}
</style>