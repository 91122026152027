<template>
	<div class="container">
		<!-- 广告图 -->
		<div class="banner">
			<div class="main">
				<div class="breadcrumb-text">
					<h2>应急保障储备社会化数字协同平台之</h2>
					<p>Socialized Digital Collaborative Platform for emergency security reserve</p>
					<h2 class="mt-10">数字仓库</h2>
					<p>Digital warehouse</p>
				</div>
			</div>
		</div>
		<div class="scfl">
			<div class="main">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>数字仓库</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main_wrap">
			<div class="main">
				<div v-loading="loading" class="ck_wrap" v-if="orderList.length > 0">
					<div class="list_item" v-for="(item,index) in orderList" :key="index">
						<h5 class="trade1">{{item.class_name}}</h5>
						<div class="col-12 trade2_3">
							<div v-for="(it,idx) in item.two" :key="idx">
								<router-link :to="'/supplyView/szckmap?cate_id='+it.class_id+'&name='+it.class_name" class="trade_name">{{it.class_name}}</router-link>（<span
									class="trade_supply">{{it.number}}</span>）
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		goodsCategory
	} from '@/api/supply/supply';
	export default {
		name: "supplySzck",
		components: {},
		data: () => {
			return {
				orderList: [],
				loading: true
			};
		},
		created() {
			this.getOrderList();

		},
		methods: {
			getOrderList() {
				goodsCategory()
					.then(res => {
						let data = res.data
						if (data != null) {
							this.orderList = data
						}
						this.loading = false;
					})
					.catch(res => {
						this.loading = false;
					});
			},
		}
	};
</script>
<style scoped>
	/deep/.nowidth .el-form-item__content {
		width: 360px;
	}

	.el-select {
		width: 120px;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}

	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #11773a;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #fff !important;
	}

	/deep/.el-pager .number:hover {
		color: #11773a !important;
	}
</style>
<style scoped lang="scss">
	.el-button--primary {
		background-color: #57c5a5;
		border-color: #57c5a5;
	}

	.container {
		.banner {
			background: url('../../../assets/images/supply/cpk.png') no-repeat;
			background-size: 100% 100%;
			position: relative;
			z-index: 1;
			width: 100%;
			height: 300px;
			margin-top: 80px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.breadcrumb-text {
					height: 300px;
					position: relative;
					z-index: 1;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h2 {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 36px;
					}

					p {
						color: #ffffff;
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 18px;
					}
				}

			}

		}

		.scfl {
			padding-top: 30px;



			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;
				padding-bottom: 10px;
				border-bottom: 1px #c1c1c1 solid;
			}
		}

		.main_wrap {
			padding-bottom: 50px;

			.main {
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;

				.ck_wrap {
					margin: 50px 0;

					.trade1 {
						font-size: 18px;
						line-height: 1;
						margin: 20px 0 10px;
					}

					.trade2_3 {
						min-height: 30px;
						margin-bottom: 10px;
						padding: 0 15px;
						display: flex;
						align-items: center;
						flex-wrap: wrap;

						div {
							height: 14px;
							line-height: 14px;
							margin: 8px 0;
							white-space: nowrap;
							padding: 0px 12px;
							border-right: solid #ececec 1px;
							color: #555;

							.trade_name {
								cursor: pointer;
								&:hover{
									border-bottom: 1px solid;
									color:#11773a;
								}
							}
						}
					}
				}
			}
		}
	}

	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
</style>