export default {
	path: "/supplyView/szckmap",
	component: () => import("@/views/supplyView/index/szckmap"),
	redirect: "/supplyView/szckmap",
	name: "supplyszckmap",
	children: [{
			path: "/",
			name: "supplyszckmap",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			// component: () => import("@/views/supplyView/index/szckmap")
		}

	]

}