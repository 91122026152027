<template>
	<div class="footer">

		<div class="footer-top">
			<div class="service">
				<div class="service_left">
					Copyright © 2025 All rights reserved | by
					<router-link to="/">XXX单位</router-link>
					|
					<router-link to="/">浙ICP备88888888号-8</router-link>
				</div>
				<div class="service_left right" style="text-align: right;">
					<router-link to="/supplyView/aboutePage?type=gywm" :target="$route.path=='/supplyView/aboutePage'?'_blank':''">关于我们</router-link>
					|
					<router-link to="/supplyView/aboutePage?type=ptjz" :target="$route.path=='/supplyView/aboutePage'?'_blank':''">价值平台</router-link>
					|
					<router-link to="/supplyView/aboutePage?type=hyfw" :target="$route.path=='/supplyView/aboutePage'?'_blank':''">会员服务</router-link>
					|
					<router-link to="/supplyView/aboutePage?type=zcfg" :target="$route.path=='/supplyView/aboutePage'?'_blank':''">政策法规</router-link>
					|
					<router-link to="/supplyView/aboutePage?type=yssm" :target="$route.path=='/supplyView/aboutePage'?'_blank':''">隐私声明</router-link>
					|
					<router-link to="/supplyView/aboutePage?type=fwtk" :target="$route.path=='/supplyView/aboutePage'?'_blank':''">服务条款</router-link>
				</div>
			</div>
		</div>

		<div class="footer-center">
			<div class="container">
				<div class="left">
					<div>
						<p class="left-phone">主办单位：XXXXXXX主办单位</p>
						<p class="left-phone">承办单位：XXXXXXX承办单位</p>
						<p class="left-phone">信用维护：XXXXXXX维护公司</p>
					</div>
				</div>
				<div class="right">
					<el-image :src="require('@/assets/images/wxewm.png')"></el-image>
				</div>
				<div class="right">
					<el-image :src="require('@/assets/images/wxewm.png')"></el-image>
				</div>
				<div class="right">
					<el-image :src="require('@/assets/images/wxewm.png')"></el-image>
					<p>微信小程序</p>
				</div>
			</div>
		</div>

		<div class="footer-fot">
			<div class="fotter_bottom">
				<i class="el-icon-location-information"></i>
				<p>XX省XX市XX区XX路XX号</p>
			</div>
			<div class="fotter_bottom">
				<i class="el-icon-message"></i>
				<p>888888@163.com</p>
			</div>
			<div class="fotter_bottom">
				<i class="el-icon-phone"></i>
				<p>****-12345678</p>
			</div>
			<div class="fotter_bottom">
				<p>Copyright © CESMAP</p>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		copyRight,
		shopServiceLists,
		friendlyLink
	} from "@/api/website"
	// import CopyRight from "./CopyRight"
	import {
		mapGetters
	} from "vuex"
	import {
		helpList
	} from "@/api/cms/help"

	export default {
		props: {},
		data() {
			return {
				shopServiceList: [],
				linkList: [{

				}],
				helpList: [],
				ishide: false,
				activeName: "first",
			}
		},
		computed: {
			...mapGetters(["siteInfo"])
		},
		created() {
			this.getShopServiceLists()
			this.link()
			this.getHelpList()
		},
		mounted() {},
		watch: {},
		methods: {
			getShopServiceLists() {
				shopServiceLists({}).then(res => {
					if (res.code == 0 && res.data) {
						this.shopServiceList = res.data
					}
				})
			},
			link() {
				// friendlyLink({})
				//     .then(res => {
				//         if (res.code == 0 && res.data) {
				//             this.linkList = res.data.list
				//         }
				//     })
				//     .catch(err => {
				//         this.$message.error(err.message)
				//     })
			},
			linkUrl(url, target) {
				if (!url) return
				if (url.indexOf("http") == -1) {
					if (target) this.$router.pushToTab({
						path: url
					})
					else this.$router.push({
						path: url
					})
				} else {
					if (target) window.open(url)
					else window.location.href = url
				}
			},
			/**
			 * 获取帮助列表
			 */
			getHelpList() {
				helpList()
					.then(res => {
						if (res.code == 0 && res.data) {
							var arr = [];
							arr = res.data.slice(0, 4)

							for (let i = 0; i < arr.length; i++) {
								arr[i].child_list = arr[i].child_list.slice(0, 4);
							}

							this.helpList = arr
						}
					})
					.catch(err => {})
			},
			/**
			 * 图片加载失败
			 */
			imageError() {
				this.siteInfo.web_qrcode = "../../assets/images/wxewm.png"
			},
			/**
			 * 跳转到帮助列表
			 */
			clickToHelp(id) {
				this.$router.push("/cms/help/listother-" + id)
			},
			/**
			 * 跳转到帮助详情
			 */
			clickToHelpDetail(id) {
				this.$router.push("/cms/help-" + id)
			}
		},
		// components: { CopyRight }
	}
</script>

<style scoped>
	.right /deep/ .el-image__inner {
		vertical-align: baseline;
		max-width: 100%;
		max-height: 100%;
	}
</style>
<style scoped lang="scss">
	.footer {
		.footer-top {
			position: relative;
			z-index: 1;
			padding: 20px 0;
			background-color: #0a0a0a;
			color: #fff;
			font-size: 14px;
			font-weight: 400;

			.service {
				width: 90%;
				margin: 0 auto;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.service_left {
					flex: 1;

					a {
						color: #fff;


						&:hover {
							color: $base-supplyfuzhu-color;
						}
					}

					&.right {
						a {
							padding: 0 10px;
						}
					}
				}
			}
		}

		.footer-center {
			background-color: rgba(0, 0, 0, 0.6);
			padding: 80px 0 60px;

			.container {
				display: flex;
				// align-items: center;
				justify-content: space-between;
				padding-right: 15px px;
				padding-left: 15px;
				width: 90%;
				margin: 0 auto;

				.left {
					// width: 300px;
					flex: 1;
					padding-left: 10%;

					.left-phone {
						color: #FFFFFF;
						margin-bottom: 5px;
						text-indent: 0 !important;
						font-size: 14px;
					}

				}

				.right {
					max-width: 15%;
					text-align: center;

					.el-image {
						width: 90%;
						height: auto;
						// line-height: 120px;
						text-align: center;

						img {
							width: 100%;
							height: 100%;
						}
					}

					p {
						font-size: 14px;
						color: #FFFFFF;
						margin-bottom: 5px;
						text-indent: 0 !important;
					}
				}
			}

			.qrcode-hide {
				display: none;
			}
		}

		.footer-bot {
			background: #242424;
			color: #9D9D9D;
			// width: $width;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			padding: 50px 350px;

			.left {
				// width: 300px;

				.left-phone {
					font-size: 26px;
					font-weight: 600;
					// color: $base-color;
					padding-bottom: 10px;
					// border-bottom: 1px solid $base-color;
					border-bottom: 1px solid #FFFFFF;
					margin-bottom: 12px;
				}

				.left-email {
					font-size: 18px;
					color: #838383;
				}
			}

			.center {
				width: calc(100% - 550px);
				padding: 0 68px;
				display: flex;
				justify-content: space-between;

				p {
					font-size: 18px;
					font-weight: 600;
					color: #838383;
					margin-bottom: 10px;
					cursor: pointer;
				}

				.help-li {
					font-size: 14px;
					color: #838383;
					line-height: 30px;
					cursor: pointer;
				}

				p:hover,
				.help-li:hover {
					color: $base-color;
				}
			}

			.right {
				width: 250px;
				text-align: center;

				.el-image {
					width: 120px;
					height: 120px;
					line-height: 120px;
					text-align: center;
					background-color: #FFFFFF;
				}

				p {
					font-size: 18px;
					color: #838383;
				}
			}

			.qrcode-hide {
				display: none;
			}
		}

		.footer-fot {
			text-align: center;
			line-height: 50px;
			background: #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;

			.fotter_bottom {
				margin-left: 30px;
				display: flex;
				align-items: center;

				p {
					margin-left: 5px;
					color: #707070;
					font-size: 14px;
				}
			}
		}


	}
</style>
<style lang="scss">

</style>